import { Dropdown } from 'semantic-ui-react';
import styled from 'styled-components';
import { StyledH2, StyledH3, StyledText } from '../../../main/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: initial;
  gap: 20px;

  width: 1050px;
  max-width: 100%;
`;

export const Header = styled(StyledH2)`
  color: ${({ theme }) => theme.colors.system.offBlack};
  margin-bottom: ${({ theme }) => theme.margin.xlarge};
`;

export const SubHeader = styled(StyledH3)`
  margin-bottom: 0;
`;

export const ActionButtonWrapper = styled.div`
  display: flex;

  gap: ${({ theme }) => theme.margin.standard};

  margin-top: ${({ theme }) => theme.margin.xxxlarge};

  > div:last-child {
    margin-left: ${({ theme }) => theme.margin.large};
  }
`;

export const ColumnWrapper = styled.div`
  margin-top: ${({ theme }) => theme.margin.large};
`;

export const CsvColumnSelectorContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.margin.standard};
`;

export const ColumnHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.margin.small};
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StretchedContainer = styled.div`
  width: 500px;
`;

export const FontSizeParagraph = styled.p`
  font-size: 20px;
`;

export const InformationParagraph = styled.p`
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 20px;
  text-align: center;
`;

export const IndividualBoosterContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.margin.xlarge};
  justify-content: center;
  align-items: flex-end;
`;

export const BoostersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledDropdown = styled(Dropdown)`
  border: ${({ theme }) => `2px solid ${theme.colors.general.grey}`};
  width: 400px;
  height: ${({ theme }) => theme.margin.xxxlarge};
`;

export const BoosterRow = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 1000px;
  max-width: 100%;

  > div {
    margin-right: ${({ theme }) => theme.margin.standard};
  }

  > button {
    width: 38px;
    height: 38px;

    > i {
      margin: 0;
    }
  }
`;

export const CenteredText = styled(StyledText)`
  text-align: center;
`;

export const BoosterRowAdditionContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.margin.standard};
`;

export const ActionButtonContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.margin.standard};
  align-self: center;
`;

export const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.general.grey};

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.general.blue};
  }
`;
