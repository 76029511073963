import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import CompanyService from '../../../../services/configur/CompanyService';
import EntriesResponse from '../../../../services/configur/CompanyService';
import { AppThunk } from '../../../store';

export interface ScoredCompaniesState {
  data: EntriesResponse;
  loading: boolean;
  error: string | null;
}

export interface ScoredCompaniesPostResponse {
  successfulRowIds: number;
  failedRowIds: number;
  totalResponse: number[];
}

const initialState: ScoredCompaniesState = {
  data: {},
  loading: false,
  error: null,
};

const CreatedCompaniesSlice = createSlice({
  name: 'createdScoredCompanies',
  initialState,
  reducers: {
    // Create and score
    createAndScoreCompanyStart(state) {
      state.loading = true;
      state.error = null;
    },
    createAndScoreCompanySuccess(
      state,
      action: PayloadAction<ScoredCompaniesPostResponse>,
    ) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    createAndScoreCompanyFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  createAndScoreCompanyStart,
  createAndScoreCompanySuccess,
  createAndScoreCompanyFailure,
} = CreatedCompaniesSlice.actions;

export default CreatedCompaniesSlice.reducer;

export const createAndScoreCompany =
  (token: string, companyData): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createAndScoreCompanyStart());
      const response = await CompanyService.scoreCompanies(token, companyData);
      dispatch(createAndScoreCompanySuccess(response.data));
    } catch (error) {
      const err = error as string;
      dispatch(createAndScoreCompanyFailure(err.toString()));
    }
  };
