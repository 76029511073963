import {
  AcquisitionEvents,
  CostCuttingEvents,
  ExpansionEvents,
  InvestmentEvents,
  LeadershipEvents,
  NewOfferingEvents,
  PartnershipEvents,
  RecognitionEvents,
} from '../../enums/EventCategories';

const SosSentenceConstructor = (category: string): string => {
  if (
    Object.values(AcquisitionEvents).includes(category as AcquisitionEvents)
  ) {
    return 'who is also going through an acquisition event';
  }

  if (Object.values(ExpansionEvents).includes(category as ExpansionEvents)) {
    return 'who is also going through an expansion event';
  }

  if (Object.values(LeadershipEvents).includes(category as LeadershipEvents)) {
    return 'who is also going through a hiring event';
  }

  if (Object.values(InvestmentEvents).includes(category as InvestmentEvents)) {
    if (category === InvestmentEvents.RECEIVES_FINANCING) {
      return 'who also recently received funding';
    }

    if (category === InvestmentEvents.GOES_PUBLIC) {
      return 'who also took their company public recently';
    }

    return 'who is also going through an investment event';
  }

  if (
    Object.values(NewOfferingEvents).includes(category as NewOfferingEvents)
  ) {
    if (category === NewOfferingEvents.INTEGRATES_WITH) {
      return 'who is also going through an integration event';
    }

    if (category === NewOfferingEvents.LAUNCHES) {
      return 'who also launched a new product recently';
    }

    if (category === NewOfferingEvents.IS_DEVELOPING) {
      return 'who also developed a new product recently';
    }
  }

  if (
    Object.values(CostCuttingEvents).includes(category as CostCuttingEvents)
  ) {
    if (category === CostCuttingEvents.CLOSES_OFFICES) {
      return 'who also closed offices recently';
    }

    return 'who also launched a new product recently';
  }

  if (
    Object.values(PartnershipEvents).includes(category as PartnershipEvents)
  ) {
    return 'who also recently partnered with a new company';
  }

  if (
    Object.values(RecognitionEvents).includes(category as RecognitionEvents)
  ) {
    return 'who was also recently recognized';
  }

  return 'who is also going through a similar event';
};

export { SosSentenceConstructor };
