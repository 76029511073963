import { Interfaces } from '@configur-tech/discover-core-types';
import { DateTime } from 'luxon';
import { EventCategories } from '../../enums/EventCategories';
import { convertInsightToDescription } from '../insights/convertInsightToDescription';
import { startCase } from 'lodash';
import { scoreToRankHelper } from './scoreToRankHelper';
import { SosBuilder } from '../sos/SosBuilder';
import { VeeamExportResult } from '../../interfaces/VeeamExportResult';

const DISCOVER_INSIDER_TITLE = '*** Discover Insider ***';
const DATE_FORMAT = 'LLL yy';
const MONTHS = 'months';

const EVENT_STRING_DATE_FORMAT = 'dd/MM/yyyy';
const DATE_CATEGORY = 'Date:';
const TYPE_CATEGORY = 'Type:';
const URL_CATEGORY = 'URL:';
const EVENT_CATEGORY = 'Event:';
const TWENTY_PREPEND = '20';

/**
 * @description - Formats company and event data into a veeam format ready for export
 *
 * @param {Interfaces.Company} company - Company data
 * @param {Interfaces.Events[]} events - Events data
 * @param {Interfaces.Insights} [insight] - Insight for the company
 * @param {number} [totalEventLimit] - Limit of the total events returned
 * @param {string} [eventTypeLimit] - Limit of the types of event per company
 *
 * @returns {Promise<VeeamExportResult>} - Veeam formatted data ready for export
 */
export const veeamFormatExportDataForCompany = async (
  company: Interfaces.Company,
  events: Interfaces.Events[],
  insight?: Interfaces.Insights,
  totalEventLimit?: number,
  eventTypeLimit?: string,
): Promise<VeeamExportResult> => {
  // Build event
  let eventString = '';
  let resultArray;
  let sosResult;

  if (totalEventLimit && !eventTypeLimit) {
    resultArray = events.splice(0, totalEventLimit);
  } else if (eventTypeLimit) {
    const checkTypeArray: EventCategories[] = [];

    const typedEvents = events.filter((event) => {
      checkTypeArray.push(event.eventCategory as EventCategories);

      return (
        checkTypeArray.filter((type) => type === event.eventCategory).length <=
        parseInt(eventTypeLimit)
      );
    });

    resultArray = typedEvents;

    if (totalEventLimit) {
      resultArray = typedEvents.splice(0, totalEventLimit);
    }
  } else {
    resultArray = events;
  }

  // If an insight exists
  if (insight) {
    // Format it to an event type format
    const formattedInsight: Interfaces.Events = {
      eventName: convertInsightToDescription(insight),
      eventCategory: DISCOVER_INSIDER_TITLE,
      eventDate: DateTime.local(
        parseInt(`${TWENTY_PREPEND}${insight.Date.split('-')[1]}`),
        DateTime.fromFormat(
          `${insight.Date.split('-')[0]} ${insight.Date.split('-')[1]}`,
          DATE_FORMAT,
        ),
      ).toISODate(), // Switch to ISO Date
    };

    // Push insight to start of resultArray
    resultArray.unshift(formattedInsight);
  }

  resultArray.map((event, i, originalArray) => {
    eventString += `${DATE_CATEGORY} ${DateTime.fromISO(
      event.eventDate,
    ).toFormat(EVENT_STRING_DATE_FORMAT)}\n${TYPE_CATEGORY} ${
      i === 0 ? event.eventCategory : startCase(event.eventCategory)
    }\n${EVENT_CATEGORY} ${event.eventName}\n${
      event.eventUrl ? `${URL_CATEGORY} ${event.eventUrl}` : ''
    }${i === originalArray.length - 1 ? '' : `\n\n`}`;
  });

  if (resultArray.length) {
    // Sort the events by most recent first
    const sortedEvents = resultArray.sort(
      (eventA, eventB) =>
        (DateTime.now()
          .diff(DateTime.fromISO(eventA.eventDate), MONTHS)
          .toObject().months as number) -
        (DateTime.now()
          .diff(DateTime.fromISO(eventB.eventDate), MONTHS)
          .toObject().months as number),
    );

    // Grab the most recent one
    const mostRecentEvent = sortedEvents[0];

    sosResult = SosBuilder(mostRecentEvent, true);
  }

  return {
    firstNameMKTO: '',
    lastNameMKTO: '',
    email: '',
    phone: '',
    Asset1: '',
    Asset2: '',
    Asset3: '',
    company: company.companyName,
    industry: company.industries,
    // job_title: '', TO BE IMPLEMENTED
    // JobLevel: '', TO BE IMPLEMENTED
    numEmployeesMKTO: company.employeeCount?.toString() || '',
    discussionNotes: `Score: ${scoreToRankHelper(
      company.overallScore,
    )}\n${eventString}`,
    CAAttendance: '',
    opt_in: '',
    street: '',
    street_2: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
    discoverSos: sosResult ? sosResult : '',
  };
};
