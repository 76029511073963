import { createGlobalStyle } from 'styled-components';
import GothicA1 from '../assets/fonts/GothicA1-Regular.woff';

export const GlobalStyles = createGlobalStyle`
    body {
        background: ${({ theme }) => theme.body};
        color: ${({ theme }) => theme.text};
        transition: all 0.50s ease;
    }
`;

export default createGlobalStyle`
    @font-face {
        font-family: 'GothicA1';
        src: local('GothicA1'),
        url(${GothicA1}) format('woff2');
        font-weight: 500;
        font-style: normal;
    }
`;
