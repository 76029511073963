import { FC, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { ThemeContext } from 'styled-components';
import {
  ActionButton,
  ActionCardContainer,
  ActionCardContentContainer,
  StyledH1,
} from '../../main/theme';
import { showModal } from '../../store/modal';
import { ModalTypes } from '../Modal/Modal';
import * as SC from './styled';

const ScoreLeadsCard: FC = () => {
  const themeContext = useContext(ThemeContext);

  const dispatch = useDispatch();
  const green = themeContext.colors.general.green;
  const white = themeContext.colors.general.white;
  const black = themeContext.colors.general.black;
  return (
    <ActionCardContainer borderColor={green}>
      <ActionCardContentContainer>
        <SC.HeaderContainer>
          <StyledH1 color={black} noMargin={true}>
            Score Leads
          </StyledH1>
        </SC.HeaderContainer>
        <div>
          <p>
            Score company level leads using Discover’s custom scoring analyser.
            Discover analyses hundreds of signals to accurately sort leads by
            their openness to sale.
          </p>
        </div>
        <div>
          <p>
            Simply upload a CSV file containing a list of companies you wish to
            score.
          </p>
        </div>
        <SC.ButtonContainer>
          <ActionButton
            backgroundColor={green}
            textColor={white}
            onClick={() =>
              dispatch(
                showModal({
                  forceOpen: true,
                  visible: true,
                  modal: ModalTypes.FILE_SELECT,
                }),
              )
            }
          >
            Upload Leads
          </ActionButton>
        </SC.ButtonContainer>
      </ActionCardContentContainer>
    </ActionCardContainer>
  );
};

export default ScoreLeadsCard;
