import { useAuth0 } from '@auth0/auth0-react';
import { Interfaces } from '@configur-tech/discover-core-types';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEvents } from '../../store/events';
import { RootState } from '../../store/rootReducer';

const useEvents = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const [events, setEvents] = useState<Interfaces.PLEvent[]>([]);
  const companyEventsState = useSelector((state: RootState) => state.events);
  const companyEvents: Interfaces.PLEvent[] =
    companyEventsState.data as Interfaces.PLEvent[];

  const getEventsFromDomain = useCallback(
    async (companyDomain?) => {
      const token = await getAccessTokenSilently();

      if (token) {
        await dispatch(fetchEvents(token, companyDomain));
      }
    },
    [dispatch, getAccessTokenSilently],
  );

  // Load company events
  useEffect(() => {
    setEvents(companyEvents);
  }, [companyEvents]);

  return {
    events,
    getEventsFromDomain,
  };
};

export default useEvents;
