import { Interfaces } from '@configur-tech/discover-core-types';
import { startCase } from 'lodash';

const JOB_TITLE = 'Job Title';
const PAIN_POINT_DESC = 'Pain Point Desc';
const TIME_FRAME_MONTHS = 'TimeFrame(Months)';
const TOPIC_OR_ASSET = 'Topic or Asset Downloaded';

const N_A_VALUE = 'N/A';

/**
 * @description - Method to convert insights to an event-style description string
 *
 * @param {Interfaces.Insights} insight - Insight attached to a company
 *
 * @returns {string}
 */
const convertInsightToDescription = (insight: Interfaces.Insights): string => {
  // Case - Does not have pain point or timeframe, but has topic
  if (
    (!insight[TIME_FRAME_MONTHS] || insight[TIME_FRAME_MONTHS] === N_A_VALUE) &&
    !insight[PAIN_POINT_DESC] &&
    insight[TOPIC_OR_ASSET] &&
    insight[TOPIC_OR_ASSET] !== N_A_VALUE
  ) {
    return `${startCase(insight[JOB_TITLE])} of ${startCase(
      insight.Domain.split('.')[0],
    )}${`, is interested in ${insight[TOPIC_OR_ASSET]}.`}`;
  }

  return `${startCase(insight[JOB_TITLE])} of ${startCase(
    insight.Domain.split('.')[0],
  )}${
    insight[TOPIC_OR_ASSET] && insight[TOPIC_OR_ASSET] !== N_A_VALUE
      ? `, is interested in ${insight[TOPIC_OR_ASSET]}`
      : ''
  }${
    insight[PAIN_POINT_DESC] && insight[PAIN_POINT_DESC] !== N_A_VALUE
      ? `${
          !insight[TOPIC_OR_ASSET] || insight[TOPIC_OR_ASSET] === N_A_VALUE
            ? ``
            : ` and`
        } told us they were looking into '${insight[PAIN_POINT_DESC]}`
      : ''
  }${
    insight[TIME_FRAME_MONTHS] &&
    insight[TIME_FRAME_MONTHS] !== N_A_VALUE &&
    insight[PAIN_POINT_DESC] &&
    insight[PAIN_POINT_DESC] !== N_A_VALUE
      ? ` within the next ${insight[TIME_FRAME_MONTHS]}.`
      : '.'
  }`;
};

export { convertInsightToDescription };
