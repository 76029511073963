import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingProps } from '../../../components/Loading/Loading';
import InitialLoading from '../initial-state';

const OverallLoadingSlice = createSlice({
  name: 'loading',
  initialState: InitialLoading,
  reducers: {
    showLoading(state, action: PayloadAction<LoadingProps>) {
      state.loading = true;
      state.text = action?.payload.text;
    },
    hideLoading(state) {
      state.loading = false;
      state.text = undefined;
    },
  },
});

export const { showLoading, hideLoading } = OverallLoadingSlice.actions;

export default OverallLoadingSlice.reducer;
