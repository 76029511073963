import { combineReducers } from '@reduxjs/toolkit';
import FilteredCompaniesReducer from './companies/filtered';
import ScoredCompaniesReducer from './companies/scored/fetchScore';
import CreatedScoredCompaniesReducer from './companies/scored/createScore';
import SubscribedCompaniesReducer from './companies/subscribed';
import RecentCompaniesReducer from './companies/recent';
import CompanyEventsReducer from './events/company';
import RecentEventsReducer from './events/recent';
import BackgroundLoadingReducer from './loading/backgroundLoading';
import LoadingReducer from './loading/overallLoading';
import RecentEventsComponentLoadingReducer from './loading/recentEventsComponentLoading';
import SubscribedComponentLoadingReducer from './loading/subscribedComponentLoading';
import SearchReducer from './searches';
import UserReducer from './user';
import ModalReducer from './modal';
import InsightsReducer from './insights';

const rootReducer = combineReducers({
  recentEvents: RecentEventsReducer,
  events: CompanyEventsReducer,
  subscribedCompanies: SubscribedCompaniesReducer,
  filteredCompanies: FilteredCompaniesReducer,
  scoredCompanies: ScoredCompaniesReducer,
  createdScoredCompanies: CreatedScoredCompaniesReducer,
  recentCompanies: RecentCompaniesReducer,
  searches: SearchReducer,
  loading: LoadingReducer,
  backgroundLoading: BackgroundLoadingReducer,
  recentEventsComponentLoading: RecentEventsComponentLoadingReducer,
  subscribedComponentLoading: SubscribedComponentLoadingReducer,
  user: UserReducer,
  modal: ModalReducer,
  insights: InsightsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
