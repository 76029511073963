import { Enums, Interfaces } from '@configur-tech/discover-core-types';
import { faFileImport, faFilter } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { startCase } from 'lodash';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import FilterLeadsSideBar, {
  RevenueRanges,
  ScoreRanges,
} from '../../components/FilterLeadsSidebar/FilterLeadsSidebar';
import { ModalTypes } from '../../components/Modal/Modal';
import Table from '../../components/Table/Table';
import { DARK_THEME } from '../../consts/projectConsts';
import { RouteName } from '../../enums/RouteName';
import useCompanies from '../../hooks/companies/useCompanies';
import useSearch from '../../hooks/searches/useSearch';
import useLoggedInUser from '../../hooks/user/useLoggedInUser';
import * as TC from '../../main/theme';
import { hideLoading, showLoading } from '../../store/loading/overallLoading';
import { showModal } from '../../store/modal';
import { getLocalTheme } from '../../utils/dark-theme/getLocalTheme';
import * as SC from './styled';

const PAGE_SIZE = 20;
const EXTRA_LARGE = 'xl';

// Search
const ID_FIELD_ALIAS = 'id';
const ID_FIELD_ID = '8bdd889f-db6c-4fd5-964d-c27a14a33547';

// Company
const DOMAIN_FIELD_ALIAS = 'domain';
const DOMAIN_FIELD_ID = '7902cea0-6e59-4211-8802-5501fdb91739';

const NAME_FIELD_ALIAS = 'companyName';
const NAME_FIELD_ID = 'e8f45319-7057-41d8-9727-56375a3b763d';

const INDUSTRY_FIELD_ALIAS = 'industries';
const INDUSTRY_FIELD_ID = 'ca15ec98-b6b7-4cce-889c-baeef996a0d7';

const COUNTRY_FIELD_ALIAS = 'location';
const COUNTRY_FIELD_ID = 'e180f523-b8ef-44f4-a2bb-21b59b7bc3e6';

const REVENUE_FIELD_ALIAS = 'revenue';
const REVENUE_FIELD_ID = 'b673c1af-c686-4c8d-9dea-9626ff14ff32';

const LOCATION_FIELD_ALIAS = 'location';
const LOCATION_FIELD_ID = 'e180f523-b8ef-44f4-a2bb-21b59b7bc3e6';

const SEARCH_ID_FIELD_ALIAS = 'searchId';
const SEARCH_ID_FIELD_ID = '49bb17dc-dad6-4cfc-8a49-5d891037de77';

const RECRUITMENT_SCORE_ALIAS = 'recruitmentScore';
const RECRUITMENT_SCORE_ID = 'b1dffbc6-bd75-4980-9c12-8f8a94d2a91c';

const TECHNOLOGY_SCORE_ALIAS = 'technologyScore';
const TECHNOLOGY_SCORE_ID = '80ac7329-3ca9-4478-a260-39f5ce4dcf17';

const EVENTS_SCORE_ALIAS = 'eventsScore';
const EVENTS_SCORE_ID = '5f927a8b-7f5b-46f0-8323-a1036527f887';

const WEBSITE_SCORE_ALIAS = 'websiteScore';
const WEBSITE_SCORE_ID = '29f01fa0-725c-48f3-89b7-9ee7873399e0';

const OVERALL_SCORE_ALIAS = 'overallScore';
const OVERALL_SCORE_ID = '1e99d449-fc98-4c25-8097-7212989df256';

const FINANCE_SCORE_ID = '7442af0c-585d-4c55-b833-43b3ba42ee91';
const FINANCE_SCORE_ALIAS = 'financeScore';

const INSIGHTS_SCORE_ID = 'c633d80c-4b89-457a-aa9c-0709e8e309f9';
const INSIGHTS_SCORE_ALIAS = 'insightsScore';

const CompaniesPage: FC = () => {
  const location = useLocation();
  const themeContext = useContext(ThemeContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const { searchId } = useParams();
  const { loggedInUser } = useLoggedInUser();
  const { companies, getCompaniesWithScore } = useCompanies();
  const { searches, getSearches } = useSearch();

  const isViewingTracked = location.pathname.includes(`${RouteName.TRACKED}`);

  const [queryOptions, setQueryOptions] = useState<{
    pagination: number;
    searchValue?: string;
  }>({ pagination: 1, searchValue: '' });
  const [filterDomains, setFilterDomains] = useState<string[]>();
  const [fetchedSearch, setFetchedSearch] = useState<boolean>(false);
  const [fetchedFilters, setFetchedFilters] = useState<boolean>(false);
  const [boostingTech, setBoostingTech] = useState<boolean>(false);
  const [boostingFinance, setBoostingFinance] = useState<boolean>(false);
  const [boosterInformation, setBoosterInformation] =
    useState<Interfaces.Boosters[]>();
  const [filtersOpen, setFiltersOpen] = useState<boolean>(false);

  const localTheme = getLocalTheme();
  // Filters props
  const [companyNameFilter, setCompanyNameFilter] = useState<string>();
  const [companySectorFilter, setCompanySectorFilter] = useState<string>();
  const [companyCountryFilter, setCompanyCountryFilter] = useState<string>('');
  const [companyRevenueFilter, setCompanyRevenueFilter] = useState<string>('');
  const [discoverScoreFilter, setDiscoverScoreFilter] = useState<string>('');
  const [hiringScoreFilter, setHiringScoreFilter] = useState<string>('');
  const [newsScoreFilter, setNewsScoreFilter] = useState<string>('');
  const [techScoreFilter, setTechScoreFilter] = useState<string>('');
  const [financeScoreFilter, setFinanceScoreFilter] = useState<string>('');
  const [websiteScoreFilter, setWebsiteScoreFilter] = useState<string>('');
  const [insiderScoreFilter, setInsiderScoreFilter] = useState<string>('');

  // To fetch filters
  const [filtersFetched, setFiltersFetched] = useState<boolean>(false);
  const [filtersCleared, setFiltersCleared] = useState<boolean>(false);

  // No searchId = skip fetching search
  useEffect(() => {
    if (!searchId && !isViewingTracked) {
      setFetchedFilters(true);
    }
  }, [searchId, isViewingTracked]);

  // Viewing tracked - use domains from User
  useEffect(() => {
    if (isViewingTracked && loggedInUser?.auth0Id) {
      const domains = (loggedInUser.savedCompanies as string[]).map((d) =>
        d.replace('www.', ''),
      );
      setFilterDomains(domains.concat(domains.map((d) => `www.${d}`)));
      setFetchedFilters(true);
    }
  }, [isViewingTracked, loggedInUser]);

  // SearchID = fetch search
  useEffect(() => {
    if (searchId) {
      (async () => {
        await getSearches(
          [
            {
              operator: '=',
              field: ID_FIELD_ID,
              alias: ID_FIELD_ALIAS,
              datasetMetaId: process.env.REACT_APP_SEARCHES_DATASET_META || '',
              value: { type: 'CONSTANT', value: searchId },
            },
          ],
          true,
        );
        setFetchedSearch(true);
        setFetchedFilters(true);
      })();
    }
  }, [searchId, getSearches]);

  // Set domains
  useEffect(() => {
    if (searchId && fetchedSearch) {
      const searchItem = searches?.find(
        (e) => (e as unknown as { id: string }).id.toString() === searchId,
      );

      if (searchItem) {
        const domains = (searchItem.domain as string[]).map((d) =>
          d.replace('www.', ''),
        );
        setFilterDomains(domains);
        setFetchedFilters(true);
      }
    }
  }, [searchId, searches, fetchedSearch]);

  const updatePagination = useCallback((page: number) => {
    setQueryOptions((q) => ({
      ...q,
      pagination: page,
    }));
  }, []);

  useMemo(() => {
    (async () => {
      if (filtersCleared) {
        dispatch(showLoading({ text: 'Loading data...' }));

        const query: Record<string, unknown>[] = [
          {
            operator: 'AND',
            value: [],
          },
        ];

        (query[0].value as Record<string, unknown>[]).push({
          operator: '=',
          field: SEARCH_ID_FIELD_ID,
          alias: SEARCH_ID_FIELD_ALIAS,
          datasetMetaId: process.env.REACT_APP_COMPANIES_DATASET_META,
          value: { type: 'CONSTANT', value: '0' },
        });

        (query[0].value as Record<string, unknown>[]).push({
          operator: '<=',
          field: RECRUITMENT_SCORE_ID,
          alias: RECRUITMENT_SCORE_ALIAS,
          datasetMetaId: process.env.REACT_APP_COMPANIES_DATASET_META,
          value: { type: 'CONSTANT', value: '100' },
        });

        (query[0].value as Record<string, unknown>[]).push({
          operator: '<=',
          field: TECHNOLOGY_SCORE_ID,
          alias: TECHNOLOGY_SCORE_ALIAS,
          datasetMetaId: process.env.REACT_APP_COMPANIES_DATASET_META,
          value: { type: 'CONSTANT', value: '100' },
        });

        (query[0].value as Record<string, unknown>[]).push({
          operator: '<=',
          field: EVENTS_SCORE_ID,
          alias: EVENTS_SCORE_ALIAS,
          datasetMetaId: process.env.REACT_APP_COMPANIES_DATASET_META,
          value: { type: 'CONSTANT', value: '100' },
        });

        (query[0].value as Record<string, unknown>[]).push({
          operator: '<=',
          field: WEBSITE_SCORE_ID,
          alias: WEBSITE_SCORE_ALIAS,
          datasetMetaId: process.env.REACT_APP_COMPANIES_DATASET_META,
          value: { type: 'CONSTANT', value: '100' },
        });
        (query[0].value as Record<string, unknown>[]).push({
          operator: '<=',
          field: OVERALL_SCORE_ID,
          alias: OVERALL_SCORE_ALIAS,
          datasetMetaId: process.env.REACT_APP_COMPANIES_DATASET_META,
          value: { type: 'CONSTANT', value: '100' },
        });

        const queryRequest = `limit=${PAGE_SIZE}&pageNum=${
          queryOptions.pagination
        }&conditions=${encodeURIComponent(JSON.stringify(query))}`;

        await getCompaniesWithScore(queryRequest);
      }

      setFiltersCleared(false);
      dispatch(hideLoading());
    })();
  }, [
    dispatch,
    filtersCleared,
    getCompaniesWithScore,
    queryOptions.pagination,
  ]);

  useMemo(() => {
    (async () => {
      if (filtersFetched) {
        setFiltersFetched(false);
        dispatch(showLoading({ text: 'Loading data...' }));

        const query: Record<string, unknown>[] = [
          {
            operator: 'AND',
            value: [],
          },
        ];

        if (companyNameFilter?.length) {
          (query[0].value as Record<string, unknown>[]).push({
            operator: 'LIKE',
            field: NAME_FIELD_ID,
            alias: NAME_FIELD_ALIAS,
            datasetMetaId: process.env.REACT_APP_COMPANIES_DATASET_META,
            value: { type: 'CONSTANT', value: `%${companyNameFilter}%` },
          });
        }

        if (companySectorFilter?.length) {
          (query[0].value as Record<string, unknown>[]).push({
            operator: 'LIKE',
            field: INDUSTRY_FIELD_ID,
            alias: INDUSTRY_FIELD_ALIAS,
            datasetMetaId: process.env.REACT_APP_COMPANIES_DATASET_META,
            value: { type: 'CONSTANT', value: `%${companySectorFilter}%` },
          });
        }

        if (companyCountryFilter?.length) {
          (query[0].value as Record<string, unknown>[]).push({
            operator: 'LIKE',
            field: COUNTRY_FIELD_ID,
            alias: COUNTRY_FIELD_ALIAS,
            datasetMetaId: process.env.REACT_APP_COMPANIES_DATASET_META,
            value: {
              type: 'CONSTANT',
              value: `%${startCase(companyCountryFilter)}%`,
            },
          });
        }

        if (companyRevenueFilter !== '') {
          let operator;
          let numericalValue;

          if (companyRevenueFilter === RevenueRanges.ZERO_TO_HALF_MILLION) {
            numericalValue = '500';
            operator = '<';
          }

          if (
            companyRevenueFilter === RevenueRanges.HALF_MILLION_TO_ONE_MILLION
          ) {
            numericalValue = ['500', '1000'];
            operator = 'BETWEEN';
          }

          if (
            companyRevenueFilter === RevenueRanges.ONE_MILLION_TO_FIVE_MILLION
          ) {
            numericalValue = ['1000', '5000'];
            operator = 'BETWEEN';
          }

          if (
            companyRevenueFilter === RevenueRanges.FIVE_MILLION_TO_TEN_MILLION
          ) {
            numericalValue = ['5000', '10000'];
            operator = 'BETWEEN';
          }

          if (
            companyRevenueFilter ===
            RevenueRanges.TEN_MILLION_TO_TWENTY_FIVE_MILLION
          ) {
            numericalValue = ['10000', '25000'];
            operator = 'BETWEEN';
          }

          if (
            companyRevenueFilter ===
            RevenueRanges.TWENTY_FIVE_MILLION_TO_FIFTY_MILLION
          ) {
            numericalValue = ['25000', '50000'];
            operator = 'BETWEEN';
          }

          if (companyRevenueFilter === RevenueRanges.FIFTY_MILLION_PLUS) {
            numericalValue = '50000';
            operator = '>=';
          }

          (query[0].value as Record<string, unknown>[]).push({
            operator,
            field: REVENUE_FIELD_ID,
            alias: REVENUE_FIELD_ALIAS,
            datasetMetaId: process.env.REACT_APP_COMPANIES_DATASET_META,
            value: { type: 'CONSTANT', value: numericalValue },
          });
        }

        if (discoverScoreFilter !== '') {
          let operator;
          let numericalValue;

          if (discoverScoreFilter === ScoreRanges.ZERO_TO_THIRTY_NINE) {
            numericalValue = '40';
            operator = '<';
          }

          if (discoverScoreFilter === ScoreRanges.FORTY_TO_ONE_HUNDRED) {
            numericalValue = '99';
            operator = '<=';
          }

          if (discoverScoreFilter === ScoreRanges.ONE_HUNDRED_PLUS) {
            numericalValue = '100';
            operator = '>=';
          }

          (query[0].value as Record<string, unknown>[]).push({
            operator,
            field: OVERALL_SCORE_ID,
            alias: OVERALL_SCORE_ALIAS,
            datasetMetaId: process.env.REACT_APP_COMPANIES_DATASET_META,
            value: { type: 'CONSTANT', value: numericalValue },
          });
        }

        if (hiringScoreFilter !== '') {
          let operator;
          let numericalValue;

          if (hiringScoreFilter === ScoreRanges.ZERO_TO_THIRTY_NINE) {
            numericalValue = '40';
            operator = '<';
          }

          if (hiringScoreFilter === ScoreRanges.FORTY_TO_ONE_HUNDRED) {
            numericalValue = '99';
            operator = '<=';
          }

          if (hiringScoreFilter === ScoreRanges.ONE_HUNDRED_PLUS) {
            numericalValue = '100';
            operator = '>=';
          }

          (query[0].value as Record<string, unknown>[]).push({
            operator,
            field: RECRUITMENT_SCORE_ID,
            alias: RECRUITMENT_SCORE_ALIAS,
            datasetMetaId: process.env.REACT_APP_COMPANIES_DATASET_META,
            value: { type: 'CONSTANT', value: numericalValue },
          });
        }

        if (newsScoreFilter !== '') {
          let operator;
          let numericalValue;

          if (newsScoreFilter === ScoreRanges.ZERO_TO_THIRTY_NINE) {
            numericalValue = '40';
            operator = '<';
          }

          if (newsScoreFilter === ScoreRanges.FORTY_TO_ONE_HUNDRED) {
            numericalValue = '99';
            operator = '<=';
          }

          if (newsScoreFilter === ScoreRanges.ONE_HUNDRED_PLUS) {
            numericalValue = '100';
            operator = '>=';
          }

          (query[0].value as Record<string, unknown>[]).push({
            operator,
            field: EVENTS_SCORE_ID,
            alias: EVENTS_SCORE_ALIAS,
            datasetMetaId: process.env.REACT_APP_COMPANIES_DATASET_META,
            value: { type: 'CONSTANT', value: numericalValue },
          });
        }

        if (techScoreFilter !== '') {
          let operator;
          let numericalValue;

          if (techScoreFilter === ScoreRanges.ZERO_TO_THIRTY_NINE) {
            numericalValue = '40';
            operator = '<';
          }

          if (techScoreFilter === ScoreRanges.FORTY_TO_ONE_HUNDRED) {
            numericalValue = '99';
            operator = '<=';
          }

          if (techScoreFilter === ScoreRanges.ONE_HUNDRED_PLUS) {
            numericalValue = '100';
            operator = '>=';
          }

          (query[0].value as Record<string, unknown>[]).push({
            operator,
            field: TECHNOLOGY_SCORE_ID,
            alias: TECHNOLOGY_SCORE_ALIAS,
            datasetMetaId: process.env.REACT_APP_COMPANIES_DATASET_META,
            value: { type: 'CONSTANT', value: numericalValue },
          });
        }

        if (financeScoreFilter !== '') {
          let operator;
          let numericalValue;

          if (financeScoreFilter === ScoreRanges.ZERO_TO_THIRTY_NINE) {
            numericalValue = '40';
            operator = '<';
          }

          if (financeScoreFilter === ScoreRanges.FORTY_TO_ONE_HUNDRED) {
            numericalValue = '99';
            operator = '<=';
          }

          if (financeScoreFilter === ScoreRanges.ONE_HUNDRED_PLUS) {
            numericalValue = '100';
            operator = '>=';
          }

          (query[0].value as Record<string, unknown>[]).push({
            operator,
            field: FINANCE_SCORE_ID,
            alias: FINANCE_SCORE_ALIAS,
            datasetMetaId: process.env.REACT_APP_COMPANIES_DATASET_META,
            value: { type: 'CONSTANT', value: numericalValue },
          });
        }

        if (websiteScoreFilter !== '') {
          let operator;
          let numericalValue;

          if (websiteScoreFilter === ScoreRanges.ZERO_TO_THIRTY_NINE) {
            numericalValue = '40';
            operator = '<';
          }

          if (websiteScoreFilter === ScoreRanges.FORTY_TO_ONE_HUNDRED) {
            numericalValue = '99';
            operator = '<=';
          }

          if (websiteScoreFilter === ScoreRanges.ONE_HUNDRED_PLUS) {
            numericalValue = '100';
            operator = '>=';
          }

          (query[0].value as Record<string, unknown>[]).push({
            operator,
            field: WEBSITE_SCORE_ID,
            alias: WEBSITE_SCORE_ALIAS,
            datasetMetaId: process.env.REACT_APP_COMPANIES_DATASET_META,
            value: { type: 'CONSTANT', value: numericalValue },
          });
        }

        if (insiderScoreFilter !== '') {
          let operator;
          let numericalValue;

          if (insiderScoreFilter === ScoreRanges.ZERO_TO_THIRTY_NINE) {
            numericalValue = '40';
            operator = '<';
          }

          if (insiderScoreFilter === ScoreRanges.FORTY_TO_ONE_HUNDRED) {
            numericalValue = '99';
            operator = '<=';
          }

          if (insiderScoreFilter === ScoreRanges.ONE_HUNDRED_PLUS) {
            numericalValue = '100';
            operator = '>=';
          }

          (query[0].value as Record<string, unknown>[]).push({
            operator,
            field: INSIGHTS_SCORE_ID,
            alias: INSIGHTS_SCORE_ALIAS,
            datasetMetaId: process.env.REACT_APP_COMPANIES_DATASET_META,
            value: { type: 'CONSTANT', value: numericalValue },
          });
        }

        const queryRequest = `limit=${PAGE_SIZE}&pageNum=${
          queryOptions.pagination
        }&conditions=${encodeURIComponent(JSON.stringify(query))}`;

        await getCompaniesWithScore(queryRequest);
        dispatch(hideLoading());
      }
    })();
  }, [
    companyNameFilter,
    companyRevenueFilter,
    companySectorFilter,
    discoverScoreFilter,
    dispatch,
    filtersFetched,
    financeScoreFilter,
    getCompaniesWithScore,
    hiringScoreFilter,
    insiderScoreFilter,
    newsScoreFilter,
    queryOptions.pagination,
    techScoreFilter,
    websiteScoreFilter,
  ]);

  // Fetch companies
  useEffect(() => {
    (async () => {
      if (fetchedFilters) {
        dispatch(showLoading({ text: 'Loading data...' }));

        const query: Record<string, unknown>[] = [
          {
            operator: 'AND',
            value: [],
          },
        ];

        if (!searchId) {
          (query[0].value as Record<string, unknown>[]).push({
            operator: '=',
            field: SEARCH_ID_FIELD_ID,
            alias: SEARCH_ID_FIELD_ALIAS,
            datasetMetaId: process.env.REACT_APP_COMPANIES_DATASET_META,
            value: { type: 'CONSTANT', value: '0' },
          });

          (query[0].value as Record<string, unknown>[]).push({
            operator: '<=',
            field: RECRUITMENT_SCORE_ID,
            alias: RECRUITMENT_SCORE_ALIAS,
            datasetMetaId: process.env.REACT_APP_COMPANIES_DATASET_META,
            value: { type: 'CONSTANT', value: '100' },
          });

          (query[0].value as Record<string, unknown>[]).push({
            operator: '<=',
            field: TECHNOLOGY_SCORE_ID,
            alias: TECHNOLOGY_SCORE_ALIAS,
            datasetMetaId: process.env.REACT_APP_COMPANIES_DATASET_META,
            value: { type: 'CONSTANT', value: '100' },
          });

          (query[0].value as Record<string, unknown>[]).push({
            operator: '<=',
            field: EVENTS_SCORE_ID,
            alias: EVENTS_SCORE_ALIAS,
            datasetMetaId: process.env.REACT_APP_COMPANIES_DATASET_META,
            value: { type: 'CONSTANT', value: '100' },
          });

          (query[0].value as Record<string, unknown>[]).push({
            operator: '<=',
            field: WEBSITE_SCORE_ID,
            alias: WEBSITE_SCORE_ALIAS,
            datasetMetaId: process.env.REACT_APP_COMPANIES_DATASET_META,
            value: { type: 'CONSTANT', value: '100' },
          });
          (query[0].value as Record<string, unknown>[]).push({
            operator: '<=',
            field: OVERALL_SCORE_ID,
            alias: OVERALL_SCORE_ALIAS,
            datasetMetaId: process.env.REACT_APP_COMPANIES_DATASET_META,
            value: { type: 'CONSTANT', value: '100' },
          });
        }

        if (isViewingTracked) {
          (query[0].value as Record<string, unknown>[]).push({
            operator: 'IN',
            field: DOMAIN_FIELD_ID,
            alias: DOMAIN_FIELD_ALIAS,
            datasetMetaId: process.env.REACT_APP_COMPANIES_DATASET_META,
            value: {
              type: 'CONSTANT',
              value: filterDomains?.slice(queryOptions.pagination, PAGE_SIZE),
            },
          });
        }

        if (searchId && fetchedSearch) {
          const search = searches.find((s) => s.id === searchId);
          if (search?.boosters.length) {
            setBoosterInformation(search.boosters as Interfaces.Boosters[]);
            (query[0].value as Record<string, unknown>[]).push({
              operator: '=',
              field: SEARCH_ID_FIELD_ID,
              alias: SEARCH_ID_FIELD_ALIAS,
              datasetMetaId: process.env.REACT_APP_COMPANIES_DATASET_META,
              value: {
                type: 'CONSTANT',
                value: searchId,
              },
            });

            // Boosting Tech
            if (
              (search.boosters as Interfaces.Boosters[]).find(
                (b) => b.boostType === Enums.BoosterTypes.USES_SOFTWARE,
              )
            ) {
              setBoostingTech(true);
            }
            // Boosting Finance
            if (
              (search.boosters as Interfaces.Boosters[]).find(
                (b) => b.boostType === Enums.BoosterTypes.RECEIVES_FINANCING,
              )
            ) {
              setBoostingFinance(true);
            }
          } else {
            (query[0].value as Record<string, unknown>[]).push({
              operator: '=',
              field: SEARCH_ID_FIELD_ID,
              alias: SEARCH_ID_FIELD_ALIAS,
              datasetMetaId: process.env.REACT_APP_COMPANIES_DATASET_META,
              value: {
                type: 'CONSTANT',
                value: searchId,
              },
            });
          }
        }

        if (queryOptions.searchValue) {
          (query[0].value as Record<string, unknown>[]).push({
            operator: 'OR',
            value: [
              {
                operator: 'LIKE',
                field: NAME_FIELD_ID,
                alias: NAME_FIELD_ALIAS,
                datasetMetaId: process.env.REACT_APP_COMPANIES_DATASET_META,
                value: {
                  type: 'CONSTANT',
                  value: `%${queryOptions.searchValue}%`,
                },
              },
              {
                operator: 'LIKE',
                field: DOMAIN_FIELD_ID,
                alias: DOMAIN_FIELD_ALIAS,
                datasetMetaId: process.env.REACT_APP_COMPANIES_DATASET_META,
                value: {
                  type: 'CONSTANT',
                  value: `%${queryOptions.searchValue}%`,
                },
              },
              {
                operator: 'LIKE',
                field: INDUSTRY_FIELD_ID,
                alias: INDUSTRY_FIELD_ALIAS,
                datasetMetaId: process.env.REACT_APP_COMPANIES_DATASET_META,
                value: {
                  type: 'CONSTANT',
                  value: `%${queryOptions.searchValue}%`,
                },
              },
              {
                operator: 'LIKE',
                field: LOCATION_FIELD_ID,
                alias: LOCATION_FIELD_ALIAS,
                datasetMetaId: process.env.REACT_APP_COMPANIES_DATASET_META,
                value: {
                  type: 'CONSTANT',
                  value: `%${queryOptions.searchValue}%`,
                },
              },
            ],
          });
        }

        const queryRequest = `limit=${PAGE_SIZE}&pageNum=${
          queryOptions.pagination
        }&conditions=${encodeURIComponent(JSON.stringify(query))}`;

        await getCompaniesWithScore(queryRequest);

        dispatch(hideLoading());
      }
    })();
  }, [
    dispatch,
    filterDomains,
    searchId,
    isViewingTracked,
    fetchedFilters,
    queryOptions.pagination,
    queryOptions.searchValue,
    getCompaniesWithScore,
    searches,
    fetchedSearch,
  ]);

  const data = useMemo(() => {
    return companies?.entries?.length ? companies.entries : [];
  }, [companies]);

  const controlledPageCount = useMemo(() => {
    return companies?.next?.totalCount ? companies.next.totalCount : 1000;
  }, [companies]);

  const columns = useMemo(
    () => [
      {
        Header: 'revenue',
        accessor: 'revenue',
      },
      {
        Header: 'type',
        accessor: 'type',
      },
      {
        Header: 'companyLogo',
        accessor: 'companyLogo',
      },
      {
        Header: 'companyDescription',
        accessor: 'companyDescription',
      },
      {
        Header: 'domain',
        accessor: 'domain',
      },
      {
        Header: 'employeeCount',
        accessor: 'employeeCount',
      },
      {
        Header: 'foundedYear',
        accessor: 'foundedYear',
      },
      {
        Header: 'Company',
        accessor: 'companyName',
      },
      {
        Header: 'Company Location',
        accessor: 'location',
      },
      {
        Header: 'Sectors',
        accessor: 'industries',
      },
      {
        Header: 'Hiring',
        accessor: 'recruitmentScore',
      },
      {
        Header: 'News',
        accessor: 'eventsScore',
      },
      {
        Header: 'Tech',
        accessor: 'technologyScore',
      },
      {
        Header: 'Finance',
        accessor: 'financeScore',
      },
      {
        Header: 'Website',
        accessor: 'websiteScore',
      },
      {
        Header: 'Insider',
        accessor: 'insightsScore',
      },
      {
        id: 'leadQuality',
        Header: 'Overall',
        accessor: 'overallScore',
      },
      {
        Header: 'customerBoosters',
        accessor: 'customerBoosters',
      },
    ],
    [],
  );

  return (
    <TC.InnerPageContainer>
      <TC.PageBodyContainer style={{ paddingBottom: 0 }}>
        <TC.PageHeader>
          <TC.StyledH1>
            <TC.StyledLink
              onClick={() => history.push(RouteName.DASHBOARD)}
              style={{
                color: themeContext.colors.general.grey,
                fontWeight: 100,
              }}
            >
              Discover /{' '}
            </TC.StyledLink>
            <b style={{ color: themeContext.colors.general.purple }}>Enrich</b>
          </TC.StyledH1>

          <SC.ActionButtonContainerWrapper>
            <SC.ActionButtonContainer
              onClick={() =>
                dispatch(
                  showModal({
                    forceOpen: true,
                    visible: true,
                    modal: ModalTypes.PABM,
                    scrollable: true,
                  }),
                )
              }
            >
              <FontAwesomeIcon
                icon={faFileImport}
                size={EXTRA_LARGE}
                color={themeContext.colors.general.green}
              />
              <p>Export PABM</p>
            </SC.ActionButtonContainer>

            <SC.ActionButtonContainer
              onClick={() =>
                dispatch(
                  showModal({
                    forceOpen: true,
                    visible: true,
                    modal: ModalTypes.ENRICH,
                  }),
                )
              }
            >
              <FontAwesomeIcon
                icon={faFileImport}
                size={EXTRA_LARGE}
                color={themeContext.colors.general.green}
              />
              <p>Enrich Leads</p>
            </SC.ActionButtonContainer>
            {/*<SC.ActionButtonContainer disabled={true}>*/}
            {/*  <FontAwesomeIcon*/}
            {/*    icon={faDownload}*/}
            {/*    size={EXTRA_LARGE}*/}
            {/*    color={themeContext.colors.general.grey}*/}
            {/*  />*/}
            {/*  <p style={{ color: themeContext.colors.general.grey }}>*/}
            {/*    Export Leads*/}
            {/*  </p>*/}
            {/*</SC.ActionButtonContainer>*/}
            <SC.ActionButtonContainer
              onClick={() => setFiltersOpen(!filtersOpen)}
            >
              <FontAwesomeIcon
                icon={faFilter}
                size={EXTRA_LARGE}
                color={
                  filtersOpen
                    ? themeContext.colors.general.green
                    : themeContext.colors.general.grey
                }
              />
              <p
                style={{
                  color: filtersOpen
                    ? localTheme === DARK_THEME
                      ? themeContext.colors.general.white
                      : themeContext.colors.general.black
                    : themeContext.colors.general.grey,
                }}
              >
                Toggle Filters
              </p>
            </SC.ActionButtonContainer>
          </SC.ActionButtonContainerWrapper>
        </TC.PageHeader>
        <SC.TableAndFooterContainer>
          <Table
            columns={columns}
            data={data}
            paginationAction={updatePagination}
            pagination={queryOptions.pagination}
            sortBy={[{ id: 'leadQuality', desc: true }]}
            hiddenColumns={[
              'companyDescription',
              'domain',
              'employeeCount',
              'foundedYear',
              'revenue',
              'type',
              'customerBoosters',
            ]}
            routeName={
              searchId
                ? RouteName.SEARCH_COMPANY
                : isViewingTracked
                ? RouteName.TRACKED_COMPANY
                : RouteName.COMPANY
            }
            noResultsMessage={
              isViewingTracked
                ? 'No Tracked Companies found'
                : 'No Companies could be found for that search query'
            }
            controlledPageCount={controlledPageCount}
            isBoostingTech={boostingTech}
            isBoostingFinance={boostingFinance}
            filtersOpen={filtersOpen}
          />
        </SC.TableAndFooterContainer>
      </TC.PageBodyContainer>
      {filtersOpen && (
        <FilterLeadsSideBar
          setCompanyNameFilter={setCompanyNameFilter}
          setCompanySectorFilter={setCompanySectorFilter}
          setCompanyCountryFilter={setCompanyCountryFilter}
          setCompanyRevenueFilter={setCompanyRevenueFilter}
          setDiscoverScoreFilter={setDiscoverScoreFilter}
          setHiringScoreFilter={setHiringScoreFilter}
          setNewsScoreFilter={setNewsScoreFilter}
          setTechScoreFilter={setTechScoreFilter}
          setFinanceScoreFilter={setFinanceScoreFilter}
          setWebsiteScoreFilter={setWebsiteScoreFilter}
          setInsiderScoreFilter={setInsiderScoreFilter}
          companyNameFilter={companyNameFilter}
          companySectorFilter={companySectorFilter}
          companyCountryFilter={companyCountryFilter}
          companyRevenueFilter={companyRevenueFilter}
          discoverScoreFilter={discoverScoreFilter}
          hiringScoreFilter={hiringScoreFilter}
          newsScoreFilter={newsScoreFilter}
          techScoreFilter={techScoreFilter}
          financeScoreFilter={financeScoreFilter}
          websiteScoreFilter={websiteScoreFilter}
          insiderScoreFilter={insiderScoreFilter}
          setFiltersFetched={setFiltersFetched}
          setFiltersCleared={setFiltersCleared}
        />
      )}
    </TC.InnerPageContainer>
  );
};

export default CompaniesPage;
