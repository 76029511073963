import styled from 'styled-components';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.margin.xlarge};
  > h1 {
    align-self: center;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-self: center;
  margin-top: ${({ theme }) => theme.margin.xlarge};
`;
