import { Interfaces } from '@configur-tech/discover-core-types';
import { EVENT_DATE_ORDERING } from '../../consts/apiConsts';
import HttpHelper from '../../utils/http-helper/HttpHelper';

export interface EntriesResponse {
  statusCode: number;
  message: string;
  data: {
    entries: Interfaces.Events[];
    next: {
      hasMoreEntries: boolean;
      totalCount: number;
    };
  };
}

const EVENTS_ENDPOINT = process.env['REACT_APP_EVENTS_API'] || '';
export const EVENT_REQUEST_LIMIT = 200;

export default class EventService {
  public static async getAllEvents(
    token: string,
    filterQuery?: string,
    encoded?: boolean,
  ) {
    let page = 1;
    let result: Interfaces.Events[] = [];
    let pageResult: Interfaces.Events[] = [];

    do {
      const pageRes = await this.getEvents(
        token,
        filterQuery,
        encoded,
        EVENT_REQUEST_LIMIT,
        page,
        EVENT_DATE_ORDERING,
      );

      result = result.concat(pageRes.data.entries);

      pageResult = pageRes.data.entries;
      page++;
    } while (pageResult.length === EVENT_REQUEST_LIMIT);

    return result;
  }

  public static async getEvents(
    token: string,
    filterQuery?: string,
    encoded?: boolean,
    limit?: number,
    pageNum?: number,
    orderBy?: string,
  ): Promise<EntriesResponse> {
    const ah = new HttpHelper(token);

    const constructUrl = (): string => {
      const orderByQuery = !orderBy ? '' : `orderBy=${orderBy}&`;

      if (filterQuery && !encoded) {
        return `${EVENTS_ENDPOINT}?${orderByQuery}${filterQuery}`;
      }

      if (filterQuery && encoded && !limit && pageNum) {
        const encodedQuery = encodeURIComponent(filterQuery);
        return `${EVENTS_ENDPOINT}?${orderByQuery}conditions=${encodedQuery}`;
      }

      if (filterQuery && encoded && limit && pageNum) {
        const encodedQuery = encodeURIComponent(filterQuery);
        return `${EVENTS_ENDPOINT}?${orderByQuery}conditions=${encodedQuery}&pageNum=${pageNum}&limit=${limit}`;
      }

      if (filterQuery && encoded && limit) {
        const encodedQuery = encodeURIComponent(filterQuery);
        return `${EVENTS_ENDPOINT}?${orderByQuery}limit=${limit}&conditions=${encodedQuery}`;
      }

      if (filterQuery && encoded) {
        const encodedQuery = encodeURIComponent(filterQuery);
        return `${EVENTS_ENDPOINT}?${orderByQuery}conditions=${encodedQuery}`;
      }

      return `${EVENTS_ENDPOINT}?${orderByQuery}`;
    };

    return await ah.get<EntriesResponse>(constructUrl(), undefined, {
      'X-API-Key': process.env['REACT_APP_EVENTS_X_API_KEY'] || '',
    });
  }
}
