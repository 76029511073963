import { Interfaces } from '@configur-tech/discover-core-types';
import {
  faBrowser,
  faCalendarDays,
  faCircleCheck,
  faDollarSign,
  faLaptopMobile,
  faNewspaper,
  faRadar,
  faSterlingSign,
  faUserPlus,
  faUsers,
} from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { startCase } from 'lodash';
import { DateTime } from 'luxon';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import CompanyInformationDetail from '../../components/CompanyInformation/CompanyInformationDetail';
import EventsTable from '../../components/EventsTable/EventsTable';
import { NoResultsWrapper } from '../../components/EventsTable/styled';
import {
  AcquisitionEvents,
  ContractEvents,
  CorporateChallengesEvents,
  CostCuttingEvents,
  EventCategories,
  ExpansionEvents,
  InsightEvents,
  InvestmentEvents,
  LeadershipEvents,
  NewOfferingEvents,
  PartnershipEvents,
  RecognitionEvents,
  RelationalEvents,
} from '../../enums/EventCategories';
import { EventGroup } from '../../enums/EventGroup';
import { RouteName } from '../../enums/RouteName';
import useCompanies from '../../hooks/companies/useCompanies';
import useEvents from '../../hooks/events/useEvents';
import useInsights from '../../hooks/insights/useInsights';

import * as TC from '../../main/theme';
import { StyledH1, StyledText } from '../../main/theme';
import { hideLoading, showLoading } from '../../store/loading/overallLoading';

import { calculateIcon } from '../../utils/events/IconHelper';
import { convertInsightToDescription } from '../../utils/insights/convertInsightToDescription';
import * as SC from './styled';

const DATE_FORMAT = 'LLL yy';
const INSIDER_CATEGORY = 'Insider';

const COMPANY_DOMAIN_FIELD = '7902cea0-6e59-4211-8802-5501fdb91739';
const COMPANY_DOMAIN_ALIAS = 'domain';

const TWO_TIMES = '2x';
const HTTPS = 'https://';

type PlEventWithId = Interfaces.PLEvent['attributes'] & { id?: string };

interface DropdownOptions {
  key: string;
  text: string;
  value: string;
}

const CompanyPage: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);

  const { companyDomain } = useParams();

  const { companies, getCompaniesWithScore } = useCompanies();
  const { events, getEventsFromDomain } = useEvents();
  const { insights, getInsightsFromDomains } = useInsights();

  const [isLogoDefined, setIsLogoDefined] = useState(false);
  const [eventData, setEventData] = useState<Record<string, unknown>[]>([]);
  const [filterValue, setFilterValue] = useState<EventGroup>();
  const [companyData, setCompanyData] = useState<Interfaces.Company>();
  const [insightsData, setInsightsData] = useState<Interfaces.Insights[]>([]);
  const [eventsAndInsightsData, setEventsAndInsightsData] = useState<
    PlEventWithId[]
  >([]);

  // Load company
  useEffect(() => {
    if (!companyData && companyDomain) {
      if (companies?.entries) {
        const exists = companies.entries.find(
          (company) => (company as Interfaces.Company).domain === companyDomain,
        );

        if (exists) {
          return setCompanyData(exists as Interfaces.Company);
        }
      }

      dispatch(showLoading({ text: 'Loading company...' }));
      (async () => {
        await getCompaniesWithScore(
          JSON.stringify([
            {
              operator: '=',
              field: COMPANY_DOMAIN_FIELD,
              alias: COMPANY_DOMAIN_ALIAS,
              datasetMetaId: process.env.REACT_APP_COMPANIES_DATASET_META,
              value: { type: 'CONSTANT', value: companyDomain },
            },
          ]),
          true,
        );
      })();
    }
  }, [dispatch, companyData, companies, companyDomain, getCompaniesWithScore]);

  // Load events
  useEffect(() => {
    if (companyData) {
      (async () => {
        dispatch(showLoading({ text: 'Loading events...' }));
        await getEventsFromDomain(
          companyDomain
            .replace('http://', '')
            .replace('https://', '')
            .replace('www.', ''),
        );
        if (companyData.companyLogo) {
          axios.get(companyData.companyLogo).then((response) => {
            setIsLogoDefined(response.status === 200);
          });
        }

        dispatch(hideLoading());
      })();
    }
  }, [dispatch, companyData, companyDomain, getEventsFromDomain]);

  // Load insights
  useEffect(() => {
    if (!insightsData.length) {
      (async () => {
        dispatch(showLoading({ text: 'Loading insights...' }));
        await getInsightsFromDomains([
          companyDomain
            .replace('http://', '')
            .replace('https://', '')
            .replace('www.', ''),
        ]);

        dispatch(hideLoading());
      })();
    }

    setInsightsData(insights);
  }, [dispatch, insightsData, companyDomain, getInsightsFromDomains, insights]);

  // Will look to make this generic
  // Interest of time will leave in for now
  const calculateFilteredData = (
    enumValue,
    eventAttrs: Interfaces.PLEvent['attributes'][],
  ) => {
    if (enumValue === EventGroup.DISCOVER_INSIDER) {
      return eventAttrs.filter((event) =>
        Object.values(InsightEvents).includes(
          event.categories[0] as InsightEvents,
        ),
      );
    }

    if (enumValue === EventGroup.LEADERSHIP) {
      return eventAttrs.filter((event) =>
        Object.values(LeadershipEvents).includes(
          event.categories[0] as LeadershipEvents,
        ),
      );
    }

    if (enumValue === EventGroup.ACQUISITION) {
      return eventAttrs.filter((event) =>
        Object.values(AcquisitionEvents).includes(
          event.categories[0] as AcquisitionEvents,
        ),
      );
    }

    if (enumValue === EventGroup.EXPANSION) {
      return eventAttrs.filter((event) =>
        Object.values(ExpansionEvents).includes(
          event.categories[0] as ExpansionEvents,
        ),
      );
    }

    if (enumValue === EventGroup.NEW_OFFERING) {
      return eventAttrs.filter((event) =>
        Object.values(NewOfferingEvents).includes(
          event.categories[0] as NewOfferingEvents,
        ),
      );
    }

    if (enumValue === EventGroup.INVESTMENT) {
      return eventAttrs.filter((event) =>
        Object.values(InvestmentEvents).includes(
          event.categories[0] as InvestmentEvents,
        ),
      );
    }

    if (enumValue === EventGroup.COST_CUTTING) {
      return eventAttrs.filter((event) =>
        Object.values(CostCuttingEvents).includes(
          event.categories[0] as CostCuttingEvents,
        ),
      );
    }

    if (enumValue === EventGroup.PARTNERSHIP) {
      return eventAttrs.filter((event) =>
        Object.values(PartnershipEvents).includes(
          event.categories[0] as PartnershipEvents,
        ),
      );
    }

    if (enumValue === EventGroup.RECOGNITION) {
      return eventAttrs.filter((event) =>
        Object.values(RecognitionEvents).includes(
          event.categories[0] as RecognitionEvents,
        ),
      );
    }

    if (enumValue === EventGroup.CONTRACT) {
      return eventAttrs.filter((event) =>
        Object.values(ContractEvents).includes(
          event.categories[0] as ContractEvents,
        ),
      );
    }

    if (enumValue === EventGroup.CORPORATE_CHALLENGES) {
      return eventAttrs.filter((event) =>
        Object.values(CorporateChallengesEvents).includes(
          event.categories[0] as CorporateChallengesEvents,
        ),
      );
    }

    if (enumValue === EventGroup.RELATIONAL) {
      return eventAttrs.filter((event) =>
        Object.values(RelationalEvents).includes(
          event.categories[0] as RelationalEvents,
        ),
      );
    }

    if (!Object.values(EventGroup).includes(enumValue)) {
      return [];
    }
  };

  useMemo(() => {
    const eventsAttrs: PlEventWithId[] = events.map((event) => {
      return {
        ...event.attributes,
        id: event.id,
      };
    });
    const insightsAttrs: Interfaces.PLEvent['attributes'][] = insightsData.map(
      (insight) => {
        return {
          categories: [EventGroup.DISCOVER_INSIDER] as string[],
          title: JSON.stringify(insight), // Need this to set the insights going through to description helper
          found_at: DateTime.fromFormat(
            `${insight.Date.split('-')[0]} ${insight.Date.split('-')[1]}`,
            DATE_FORMAT,
          ).toISODate(), // Unify date format for events
          url: '',
          additional_data: {
            location: '',
            location_data: {
              country: '',
              fuzzy_match: false,
            },
            article_title: '',
            article_sentence: '',
            article_body: '',
            article_source: '',
            article_published_at: '',
            confidence: '',
          },
        };
      },
    );

    const eventsAndInsightsAttr = eventsAttrs.concat(insightsAttrs);

    setEventsAndInsightsData(eventsAndInsightsAttr);

    const filteredEvents = filterValue?.length
      ? calculateFilteredData(filterValue, eventsAndInsightsAttr)
      : eventsAndInsightsAttr;
    setEventData(filteredEvents as Interfaces.PLEvent['attributes'][]);
  }, [events, filterValue, insightsData]);

  const companyInformation = [
    {
      information: `${companyData?.employeeCount || 0}`,
      text: 'Employees',
      iconName: faUsers,
    },
    {
      information: `${companyData?.foundedYear || '2022'}`,
      text: 'Founded',
      iconName: faCalendarDays,
    },
    {
      information: new Intl.NumberFormat(
        companyData?.location?.includes('United Kingdom') ? 'en-GB' : 'en-US',
        {
          style: 'currency',
          currency: companyData?.location?.includes('United Kingdom')
            ? 'GBP'
            : 'USD',
          maximumFractionDigits: 0,
        },
      ).format(companyData?.revenue ? companyData?.revenue * 1000 : 0),
      text: 'Revenue',
      iconName: companyData?.location?.includes('United Kingdom')
        ? faSterlingSign
        : faDollarSign,
    },
  ];

  const calculateDropdownOptionsFromEnums = (enumValues) => {
    const enumArray: string[] = Object.values(enumValues) as string[];

    return enumArray.map((enumValue: string) => {
      return {
        key: enumValue,
        text: `${startCase(enumValue)} (${
          calculateFilteredData(enumValue, eventsAndInsightsData)?.length
        })`,
        value: enumValue,
      } as DropdownOptions;
    });
  };

  const tableHelper = (whichData) => {
    // Events
    const events = whichData.map((event) => {
      // Sort insights here
      if (event.categories[0] === EventGroup.DISCOVER_INSIDER) {
        return (
          <SC.AquaContainer>
            <EventsTable
              name={calculateIcon(EventCategories.DEFAULT)}
              title={convertInsightToDescription(JSON.parse(event.title))}
              category={INSIDER_CATEGORY}
              date={event.found_at}
              hasMoreDetails={false}
              isInsights={true}
            />
          </SC.AquaContainer>
        );
      }

      return (
        <EventsTable
          name={calculateIcon(event.categories[0])}
          title={event.title}
          category={event.categories[0]}
          date={event.found_at}
          url={event.url}
          eventId={event.id}
          description={event.additional_data.article_sentence}
        />
      );
    });

    // Return generic no results message
    if (!events.length) {
      return (
        <NoResultsWrapper>
          <StyledText>No events found</StyledText>
        </NoResultsWrapper>
      );
    }

    // Sort insights and events by date
    const sortedArray = events.sort(
      (elementA, elementB) =>
        (DateTime.now()
          .diff(
            DateTime.fromISO(
              elementA.props?.date
                ? elementA.props?.date
                : elementA.props?.children?.props?.date,
            ),
            'months',
          )
          .toObject().months as number) -
        (DateTime.now()
          .diff(
            DateTime.fromISO(
              elementB.props?.date
                ? elementB.props?.date
                : elementB.props?.children?.props?.date,
            ),
            'months',
          )
          .toObject().months as number),
    );

    // Get headers for each year
    return sortedArray.map((element, index, array) => {
      // Map through to get the Headers for yearly groupings
      if (
        index === 0 ||
        DateTime.fromISO(
          array[index - 1].props?.date
            ? array[index - 1].props?.date
            : array[index - 1].props?.children?.props?.date,
        ).toFormat('yyyy') !==
          DateTime.fromISO(
            array[index].props?.date
              ? array[index].props?.date
              : array[index].props?.children?.props?.date,
          ).toFormat('yyyy')
      ) {
        return (
          <SC.YearHeader
            key={`${
              element.props?.title
                ? element.props?.title
                : element.props?.children?.props?.title
            }-${index}`}
          >
            <TC.StyledH1>
              {DateTime.fromISO(
                array[index].props?.date
                  ? array[index].props?.date
                  : array[index].props?.children?.props?.date,
              ).toFormat('yyyy')}
            </TC.StyledH1>

            {element}
          </SC.YearHeader>
        );
      }

      return element;
    });
  };

  return (
    <TC.InnerPageContainer>
      <TC.PageBodyContainer>
        <TC.PageHeader>
          <TC.StyledH1>
            <TC.StyledLink
              onClick={() => history.push(RouteName.DASHBOARD)}
              style={{
                color: themeContext.colors.general.grey,
                fontWeight: 100,
              }}
            >
              Discover /{' '}
            </TC.StyledLink>
            <TC.StyledLink
              onClick={() => history.push(RouteName.COMPANIES)}
              style={{
                color: themeContext.colors.general.grey,
                fontWeight: 100,
              }}
            >
              Enrich /{' '}
            </TC.StyledLink>
            <b style={{ color: themeContext.colors.general.purple }}>
              {companyData?.companyName}
            </b>
          </TC.StyledH1>

          <SC.IconWrapper>
            <FontAwesomeIcon
              icon={faUserPlus}
              size={TWO_TIMES}
              color={
                companyData?.recruitmentScore &&
                companyData?.recruitmentScore > 40
                  ? themeContext.colors.general.green
                  : themeContext.colors.general.grey
              }
            />
            <FontAwesomeIcon
              icon={faNewspaper}
              size={TWO_TIMES}
              color={
                companyData?.eventsScore && companyData?.eventsScore > 40
                  ? themeContext.colors.general.green
                  : themeContext.colors.general.grey
              }
            />
            <FontAwesomeIcon
              icon={faLaptopMobile}
              size={TWO_TIMES}
              color={
                companyData?.technologyScore &&
                companyData?.technologyScore > 40
                  ? themeContext.colors.general.green
                  : themeContext.colors.general.grey
              }
            />
            <FontAwesomeIcon
              icon={faSterlingSign}
              size={TWO_TIMES}
              color={
                companyData?.financeScore && companyData?.financeScore > 40
                  ? themeContext.colors.general.green
                  : themeContext.colors.general.grey
              }
            />
            <FontAwesomeIcon
              icon={faBrowser}
              size={TWO_TIMES}
              color={
                companyData?.websiteScore && companyData?.websiteScore > 40
                  ? themeContext.colors.general.green
                  : themeContext.colors.general.grey
              }
            />
            <FontAwesomeIcon
              icon={faRadar}
              size={TWO_TIMES}
              color={
                companyData?.insightsScore && companyData?.insightsScore > 40
                  ? themeContext.colors.general.green
                  : themeContext.colors.general.grey
              }
            />
            <FontAwesomeIcon
              icon={faCircleCheck}
              size={TWO_TIMES}
              color={
                companyData?.overallScore && companyData?.overallScore > 40
                  ? themeContext.colors.general.green
                  : themeContext.colors.general.grey
              }
            />
          </SC.IconWrapper>
        </TC.PageHeader>

        <SC.ActivitySelectorHeader>
          <StyledH1 noMargin={true}>Company Activity</StyledH1>
          <SC.StyledDropdown
            placeholder="Filter events by category"
            clearable
            name="activityType"
            search
            selectOnBlur={false}
            selection
            value={filterValue || ''}
            onChange={(e, { value }) => {
              setFilterValue(value);
            }}
            options={calculateDropdownOptionsFromEnums(EventGroup)}
          />
        </SC.ActivitySelectorHeader>

        <SC.ContentContainer>{tableHelper(eventData)}</SC.ContentContainer>
      </TC.PageBodyContainer>
      <SC.CompanyInformationWrapper>
        <SC.CompanyInformationHeader>
          {isLogoDefined && (
            <SC.CompanyInformationLogo logo={companyData?.companyLogo} />
          )}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <p
              style={{
                color: themeContext.colors.general.green,
                fontSize: '28px',
                margin: 0,
              }}
            >
              <b>{companyData?.companyName}</b>
            </p>
            <p style={{ margin: `0 0 ${themeContext.margin.small}` }}>
              {companyData?.location}
            </p>
            <TC.StyledLink
              href={
                !companyData?.domain?.includes(HTTPS)
                  ? `${HTTPS}${companyData?.domain}`
                  : companyData?.domain
              }
              target={'_blank'}
              style={{ color: themeContext.colors.general.grey }}
            >
              {companyData?.domain}
            </TC.StyledLink>
          </div>
          <p style={{ margin: 0 }}>{companyData?.industries?.split(',')[0]}</p>
          <p
            style={{
              textAlign: 'center',
              marginBottom: themeContext.margin.standard,
            }}
          >
            {companyData?.companyDescription}
          </p>
        </SC.CompanyInformationHeader>
        <SC.CompanyInformationDetailWrapper>
          {companyInformation.map((company, i) => {
            return (
              <CompanyInformationDetail
                key={`${company.information}-${i}`}
                information={company.information}
                text={company.text}
                logoName={company.iconName}
              />
            );
          })}
        </SC.CompanyInformationDetailWrapper>
      </SC.CompanyInformationWrapper>
    </TC.InnerPageContainer>
  );
};

export default CompanyPage;
