import { FC, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import EnrichLeadsCard from '../../components/EnrichLeads/EnrichLeadsCard';
import PageFooter from '../../components/PageFooter/PageFooter';
import PersonalisedCopyCard from '../../components/PersonalisedCopy/PersonalisedCopyCard';
import ScoreLeadsCard from '../../components/ScoreLeads/ScoreLeadsCard';
import * as TC from '../../main/theme';
import * as SC from './styled';

const DashboardPage: FC = () => {
  const themeContext = useContext(ThemeContext);

  return (
    <TC.InnerPageContainer>
      <TC.PageBodyContainer>
        <TC.PageHeader>
          <TC.StyledH1>
            <b style={{ color: themeContext.colors.general.black }}>Discover</b>
          </TC.StyledH1>

          <img
            src={
              'https://interlink-discover-assets.s3.eu-west-2.amazonaws.com/interlink-logo.png'
            }
            alt={'Interlink Discover'}
          />
        </TC.PageHeader>

        <SC.ContentWrapper>
          <PersonalisedCopyCard />

          <SC.CardWrapper>
            <ScoreLeadsCard />
            <EnrichLeadsCard />
          </SC.CardWrapper>
        </SC.ContentWrapper>

        <TC.PageFooterContainer>
          <PageFooter />
        </TC.PageFooterContainer>
      </TC.PageBodyContainer>
    </TC.InnerPageContainer>
  );
};

export default DashboardPage;
