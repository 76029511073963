import {
  faRadar,
  faUpRightFromSquare,
  IconDefinition,
} from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { startCase, truncate } from 'lodash';
import { DateTime } from 'luxon';
import { FC, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { DARK_THEME } from '../../consts/projectConsts';
import * as TC from '../../main/theme';
import { getLocalTheme } from '../../utils/dark-theme/getLocalTheme';
import * as SC from './styled';

interface EventsTableProps {
  category: string;
  title: string;
  description?: string;
  name: IconDefinition;
  handleSosSelection?: (data) => void;
  url?: string;
  date?: string;
  hasMoreDetails?: boolean;
  isInsights?: boolean;
  isDashboardPage?: boolean;
  eventId?: string;
  selectedEventId?: string;
}

const TITLE_TRUNCATE_CHAR_LIMIT = 80;
const DESC_TRUNCATE_CHAR_LIMIT = 200;
const EXTRA_LARGE = 'xl';
const TWO_EXTRA_LARGE = '2xl';

const SHORTHAND_DATE_FORMAT = 'LLL dd';

const EventsTable: FC<EventsTableProps> = ({
  category,
  title,
  url,
  name,
  date,
  description,
  handleSosSelection,
  hasMoreDetails = true,
  isInsights = false,
  isDashboardPage = false,
  eventId,
  selectedEventId,
}) => {
  const themeContext = useContext(ThemeContext);
  const localTheme = getLocalTheme();

  return (
    <SC.Container isInsights={isInsights}>
      <SC.InnerContainer>
        <SC.IconContainer>
          {isInsights ? (
            <FontAwesomeIcon
              icon={faRadar}
              size={TWO_EXTRA_LARGE}
              color={themeContext.colors.general.purple}
            />
          ) : (
            <FontAwesomeIcon
              icon={name}
              size={TWO_EXTRA_LARGE}
              color={themeContext.colors.general.purple}
            />
          )}
        </SC.IconContainer>
        {date && isInsights ? (
          <SC.IndividualContainer>
            <p
              style={{
                marginBottom: 0,
                color: themeContext.colors.general.grey,
              }}
            >
              {startCase(category)}
            </p>
          </SC.IndividualContainer>
        ) : (
          date && (
            <SC.IndividualContainer>
              <p style={{ margin: 0 }}>
                <b>
                  {date
                    ? DateTime.fromISO(date).toFormat(SHORTHAND_DATE_FORMAT)
                    : ''}
                </b>
              </p>
              <p
                style={{
                  marginBottom: 0,
                  color: themeContext.colors.general.grey,
                }}
              >
                {startCase(category)}
              </p>
            </SC.IndividualContainer>
          )
        )}
        {isInsights ? (
          <SC.ColumnFlexContainer>
            <p
              style={{
                color: `${themeContext.colors.general.purple}`,
                marginBottom: 0,
              }}
            >
              <b>Discover Insider</b>
            </p>
            <p
              style={{
                color:
                  localTheme === DARK_THEME
                    ? themeContext.colors.general.white
                    : themeContext.colors.general.black,
              }}
            >
              {truncate(title, { length: DESC_TRUNCATE_CHAR_LIMIT })}
            </p>
          </SC.ColumnFlexContainer>
        ) : (
          <SC.ColumnFlexContainer>
            <p
              style={{
                color: `${
                  localTheme === DARK_THEME
                    ? TC.darkTheme.text
                    : TC.lightTheme.text
                }`,
                marginBottom: 0,
              }}
            >
              <b>{truncate(title, { length: TITLE_TRUNCATE_CHAR_LIMIT })}</b>
            </p>
            <p
              style={{
                color: `${
                  localTheme === DARK_THEME
                    ? TC.darkTheme.text
                    : TC.lightTheme.text
                }`,
              }}
            >
              {truncate(description, { length: DESC_TRUNCATE_CHAR_LIMIT })}
            </p>
          </SC.ColumnFlexContainer>
        )}
      </SC.InnerContainer>

      {hasMoreDetails && (
        <SC.ButtonContainer>
          <TC.StyledLink
            target={'_blank'}
            href={url}
            style={{
              color: themeContext.colors.general.purple,
            }}
          >
            <FontAwesomeIcon
              icon={faUpRightFromSquare}
              size={EXTRA_LARGE}
              color={themeContext.colors.general.purple}
              style={{ cursor: 'pointer' }}
            />
            Read more
          </TC.StyledLink>
        </SC.ButtonContainer>
      )}
    </SC.Container>
  );
};

export default EventsTable;
