import { useAuth0 } from '@auth0/auth0-react';

import { Interfaces } from '@configur-tech/discover-core-types';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CompanyService from '../../services/configur/CompanyService';
import {
  createAndScoreCompany,
  fetchCompaniesWithScore,
} from '../../store/companies';
import { RootState } from '../../store/rootReducer';

const CONVERT_ROW_ID_REQUEST_PAGE_LIMIT = 50;

interface CompanyAPIResponse {
  entries: Interfaces.Company[];
  next: {
    hasMoreEntries: boolean;
    totalCount: number;
  };
}

const useCompanies = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const [companies, setCompanies] = useState<CompanyAPIResponse>();
  const [createdResponses, setCreatedResponses] =
    useState<Interfaces.CompanyScoring>();

  const scoredCompaniesState = useSelector(
    (state: RootState) => state.scoredCompanies,
  );
  const createdScoredCompaniesState = useSelector(
    (state: RootState) => state.createdScoredCompanies,
  );

  const scoredCompanies: CompanyAPIResponse =
    scoredCompaniesState.data as CompanyAPIResponse;

  const createdScoredCompanies: Interfaces.CompanyScoring =
    createdScoredCompaniesState.data as Interfaces.CompanyScoring;

  const getCompaniesWithScore = useCallback(
    async (queryRequest?, encoded?, limit?, pageNum?) => {
      const token = await getAccessTokenSilently();

      if (token) {
        await dispatch(
          fetchCompaniesWithScore(token, queryRequest, encoded, limit, pageNum),
        );
      }
    },
    [dispatch, getAccessTokenSilently],
  );

  const scoreCompaniesDirectly = async (
    companyData,
    userInfo,
    boosters,
    searchId,
  ) => {
    try {
      const token = await getAccessTokenSilently();

      const response = await CompanyService.scoreCompanies(token, {
        body: companyData,
        user: userInfo,
        boosters,
        searchId,
      });

      return response.data;
    } catch (error) {
      return {
        failedDomains: companyData.length,
        totalDomains: companyData.length,
      };
    }
  };

  const getCompanyDirectly = useCallback(
    async (query, encoded, multiple?: boolean) => {
      try {
        const token = await getAccessTokenSilently();

        const response = await CompanyService.getCompanies(
          token,
          query,
          encoded,
        );

        return multiple ? response.data.entries : response.data.entries[0];
      } catch (error) {
        const err = error as string;
        throw new Error(err);
      }
    },
    [getAccessTokenSilently],
  );

  const sendCreateAndScoreCompany = useCallback(
    async (createData) => {
      const token = await getAccessTokenSilently();

      if (token) {
        await dispatch(createAndScoreCompany(token, createData));
      }
    },
    [dispatch, getAccessTokenSilently],
  );

  const convertRowIdsToDomains = useCallback(
    async (rowIds: string[]) => {
      const domainUrls: string[] = [];

      // Create paged arrays of rowIds
      const numRowPages = Math.ceil(
        rowIds.length / CONVERT_ROW_ID_REQUEST_PAGE_LIMIT,
      );
      const pagedRowIds = Array.from(Array(numRowPages)).reduce(
        (acc: string[], rowId, index) => {
          const pageRows = rowIds.slice(
            CONVERT_ROW_ID_REQUEST_PAGE_LIMIT * index,
            (index + 1) * CONVERT_ROW_ID_REQUEST_PAGE_LIMIT,
          );

          return [...acc, pageRows];
        },
        [],
      );

      // Loop through pages and get all existing rowIds converted to domains
      for (const page of pagedRowIds) {
        // Get the domains by filtering by row_id
        const query = [
          {
            operator: 'IN',
            field: 'row_id',
            alias: 'row_id',
            datasetMetaId: process.env.REACT_APP_COMPANIES_DATASET_META,
            value: {
              type: 'CONSTANT',
              value: page,
            },
          },
        ];

        const entries = await getCompanyDirectly(
          JSON.stringify(query),
          true,
          true,
        );

        (entries as Interfaces.Company[]).map((e) => {
          const cleanDomain = e.domain && e.domain.replace('www.', '');

          if (cleanDomain && !domainUrls.includes(cleanDomain)) {
            domainUrls.push(cleanDomain);
          }
        });
      }

      return domainUrls;
    },
    [getCompanyDirectly],
  );

  useEffect(() => {
    setCompanies(scoredCompanies);
    setCreatedResponses(createdScoredCompanies);
  }, [scoredCompanies, createdScoredCompanies]);

  return {
    companies,
    createdResponses,
    scoreCompaniesDirectly,
    getCompanyDirectly,
    getCompaniesWithScore,
    sendCreateAndScoreCompany,
    convertRowIdsToDomains,
  };
};

export default useCompanies;
