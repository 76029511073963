import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { StyledText } from '../../main/theme';
import * as SC from './styled';

const EXTRA_LARGE = 'xl';

interface CompanyInformationDetailProps {
  logoName: IconDefinition;
  text: string;
  information: string | number | React.ReactElement;
}

const CompanyInformationDetail: FC<CompanyInformationDetailProps> = ({
  logoName,
  text,
  information,
}) => {
  const themeContext = useContext(ThemeContext);

  return (
    <SC.CompanyInformationDetailContainer>
      <FontAwesomeIcon
        icon={logoName}
        size={EXTRA_LARGE}
        color={themeContext.colors.general.green}
      />
      <p style={{ margin: 0 }}>
        <b>{information}</b>
      </p>
      <p style={{ margin: 0 }}>{text}</p>
    </SC.CompanyInformationDetailContainer>
  );
};

export default CompanyInformationDetail;
