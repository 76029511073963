import { Interfaces } from '@configur-tech/discover-core-types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import CompanyService from '../../../services/configur/CompanyService';
import { AppThunk } from '../../store';

type CompanyData = Interfaces.Company[];

export interface SubscribedCompaniesState {
  data: CompanyData;
  loading: boolean;
  error: string | null;
}

const initialState: SubscribedCompaniesState = {
  data: [],
  loading: false,
  error: null,
};

const CompaniesSlice = createSlice({
  name: 'subscribedCompanies',
  initialState,
  reducers: {
    // Fetch subscribed
    fetchSubscribedCompaniesStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchSubscribedCompaniesSuccess(
      state,
      action: PayloadAction<Interfaces.Company[]>,
    ) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchSubscribedCompaniesFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchSubscribedCompaniesStart,
  fetchSubscribedCompaniesSuccess,
  fetchSubscribedCompaniesFailure,
} = CompaniesSlice.actions;

export default CompaniesSlice.reducer;

export const fetchSubscribedCompanies =
  (token: string, companies: string[]): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchSubscribedCompaniesStart());
      const fetched = await CompanyService.getSubscribedCompanies(
        token,
        companies,
      );
      dispatch(
        fetchSubscribedCompaniesSuccess(
          fetched as unknown as Interfaces.Company[],
        ),
      );
    } catch (error) {
      const err = error as string;
      dispatch(fetchSubscribedCompaniesFailure(err.toString()));
    }
  };
