const WILL_PREFIX = 'will ';
const SOLD_PREFIX = 'sold';

const ED_SUFFIX = 'ed';
const ING_SUFFIX = 'ing';
const ES_SUFFIX = 'es';
const S_SUFFIX = 's';
const RE_SUFFIX = 're';
const CH_SUFFIX = 'ch';

const SELLING = 'selling';

const END_OF_STRING_REGEX = /.$/;

const pastTenseToPresentTense = (x: string) => {
  if (x.startsWith(WILL_PREFIX)) {
    // EG - will hire -> hiring
    if (x.endsWith(RE_SUFFIX)) {
      return x
        .replace(WILL_PREFIX, '')
        .replace(END_OF_STRING_REGEX, '')
        .concat(ING_SUFFIX);
    }

    // EG - will launch -> launching
    if (x.endsWith(CH_SUFFIX)) {
      return x.replace(WILL_PREFIX, '').concat(ING_SUFFIX);
    }
  }

  // EG - sold assets to -> selling assets to
  if (x.startsWith(SOLD_PREFIX)) {
    return x.replace(SOLD_PREFIX, SELLING);
  }

  // EG hired -> hiring
  if (x.endsWith(ED_SUFFIX)) {
    return x.replace(ED_SUFFIX, '').concat(ING_SUFFIX);
  }

  // EG hires -> hiring
  if (x.endsWith(ES_SUFFIX)) {
    return x.replace(ES_SUFFIX, '').concat(ING_SUFFIX);
  }

  // Replace last character here EG signs -> signing
  if (x.endsWith(S_SUFFIX)) {
    return x.replace(END_OF_STRING_REGEX, '').concat(ING_SUFFIX);
  }

  return x;
};

export { pastTenseToPresentTense };
