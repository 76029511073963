import { Interfaces } from '@configur-tech/discover-core-types';
import HttpHelper from '../../utils/http-helper/HttpHelper';

const endpoint = process.env['REACT_APP_USER_API'] || '';

export interface EntriesResponse {
  data: {
    entries: Interfaces.User[];
  };
}

// Will change when Configur User CRUD updated
export interface EntryResponse {
  data: { entry: Interfaces.User };
}

export default class UserService {
  public static async getUser(
    token: string,
    filterQuery,
  ): Promise<EntriesResponse> {
    const ah = new HttpHelper(token);

    const encodedQuery = encodeURIComponent(filterQuery);
    const url = `${endpoint}?limit=50&pageNum=1&conditions=${encodedQuery}`;

    return await ah.get<EntriesResponse>(url, undefined, {
      'X-API-Key': process.env['REACT_APP_USER_X_API_KEY'] || '',
    });
  }

  public static async putUser(token: string, userData): Promise<EntryResponse> {
    const ah = new HttpHelper(token);

    return await ah.put(
      `${endpoint}/${userData.row_id}`,
      { updateData: userData },
      {
        'X-API-Key': process.env['REACT_APP_USER_X_API_KEY'] || '',
      },
    );
  }
}
