import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import CompanyService from '../../../services/configur/CompanyService';
import { AppThunk } from '../../store';
import { ConfigurReadCompaniesData } from '../../../services/configur/CompanyService';

export interface RecentCompaniesState {
  data: ConfigurReadCompaniesData;
  loading: boolean;
  error: string | null;
}

const initialState: RecentCompaniesState = {
  data: { entries: [], next: { hasMoreEntries: false, totalCount: 0 } },
  loading: false,
  error: null,
};

const CompaniesSlice = createSlice({
  name: 'recentCompanies',
  initialState,
  reducers: {
    // Fetch recent
    fetchRecentCompaniesStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchRecentCompaniesSuccess(
      state,
      action: PayloadAction<ConfigurReadCompaniesData>,
    ) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchRecentCompaniesFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchRecentCompaniesStart,
  fetchRecentCompaniesSuccess,
  fetchRecentCompaniesFailure,
} = CompaniesSlice.actions;

export default CompaniesSlice.reducer;

export const fetchRecentCompanies =
  (token: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchRecentCompaniesStart());
      const fetched = await CompanyService.getRecentCompanies(token);
      dispatch(fetchRecentCompaniesSuccess(fetched));
    } catch (error) {
      const err = error as string;
      dispatch(fetchRecentCompaniesFailure(err.toString()));
    }
  };
