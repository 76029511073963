import styled from 'styled-components';
import { StyledH2, StyledH3 } from '../../../main/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: initial;
  gap: 10px;

  width: 1050px;
  max-width: 100%;
`;

export const Header = styled(StyledH2)`
  color: ${({ theme }) => theme.colors.system.offBlack};
  margin-bottom: ${({ theme }) => theme.margin.xlarge};
`;

export const SubHeader = styled(StyledH3)`
  margin-bottom: 0;
`;

export const ActionButtonWrapper = styled.div`
  display: flex;

  gap: ${({ theme }) => theme.margin.standard};

  margin-top: ${({ theme }) => theme.margin.xxxlarge};

  > div:last-child {
    margin-left: ${({ theme }) => theme.margin.large};
  }
`;

export const ColumnWrapper = styled.div`
  margin-top: ${({ theme }) => theme.margin.large};
`;

export const CsvColumnSelectorContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.margin.standard};
`;

export const ColumnHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.margin.small};
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StretchedContainer = styled.div`
  width: 500px;
`;

export const FontSizeParagraph = styled.p`
  font-size: 20px;
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${({ theme }) => theme.margin.xlarge};
  gap: ${({ theme }) => theme.margin.standard};
`;
