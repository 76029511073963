export enum RouteName {
  COMPANY = '/company',
  COMPANIES = '/companies',
  DASHBOARD = '/',
  SEARCH = '/search',
  SEARCH_COMPANY = '/search-company',
  SEARCH_RESULTS = '/search-results',
  SAVED_SEARCHES = '/searches',
  SIGNUP = '/signup',
  TRACKED = '/tracked',
  TRACKED_COMPANY = '/tracked-company',
  NOT_FOUND = '/not-found',
}
