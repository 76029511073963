import styled from 'styled-components';

export const CompanyInformationDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: ${({ theme }) => theme.margin.standard};

  > svg {
    margin-bottom: ${({ theme }) => theme.margin.large};
  }
`;
