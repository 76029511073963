export enum EventCategories {
  DEFAULT = 'default',
  HIRES = 'hires',
  PROMOTES = 'promotes',
  LEAVES = 'leaves',
  RETIRES = 'retires',

  ACQUIRES = 'acquires',
  MERGES_WITH = 'merges_with',
  SELLS_ASSETS_TO = 'sells_assets_to',

  EXPANDS_OFFICES_TO = 'expands_offices_to',
  EXPANDS_OFFICES_IN = 'expands_offices_in',
  EXPANDS_FACILITIES = 'expands_facilities',
  OPENS_NEW_LOCATION = 'opens_new_location',
  INCREASES_HEADCOUNT_BY = 'increases_headcount_by',

  LAUNCHES = 'launches',
  INTEGRATES_WITH = 'integrates_with',
  IS_DEVELOPING = 'is_developing',

  RECEIVES_FINANCING = 'receives_financing',
  INVESTS_INTO = 'invests_into',
  INVESTS_INTO_ASSETS = 'invests_into_assets',
  GOES_PUBLIC = 'goes_public',

  CLOSES_OFFICES = 'closes_offices',
  DECREASES_HEADCOUNT_BY = 'decreases_headcount_by',

  PARTNERS_WITH = 'partners_with',

  RECEIVES_AWARD = 'receives_award',
  RECOGNIZED_AS = 'recognized_as',

  SIGNS_NEW_CLIENT = 'signs_new_client',

  FILES_SUIT_AGAINST = 'files_suit_against',
  HAS_ISSUES_WITH = 'has_issues_with',

  IDENTIFIED_AS_COMPETITOR_OF = 'identified_as_competitor_of',
}

export enum LeadershipEvents {
  HIRES = 'hires',
  PROMOTES = 'promotes',
  LEAVES = 'leaves',
  RETIRES = 'retires',
}

export enum AcquisitionEvents {
  ACQUIRES = 'acquires',
  MERGES_WITH = 'merges_with',
  SELLS_ASSETS_TO = 'sells_assets_to',
}

export enum ExpansionEvents {
  EXPANDS_OFFICES_TO = 'expands_offices_to',
  EXPANDS_OFFICES_IN = 'expands_offices_in',
  EXPANDS_FACILITIES = 'expands_facilities',
  OPENS_NEW_LOCATION = 'opens_new_location',
  INCREASES_HEADCOUNT_BY = 'increases_headcount_by',
}

export enum NewOfferingEvents {
  LAUNCHES = 'launches',
  INTEGRATES_WITH = 'integrates_with',
  IS_DEVELOPING = 'is_developing',
}

export enum InvestmentEvents {
  RECEIVES_FINANCING = 'receives_financing',
  INVESTS_INTO = 'invests_into',
  INVESTS_INTO_ASSETS = 'invests_into_assets',
  GOES_PUBLIC = 'goes_public',
}

export enum CostCuttingEvents {
  CLOSES_OFFICES = 'closes_offices',
  DECREASES_HEADCOUNT_BY = 'decreases_headcount_by',
}

export enum PartnershipEvents {
  PARTNERS_WITH = 'partners_with',
}

export enum RecognitionEvents {
  RECEIVES_AWARD = 'receives_award',
  RECOGNIZED_AS = 'recognized_as',
}

export enum ContractEvents {
  SIGNS_NEW_CLIENT = 'signs_new_client',
}

export enum CorporateChallengesEvents {
  FILES_SUIT_AGAINST = 'files_suit_against',
  HAS_ISSUES_WITH = 'has_issues_with',
}

export enum RelationalEvents {
  IDENTIFIED_AS_COMPETITOR_OF = 'identified_as_competitor_of',
}

export enum InsightEvents {
  DISCOVER_INSIDER = 'discover_insider',
}
