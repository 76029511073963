import { Interfaces } from '@configur-tech/discover-core-types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import CompanyService, {
  EntriesResponse,
} from '../../../services/configur/CompanyService';
import { AppThunk } from '../../store';

type CompanyData = EntriesResponse[] | Interfaces.CompanyScoring;

export interface FilteredCompaniesState {
  data: CompanyData;
  loading: boolean;
  error: string | null;
}

const initialState: FilteredCompaniesState = {
  data: [],
  loading: false,
  error: null,
};

const CompaniesSlice = createSlice({
  name: 'filteredCompanies',
  initialState,
  reducers: {
    // Fetch filtered
    fetchFilteredCompaniesStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchFilteredCompaniesSuccess(
      state,
      action: PayloadAction<EntriesResponse[]>,
    ) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchFilteredCompaniesFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchFilteredCompaniesStart,
  fetchFilteredCompaniesSuccess,
  fetchFilteredCompaniesFailure,
} = CompaniesSlice.actions;

export default CompaniesSlice.reducer;

export const fetchFilteredCompaniesWithScore =
  (token: string, queryParams?): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchFilteredCompaniesStart());
      const fetched = await CompanyService.getCompanies(token, queryParams);
      dispatch(
        fetchFilteredCompaniesSuccess(
          fetched.data as unknown as EntriesResponse[],
        ),
      );
    } catch (error) {
      const err = error as string;
      dispatch(fetchFilteredCompaniesFailure(err.toString()));
    }
  };
