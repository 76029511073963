import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingProps } from '../../../components/Loading/BackgroundScoresLoading';
import InitialLoading from '../initial-state';

const BackgroundLoadingSlice = createSlice({
  name: 'backgroundLoading',
  initialState: InitialLoading,
  reducers: {
    showBackgroundLoading(state, action: PayloadAction<LoadingProps>) {
      state.loading = true;
      state.text = action?.payload.text;
    },
    hideBackgroundLoading(state) {
      state.loading = false;
      state.text = undefined;
    },
  },
});

export const { showBackgroundLoading, hideBackgroundLoading } =
  BackgroundLoadingSlice.actions;

export default BackgroundLoadingSlice.reducer;
