import { useAuth0 } from '@auth0/auth0-react';
import * as SC from './styled';

const UserProfile = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();

  if (isLoading || !isAuthenticated) {
    return <div>Loading ...</div>;
  }

  return isAuthenticated && <SC.UserAvatar avatar={user?.picture} />;
};

export default UserProfile;
