import { useAuth0 } from '@auth0/auth0-react';
import { Interfaces } from '@configur-tech/discover-core-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import { fetchUser } from '../../store/user';

interface useUserResult {
  loggedInUser?: Interfaces.User;

  getLoggedInUser: () => void;
}

const useLoggedInUser = (): useUserResult => {
  const dispatch = useDispatch();

  const { getAccessTokenSilently, user } = useAuth0();

  const [loggedInUser, setLoggedInUser] = useState<Interfaces.User>();
  const userState = useSelector((state: RootState) => state.user);

  const userObj: Interfaces.User = userState.data as Interfaces.User;

  const getLoggedInUser = async () => {
    const token = await getAccessTokenSilently();

    if (token && user) {
      await dispatch(fetchUser(token, user.sub as string));
    }
  };

  // Load user
  useEffect(() => {
    if (userObj) {
      setLoggedInUser(userObj);
    }
  }, [userObj]);

  return {
    loggedInUser,
    getLoggedInUser,
  };
};

export default useLoggedInUser;
