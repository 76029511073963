import { useState, useContext } from 'react';
import {
  useCSVReader,
  lightenDarkenColor,
  formatFileSize,
  usePapaParse,
} from 'react-papaparse';
import { useDispatch } from 'react-redux';

import { ThemeContext } from 'styled-components';
import { DARK_THEME } from '../../consts/projectConsts';
import useLoggedInUser from '../../hooks/user/useLoggedInUser';

interface CSVClickAndDragParams {
  searchId?: string;
}

interface CSVResults {
  data: any[][];
  errors: string[];
  meta: string[];
}

// Main
import theme, { ActionButton } from '../../main/theme';

// Store
import { showModal } from '../../store/modal';

// Utils
import { getLocalTheme } from '../../utils/dark-theme/getLocalTheme';

// Modal
import { ModalTypes } from '../Modal/Modal';

// Styles
import * as SC from './styled';

const DEFAULT_REMOVE_HOVER_COLOR = '#A01919';
const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(
  DEFAULT_REMOVE_HOVER_COLOR,
  40,
);
const USER_ID_COLUMN = 'userId';
const SEARCH_ID_COLUMN = 'searchId';

const CSVCLickAndDrag = ({ searchId }: CSVClickAndDragParams) => {
  const { loggedInUser } = useLoggedInUser();

  const themeContext = useContext(ThemeContext);
  const { jsonToCSV } = usePapaParse();

  const dispatch = useDispatch();
  const { CSVReader } = useCSVReader();
  const [zoneHover, setZoneHover] = useState(false);
  const [removeHoverColor, setRemoveHoverColor] = useState(
    DEFAULT_REMOVE_HOVER_COLOR,
  );
  const [appendedCsvFile, setAppendedCsvFile] = useState<string>();

  const blue = themeContext.colors.general.blue;
  const white = themeContext.colors.general.white;
  const darkGrey = themeContext.colors.general.darkGrey;

  const localTheme = getLocalTheme();

  return (
    <CSVReader
      onUploadAccepted={(results: CSVResults) => {
        setZoneHover(false);

        if (loggedInUser?.auth0Id) {
          const tempResults = results;

          // Add user id
          tempResults.data[0].push(USER_ID_COLUMN);
          tempResults.data[1].push(loggedInUser.auth0Id);

          // Add searchId
          tempResults.data[0].push(SEARCH_ID_COLUMN);
          tempResults.data[1].push(searchId);

          const csvResults = jsonToCSV(tempResults.data);

          setAppendedCsvFile(csvResults);
        }
      }}
      onDragOver={(event: DragEvent) => {
        event.preventDefault();
        setZoneHover(true);
      }}
      onDragLeave={(event: DragEvent) => {
        event.preventDefault();
        setZoneHover(false);
      }}
    >
      {({
        getRootProps,
        acceptedFile,
        ProgressBar,
        getRemoveFileProps,
        Remove,
      }: any) => (
        <SC.Wrapper>
          <div
            {...getRootProps()}
            style={Object.assign(
              {},
              SC.DragAndDropStyles.zone,
              zoneHover && SC.DragAndDropStyles.zoneHover,
              {
                backgroundColor:
                  localTheme === DARK_THEME
                    ? darkGrey
                    : themeContext.colors.system.grey,
              },
            )}
          >
            {acceptedFile ? (
              <>
                <div style={SC.DragAndDropStyles.file}>
                  <div style={SC.DragAndDropStyles.info}>
                    <span style={SC.DragAndDropStyles.size}>
                      {formatFileSize(acceptedFile.size)}
                    </span>
                    <span style={SC.DragAndDropStyles.name}>
                      {acceptedFile.name}
                    </span>
                  </div>
                  <div style={SC.DragAndDropStyles.progressBar}>
                    <ProgressBar />
                  </div>
                  <div
                    {...getRemoveFileProps()}
                    style={SC.DragAndDropStyles.remove}
                    onMouseOver={(event: Event) => {
                      event.preventDefault();
                      setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT);
                    }}
                    onMouseOut={(event: Event) => {
                      event.preventDefault();
                      setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR);
                    }}
                  >
                    <Remove color={removeHoverColor} />
                  </div>
                </div>
              </>
            ) : (
              <SC.DragAndDropContainer>
                Drag and drop your file here or tap the button below to select
                it
                <ActionButton
                  backgroundColor={
                    localTheme === DARK_THEME
                      ? themeContext.overlayComponents
                      : themeContext.colors.system.grey
                  }
                  textColor={blue}
                  border={true}
                >
                  Upload Leads
                </ActionButton>
              </SC.DragAndDropContainer>
            )}
          </div>
          <ActionButton
            style={{ alignSelf: 'flex-end' }}
            backgroundColor={blue}
            textColor={white}
            disabled={!acceptedFile}
            onClick={() =>
              dispatch(
                showModal({
                  forceOpen: true,
                  visible: true,
                  modal: ModalTypes.APPLY_BOOSTERS,
                  additionalProps: { file: acceptedFile, appendedCsvFile },
                }),
              )
            }
          >
            Next
          </ActionButton>
        </SC.Wrapper>
      )}
    </CSVReader>
  );
};

export default CSVCLickAndDrag;
