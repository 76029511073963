import { Interfaces } from '@configur-tech/discover-core-types';
import { ReactNode } from 'react';
import { EventActionPastTense } from '../../enums/EventActionPastTense';
import { pastTenseToPresentTense } from '../events/EventActionTenseMapper';
import { SosSentenceConstructor } from './SosSentenceConstructor';

const ON_SPLIT = ' on';

const pastTenseHelper = (title) => {
  // Check if the title has any key event in the past tense
  if (
    Object.values(EventActionPastTense).some((pastTenseWord) =>
      title.includes(pastTenseWord),
    )
  ) {
    // Grab the index and length of the word thats found then filter out ones which are not found
    const wordIndex = Object.values(EventActionPastTense)
      .map((pastTenseWord) => {
        return {
          index: title.search(pastTenseWord),
          length: pastTenseWord.length,
        };
      })
      .filter((obj) => obj.index !== -1);

    // Isolate the word
    const wordToFormat = title?.slice(
      wordIndex[0].index,
      wordIndex[0].index + wordIndex[0].length,
    );

    // Formatted title returned -> split if there is 'on Date' etc
    return title
      .replace(wordToFormat, pastTenseToPresentTense(wordToFormat))
      .split(ON_SPLIT)[0];
  }
};

const SosBuilder = (
  event?: Interfaces.Events,
  isExport = false,
  companyName?: string,
  isEmail = true,
): string | ReactNode => {
  if (!event) {
    return '';
  }

  const title = event?.eventName as string;
  const category = event?.eventCategory as EventActionPastTense;
  let formattedTitle = '';
  const pastTenseSentence = pastTenseHelper(title);

  if (!pastTenseSentence) {
    formattedTitle = '';
  }

  formattedTitle = pastTenseSentence;

  if (isEmail) {
    if (isExport) {
      return `Have seen ${
        formattedTitle?.length ? formattedTitle : title
      } - Looks like exciting things are bubbling!\n\nWe are currently working with [relevant client], ${SosSentenceConstructor(
        category,
      )}, helping them to overcome [insert challenge]\n\nWould you be open to learning more about how we helped [client x] overcome [insert challenge]?\n\nHappy to send through a 1-pager to start with - just let me know!`;
    }

    return (
      <div>
        Hello [lead name] - Have seen{' '}
        {formattedTitle && formattedTitle.length ? formattedTitle : title} -
        Looks like exciting things are bubbling!
        <br />
        <br />
        We are currently working with [relevant client],{' '}
        {SosSentenceConstructor(category)}, helping them to overcome [insert
        challenge]
        <br />
        <br />
        Would you be open to learning more about how we helped [client x]
        overcome [insert challenge]?
        <br />
        <br />
        Happy to send through a 1-pager to start with - just let me know!
      </div>
    );
  }

  return (
    <div>
      Hello [lead name], it's [caller name] calling from Veeam - did I catch you
      before your next meeting at all?
      <br />
      <br />
      [pause]
      <br />
      <br />
      Nice - I'll keep it quick!
      <br />
      <br />
      Well I noticed{' '}
      {formattedTitle && formattedTitle.length ? formattedTitle : title} - we
      work with [competitor company] {SosSentenceConstructor(category)}.
      <br />
      <br />
      We helped them to overcome [relevant challenge 1] and [relevant challenge
      2].
      <br />
      <br />I was calling to see if you would you like to learn more about the
      tactics we used to help them overcome their challenges?
    </div>
  );
};

export { SosBuilder };
