import { EventCategories } from '../../enums/EventCategories';
import {
  faUserPlus,
  IconDefinition,
  faEarthEurope,
  faUserMinus,
  faRocketLaunch,
  faHandshake,
  faMerge,
  faMoneyBill,
  faNewspaper,
  faUserPen,
  faCode,
  faSitemap,
  faUser,
} from '@fortawesome/pro-duotone-svg-icons';

const calculateIcon = (title: EventCategories): IconDefinition => {
  if (
    [EventCategories.HIRES, EventCategories.INCREASES_HEADCOUNT_BY].includes(
      title,
    )
  ) {
    return faUserPlus;
  }

  if ([EventCategories.GOES_PUBLIC].includes(title)) {
    return faEarthEurope;
  }

  if ([EventCategories.LEAVES, EventCategories.RETIRES].includes(title)) {
    return faUserMinus;
  }

  if ([EventCategories.LAUNCHES].includes(title)) {
    return faRocketLaunch;
  }

  if ([EventCategories.PARTNERS_WITH].includes(title)) {
    return faHandshake;
  }

  if ([EventCategories.INTEGRATES_WITH].includes(title)) {
    return faMerge;
  }

  if (
    [
      EventCategories.RECEIVES_FINANCING,
      EventCategories.INVESTS_INTO,
      EventCategories.INVESTS_INTO_ASSETS,
    ].includes(title)
  ) {
    return faMoneyBill;
  }

  if ([EventCategories.RECOGNIZED_AS].includes(title)) {
    return faNewspaper;
  }

  if ([EventCategories.SIGNS_NEW_CLIENT].includes(title)) {
    return faUserPen;
  }

  if ([EventCategories.IS_DEVELOPING].includes(title)) {
    return faCode;
  }

  if ([EventCategories.ACQUIRES].includes(title)) {
    return faSitemap;
  }

  return faUser;
};

export { calculateIcon };
