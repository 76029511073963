import { Interfaces } from '@configur-tech/discover-core-types';
import { EventCategories } from '../../enums/EventCategories';
import { convertInsightToDescription } from '../insights/convertInsightToDescription';
import { DateTime } from 'luxon';
import { startCase } from 'lodash';
import { SosBuilder } from '../sos/SosBuilder';
import { scoreToRankHelper } from './scoreToRankHelper';
import { DefaultExportResult } from '../../interfaces/DefaultExportResult';

// Consts
const DISCOVER_INSIDER_TITLE = '*** Discover Insider ***';
const MONTHS = 'months';

const EVENT_STRING_DATE_FORMAT = 'dd/MM/yyyy';
const DATE_CATEGORY = 'Date:';
const TYPE_CATEGORY = 'Type:';
const URL_CATEGORY = 'URL:';
const EVENT_CATEGORY = 'Event:';
const ARTICLE_SENTENCE = 'Article Sentence:';

/**
 * @description - Formats company and event data into a default format ready for export
 *
 * @param {Interfaces.Company} company - Company data
 * @param {Interfaces.Events[]} events - Events data
 * @param {Interfaces.Insights} [insight] - Insight for the company
 * @param {number} [totalEventLimit] - Limit of the total events returned
 * @param {string} [eventTypeLimit] - Limit of the types of event per company
 *
 * @returns {Promise<DefaultExportResult>} - Formatted data ready for export
 */
export const defaultFormatExportDataForCompany = async (
  company: Interfaces.Company,
  events: Interfaces.Events[],
  insight?: Interfaces.Insights,
  totalEventLimit?: number,
  eventTypeLimit?: string,
): Promise<DefaultExportResult> => {
  // Build event
  let eventString = '';
  let resultArray;
  let sosResult;

  if (totalEventLimit && !eventTypeLimit) {
    resultArray = events.splice(0, totalEventLimit);
  } else if (eventTypeLimit) {
    const checkTypeArray: EventCategories[] = [];

    const typedEvents = events.filter((event) => {
      checkTypeArray.push(event.eventCategory as EventCategories);

      return (
        checkTypeArray.filter((type) => type === event.eventCategory).length <=
        parseInt(eventTypeLimit)
      );
    });

    resultArray = typedEvents;

    if (totalEventLimit) {
      resultArray = typedEvents.splice(0, totalEventLimit);
    }
  } else {
    resultArray = events;
  }

  const zeroTo5Results = resultArray.length ? resultArray.length : 0;

  // If an insight exists
  if (insight) {
    // Format it to an event type format
    const formattedInsight: Interfaces.Events = {
      eventName: convertInsightToDescription(insight),
      eventCategory: DISCOVER_INSIDER_TITLE,
    };

    // Push insight to start of resultArray
    resultArray.unshift(formattedInsight);
  }

  resultArray.map((event, i, originalArray) => {
    eventString += `${
      event.eventDate
        ? `${DATE_CATEGORY} ${DateTime.fromISO(event.eventDate).toFormat(
            EVENT_STRING_DATE_FORMAT,
          )}\n${TYPE_CATEGORY} ${
            i === 0 ? event.eventCategory : startCase(event.eventCategory)
          }\n${EVENT_CATEGORY} ${event.eventName}${
            event.eventArticleSentence
              ? `\n${ARTICLE_SENTENCE} ${event.eventArticleSentence}`
              : ''
          }\n${event.eventUrl ? `${URL_CATEGORY} ${event.eventUrl}` : ''}${
            i === originalArray.length - 1 ? '' : `\n\n`
          }`
        : `${TYPE_CATEGORY} ${
            i === 0 ? event.eventCategory : startCase(event.eventCategory)
          }\n${EVENT_CATEGORY} ${event.eventName}\n${
            event.eventUrl ? `${URL_CATEGORY} ${event.eventUrl}` : ''
          }${i === originalArray.length - 1 ? '' : `\n\n`}`
    }`;
  });

  if (resultArray.length) {
    // Sort the events by most recent first
    const sortedEvents = resultArray.sort(
      (eventA, eventB) =>
        (DateTime.now()
          .diff(DateTime.fromISO(eventA.eventDate), MONTHS)
          .toObject().months as number) -
        (DateTime.now()
          .diff(DateTime.fromISO(eventB.eventDate), MONTHS)
          .toObject().months as number),
    );

    // Grab the most recent one
    const mostRecentEvent = sortedEvents[0];

    sosResult = SosBuilder(mostRecentEvent, true);
  }

  return {
    domain: company.domain || '',
    companyDescription: company.companyDescription || '',
    industry: company.industries || '',
    revenue: company.revenue?.toString() || '',
    employeeCount: company.employeeCount?.toString() || '',
    events: eventString,
    hiringScore: scoreToRankHelper(company.recruitmentScore),
    technologyScore: scoreToRankHelper(company.technologyScore, true),
    eventsScore: scoreToRankHelper(company.eventsScore),
    websiteScore: scoreToRankHelper(company.websiteScore),
    financeScore: scoreToRankHelper(company.financeScore, true),
    insightsScore: scoreToRankHelper(company.insightsScore, true),
    overallScore: scoreToRankHelper(company.overallScore),
    discoverSos: sosResult ? sosResult : '',
    eventCount: zeroTo5Results,
    itBudget: company.itBudget || 0,
    itEmployeeCount: company.itEmployeeCount || 0,
    salesEmployeeCount: company.salesEmployeeCount || 0,
    engAndTechEmployeeCount: company.engAndTechEmployeeCount || 0,
  };
};
