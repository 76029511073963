import { Interfaces } from '@configur-tech/discover-core-types';
import { useContext, useEffect, useState } from 'react';

import { useCSVReader, usePapaParse } from 'react-papaparse';
import { ThemeContext } from 'styled-components';
import { ActionButton } from '../../main/theme';
import * as SC from './styled';

const USER_ID_COLUMN = 'userId';
const SEARCH_ID_COLUMN = 'searchId';

interface CSVReaderParams {
  isSearchResults?: boolean;
  isPabmUpload?: boolean;
  setHeadersData?: (data) => void;
  setHeadersArray?: (data) => void;
  setDataToExport?: (data) => void;
  setDomainTableData?: (data) => void;
  setAcceptedCsvFile?: (data) => void;
  setAppendedCsvFile?: (data) => void;
  setRawResults?: (data) => void;

  userId?: string;
  boosterData?: Interfaces.Boosters[];
  boostersComplete?: boolean;
  searchId?: string;
}

interface CSVResults {
  data: any[][];
  errors: string[];
  meta: string[];
}

const CSVReader = ({
  isSearchResults,
  isPabmUpload,
  setHeadersData,
  setHeadersArray,
  setDataToExport,
  setDomainTableData,
  setAcceptedCsvFile,
  setAppendedCsvFile,
  setRawResults,
  userId,
  boosterData,
  boostersComplete,
  searchId,
}: CSVReaderParams) => {
  const themeContext = useContext(ThemeContext);
  const [boosters, setBoosters] = useState<Interfaces.Boosters[] | undefined>(
    [],
  );
  const [uploadResults, setUploadResults] = useState<CSVResults>();
  const { CSVReader } = useCSVReader();
  const { jsonToCSV } = usePapaParse();

  useEffect(() => {
    // Initial booster addition
    if (uploadResults && setAppendedCsvFile && boostersComplete) {
      const tempResults = uploadResults;

      // Wait for boosters to complete
      setBoosters(boosterData);

      // Avoids the same booster being appended if there are inner fields being added
      if (boosterData?.length !== boosters?.length) {
        tempResults.data[0].push('boosters');
        tempResults.data[1].push(
          encodeURI(JSON.stringify(boosterData) as string),
        );

        const csvResultWithBoosters = jsonToCSV(tempResults.data);

        setAppendedCsvFile(csvResultWithBoosters as string);
      }
    }
  }, [
    boosterData,
    boosters?.length,
    boostersComplete,
    jsonToCSV,
    setAppendedCsvFile,
    uploadResults,
  ]);

  return (
    <CSVReader
      onUploadAccepted={(results: CSVResults) => {
        if (
          isSearchResults &&
          setHeadersArray &&
          setHeadersData &&
          setDataToExport
        ) {
          const headers = results.data[0];

          setHeadersArray(headers);
          setHeadersData({
            domains: headers[0],
            description: headers[1],
            revenue: headers[2],
            employeeCount: headers[3],
            events: headers[4],
          });
          // set the data but not the column headers
          setDataToExport(results.data.slice(1));
        }

        if (
          !isSearchResults &&
          setDomainTableData &&
          userId &&
          setAppendedCsvFile &&
          searchId
        ) {
          const tempResults = results;

          // Add user id
          tempResults.data[0].push(USER_ID_COLUMN);
          tempResults.data[1].push(userId);

          // Add searchId
          tempResults.data[0].push(SEARCH_ID_COLUMN);
          tempResults.data[1].push(searchId);

          setUploadResults(tempResults);

          const csvResults = jsonToCSV(tempResults.data);

          setAppendedCsvFile(csvResults);

          // Reduce data into domain format and remove headers from csv
          const reducedArray = results.data
            .reduce((accumulator: Record<string, unknown>[], value, index) => {
              if (index !== 0) {
                return accumulator.concat({ domain: value[0] });
              }

              return accumulator.concat({});
            }, [])
            .filter((element) => element.domain);
          setDomainTableData(reducedArray);
        }

        if (isPabmUpload && setDataToExport && setAppendedCsvFile) {
          const tempResults = results;

          const csvResults = jsonToCSV(tempResults.data);

          setAppendedCsvFile(csvResults);

          // set the data but not the column headers
          setDataToExport(results.data.slice(1));
        }
      }}
    >
      {({ getRootProps, acceptedFile, ProgressBar }) => (
        <>
          <SC.CsvReader>
            <SC.AcceptedFile>
              {acceptedFile && acceptedFile?.name}
            </SC.AcceptedFile>
            {acceptedFile &&
              isPabmUpload &&
              setAcceptedCsvFile &&
              setAcceptedCsvFile(acceptedFile)}
            {acceptedFile &&
              !isSearchResults &&
              setAcceptedCsvFile &&
              setAcceptedCsvFile(acceptedFile)}
            <ActionButton
              backgroundColor={themeContext.colors.general.green}
              textColor={themeContext.colors.general.white}
              type="button"
              {...getRootProps()}
            >
              Select File
            </ActionButton>
          </SC.CsvReader>

          <ProgressBar
            style={{
              backgroundColor: themeContext.colors.general.green,
              marginTop: 20,
              marginBottom: 20,
            }}
          />
        </>
      )}
    </CSVReader>
  );
};

export default CSVReader;
