import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import {
  faSterlingSign,
  faUserPlus,
  faNewspaper,
  faLaptopMobile,
  faBrowser,
  faCircleCheck,
  faRadar,
} from '@fortawesome/pro-duotone-svg-icons';

const TWO_TIMES = '2x';
const RECRUITMENT_SCORE = 'recruitmentScore';
const EVENTS_SCORE = 'eventsScore';
const TECH_SCORE = 'technologyScore';
const FINANCE_SCORE = 'financeScore';
const WEBSITE_SCORE = 'websiteScore';
const INSIGHTS_SCORE = 'insightsScore';
const LEAD_QUALITY = 'leadQuality';

const IconGenerator = (category: string, score: number) => {
  const themeContext = useContext(ThemeContext);

  if (category === RECRUITMENT_SCORE) {
    return (
      <FontAwesomeIcon
        icon={faUserPlus}
        size={TWO_TIMES}
        color={
          score > 40
            ? themeContext.colors.general.green
            : themeContext.colors.general.grey
        }
      />
    );
  }

  if (category === EVENTS_SCORE) {
    return (
      <FontAwesomeIcon
        icon={faNewspaper}
        size={TWO_TIMES}
        color={
          score > 40
            ? themeContext.colors.general.green
            : themeContext.colors.general.grey
        }
      />
    );
  }

  if (category === TECH_SCORE) {
    return (
      <FontAwesomeIcon
        icon={faLaptopMobile}
        size={TWO_TIMES}
        color={
          score > 40
            ? themeContext.colors.general.green
            : themeContext.colors.general.grey
        }
      />
    );
  }

  if (category === FINANCE_SCORE) {
    return (
      <FontAwesomeIcon
        icon={faSterlingSign}
        size={TWO_TIMES}
        color={
          score > 40
            ? themeContext.colors.general.green
            : themeContext.colors.general.grey
        }
      />
    );
  }

  if (category === WEBSITE_SCORE) {
    return (
      <FontAwesomeIcon
        icon={faBrowser}
        size={TWO_TIMES}
        color={
          score > 40
            ? themeContext.colors.general.green
            : themeContext.colors.general.grey
        }
      />
    );
  }

  if (category === INSIGHTS_SCORE) {
    return (
      <FontAwesomeIcon
        icon={faRadar}
        size={TWO_TIMES}
        color={
          score > 40
            ? themeContext.colors.general.green
            : themeContext.colors.general.grey
        }
      />
    );
  }

  if (category === LEAD_QUALITY) {
    return (
      <FontAwesomeIcon
        icon={faCircleCheck}
        size={TWO_TIMES}
        color={
          score > 40
            ? themeContext.colors.general.green
            : themeContext.colors.general.grey
        }
      />
    );
  }
};

export { IconGenerator };
