import { FC, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { ThemeContext } from 'styled-components';
import { pabmTemplateFile } from '../../consts/pabm';
import {
  ActionButton,
  ActionCardContainer,
  ActionCardContentContainer,
  StyledH1,
} from '../../main/theme';
import { showModal } from '../../store/modal';
import { ModalTypes } from '../Modal/Modal';
import * as SC from './styled';

const PersonalisedCopyCard: FC = () => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const green = themeContext.colors.general.green;
  const white = themeContext.colors.general.white;
  const black = themeContext.colors.general.black;
  return (
    <ActionCardContainer
      backgroundColor={green}
      borderColor={green}
      height={'100%'}
    >
      <ActionCardContentContainer>
        <SC.ContentWrapper>
          <SC.HeaderContainer>
            <StyledH1
              color={black}
              noMargin={true}
              style={{ marginTop: '1em' }}
            >
              Generate Personalised Copy
            </StyledH1>
            <div>
              <p>
                Generate personalised copy per targeting variant including job
                title, industry and content.
              </p>
            </div>
            <div>
              <p>
                Upload a CSV file using{' '}
                <a href={pabmTemplateFile}>this template</a> to generate
                personalised copy.
              </p>
            </div>
          </SC.HeaderContainer>

          <SC.ButtonContainer>
            <ActionButton
              backgroundColor={white}
              textColor={black}
              onClick={() =>
                dispatch(
                  showModal({
                    forceOpen: true,
                    visible: true,
                    modal: ModalTypes.PABM,
                  }),
                )
              }
            >
              Generate Copy
            </ActionButton>
          </SC.ButtonContainer>
        </SC.ContentWrapper>
      </ActionCardContentContainer>
    </ActionCardContainer>
  );
};

export default PersonalisedCopyCard;
