import { faBolt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu, Sidebar } from 'semantic-ui-react';
import { ThemeContext } from 'styled-components';
import { DARK_THEME } from '../../consts/projectConsts';
import { RouteName } from '../../enums/RouteName';
import { getLocalTheme } from '../../utils/dark-theme/getLocalTheme';
import UserProfile from '../UserProfile/UserProfile';
import * as SC from './styled';

interface NavigationBarProps {
  themeToggler: () => void;
}

const TWO_TIMES_SIZE = '2x';

const NavigationBar: FC<NavigationBarProps> = () => {
  const location = useLocation();
  const themeContext = useContext(ThemeContext);
  const { pathname } = location;

  const green = themeContext.colors.general.green;
  const grey = themeContext.colors.general.darkGrey;

  const localTheme = getLocalTheme();

  return (
    <>
      <Sidebar
        as={Menu}
        borderless
        icon="labeled"
        vertical
        visible={true}
        width="very thin"
        animation="push"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor:
            localTheme === DARK_THEME
              ? themeContext.overlayComponents
              : themeContext.colors.general.white,
          overflowX: 'hidden',
        }}
      >
        <SC.ShortcutsWrapper>
          <Menu.Item as={Link} to={RouteName.DASHBOARD}>
            <FontAwesomeIcon
              icon={faBolt}
              color={pathname === RouteName.DASHBOARD ? green : grey}
              size={TWO_TIMES_SIZE}
            />
          </Menu.Item>
        </SC.ShortcutsWrapper>
        <SC.UserProfileContainer>
          <Menu.Item>
            <UserProfile />
          </Menu.Item>
        </SC.UserProfileContainer>
      </Sidebar>
    </>
  );
};

export default NavigationBar;
