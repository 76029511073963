import styled from 'styled-components';
import { TRUE } from '../../consts/projectConsts';

export const Container = styled.div<{ isInsights?: boolean }>`
  background-color: ${({ theme }) =>
    theme.isDarkMode === TRUE
      ? theme.overlayComponents
      : theme.colors.general.white};
  border: ${({ theme, isInsights }) =>
    `1px solid ${
      isInsights
        ? theme.colors.general.purple
        : theme.isDarkMode === TRUE
        ? theme.colors.general.darkGrey
        : theme.colors.general.grey
    }`};
  border-radius: ${({ theme }) => theme.borders.small};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${({ theme }) => `${theme.margin.standard} 0 0 0`};
  padding-right: 10px;
`;

export const InnerContainer = styled.div`
  display: flex;
  height: 80px;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.margin.xxxlarge};
  align-items: center;
`;

export const IndividualContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 120px;
  min-width: 50px;
`;

export const NoResultsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: ${({ theme }) => theme.margin.xxxlarge};
`;

export const ColumnFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 60%;
`;

export const IconContainer = styled(IndividualContainer)`
  border: ${({ theme }) => `1px solid ${theme.colors.general.grey}`};
  width: 50px;
  height: 50px;
  border-radius: 50%;
  gap: ${({ theme }) => theme.margin.small};
  background-color: ${({ theme }) =>
    theme.isDarkMode === TRUE
      ? theme.colors.general.black
      : theme.colors.general.white};
  margin-left: 20px;
`;

export const ButtonContainer = styled.div`
  min-width: 100px;
  > a {
    display: flex;
    gap: ${({ theme }) => theme.margin.standard};
  }
`;

export const Button = styled.button<{
  disabled?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
}>`
  min-width: 120px;
  height: 38px;

  transition: background-color 0.25s, color 0.25s, opacity 0.25s;
  font-family: 'Roboto', 'sans-serif';
  font-weight: bold;

  background-color: ${({ theme, disabled, secondary, tertiary }) =>
    disabled
      ? tertiary
        ? theme.colors.system.darkGrey
        : theme.colors.system.grey
      : secondary
      ? theme.colors.general.sea
      : theme.colors.general.yellow};

  color: ${({ theme, disabled, secondary, tertiary }) =>
    disabled
      ? tertiary
        ? theme.colors.system.grey
        : theme.colors.system.darkGrey
      : secondary
      ? theme.colors.system.white
      : theme.colors.system.offBlack};

  border: none;
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.borders.small};

  cursor: ${({ disabled }) => (disabled ? 'disabled' : 'pointer')};

  &:hover {
    opacity: ${({ disabled }) => (disabled ? 1 : 0.7)};
  }

  margin-left: ${({ theme }) => theme.margin.standard};
`;
