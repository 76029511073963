import { useAuth0 } from '@auth0/auth0-react';
import { Interfaces } from '@configur-tech/discover-core-types';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInsights } from '../../store/insights';
import { RootState } from '../../store/rootReducer';

const useInsights = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const [insights, setInsights] = useState<Interfaces.Insights[]>([]);
  const companyInsightsState = useSelector(
    (state: RootState) => state.insights,
  );
  const companyInsights: Interfaces.Insights[] =
    companyInsightsState.data as Interfaces.Insights[];

  const getInsightsFromDomains = useCallback(
    async (companyDomains?: string[]) => {
      const token = await getAccessTokenSilently();

      if (token) {
        await dispatch(fetchInsights(token, companyDomains));
      }
    },
    [dispatch, getAccessTokenSilently],
  );

  // Load insights
  useEffect(() => {
    setInsights(companyInsights);
  }, [companyInsights]);

  return {
    insights,
    getInsightsFromDomains,
  };
};

export default useInsights;
