import { FC, useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faPlus, faMinus } from '@fortawesome/pro-duotone-svg-icons';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { cloneDeep, startCase } from 'lodash';

import * as SC from './styled';
import { hideModal, showModal } from '../../../store/modal';
import {
  ActionButton,
  SpecificallyStyledDropdown,
  SpecificallyStyledInput,
  StyledParagraph,
} from '../../../main/theme';
import { ModalTypes } from '../Modal';
import { ThemeContext } from 'styled-components';
import useLoggedInUser from '../../../hooks/user/useLoggedInUser';
import useToast from '../../../hooks/toast/useToast';

import FileService, {
  GetFileUploadUrlResponse,
} from '../../../services/file/FileService';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Constants,
  Enums,
  Interfaces,
} from '@configur-tech/discover-core-types';
import { DropdownItemProps } from 'semantic-ui-react';

export interface ApplyBoostersModalProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  applyBoostersProps?: { file: any; appendedCsvFile: string };
}

interface BoosterWithString {
  boostType: Enums.BoosterTypes.USES_SOFTWARE;
  condition: {
    conditionType:
      | Enums.ConditionTypes.INCLUDES
      | Enums.NumericConditionTypes.GREATER_THAN;
    conditionValues: string;
    additionalConditions: [];
  };
}

const initialBooster: Interfaces.Boosters | BoosterWithString = {
  boostType: Enums.BoosterTypes.USES_SOFTWARE,
  condition: {
    conditionType: Enums.ConditionTypes.INCLUDES,
    conditionValues: [] || '',
    additionalConditions: [],
  },
};

const technologyBoosters = Constants.TECHNOLOGIES;

const EXTRA_LARGE = 'xl';
const BOOST_TYPE = 'boostType';
const CONDITION = 'condition';
const COMMON_WIDTH = '250px';
const ApplyBoostersModal: FC<ApplyBoostersModalProps> = ({
  setShowModal,
  applyBoostersProps,
}) => {
  const { loggedInUser } = useLoggedInUser();
  const { getAccessTokenSilently } = useAuth0();
  const { notifyToast } = useToast();

  const themeContext = useContext(ThemeContext);
  const dispatch = useDispatch();

  const [acceptedCsvFile, setAcceptedCsvFile] = useState<File>();
  const [fileData, setFileData] = useState<string[]>([]);
  const [appendedCsvFile, setAppendedCsvFile] = useState<string>();
  const [isScoring, setIsScoring] = useState<boolean>(false);
  const [boosterData, setBoosterData] = useState<Interfaces.Boosters[]>([]);
  const [loadingOptions, setLoadingOptions] = useState<boolean>(false);
  const [locationBoosterOptions, setLocationBoosterOptions] = useState<
    DropdownItemProps[]
  >([]);
  const [techBoosterOptions, setTechBoosterOptions] = useState<
    DropdownItemProps[]
  >([]);
  const [debounceValue, setDebounceValue] = useState<string>();
  const [techSearchOptions, setTechSearchOptions] = useState<
    DropdownItemProps[]
  >([]);
  const [searchTechValue, setSearchTechValue] = useState<string>();

  const blue = themeContext.colors.general.blue;
  const white = themeContext.colors.general.white;

  const calculateDropdownOptionsFromTechnologyArray = (
    searchValue?: string,
  ) => {
    if (!searchValue) {
      return [];
    }

    return technologyBoosters
      .filter((techValue: string) =>
        techValue.toLowerCase().includes(searchValue.toLowerCase()),
      )
      .map((techValue: string) => {
        return {
          key: techValue,
          text: techValue,
          value: techValue,
        } as DropdownItemProps;
      });
  };

  // Allow user to finish typing before setting search value
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (debounceValue !== searchTechValue) {
        setSearchTechValue(debounceValue);
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [debounceValue, searchTechValue]);

  // If searching tech, limit option
  useEffect(() => {
    setTechSearchOptions(
      calculateDropdownOptionsFromTechnologyArray(searchTechValue),
    );
    setLoadingOptions(false);
  }, [searchTechValue]);

  const closeModal = () => {
    dispatch(hideModal());
  };

  // Add new booster row
  const addBoosterRow = () => {
    setBoosterData([...boosterData, initialBooster]);
  };

  // Remove booster row
  const removeBoosterRow = (index) => {
    setBoosterData(boosterData.filter((row, i) => i !== index));
  };

  // Confirm all fields completed
  const boostersComplete = (): boolean => {
    return !(
      boosterData.length &&
      !boosterData.every(
        (booster) =>
          booster.boostType &&
          booster.condition.conditionType &&
          booster.condition.conditionValues.length,
      )
    );
  };

  // Update booster row value
  const updateBoosterRowValue = (
    index: number,
    field: string,
    value: unknown,
  ) => {
    const cloned = cloneDeep(boosterData);

    cloned.map((row, i) => {
      if (i === index) {
        row[field] = value;
      }

      // Default greater than/includes dropdown
      if (field === BOOST_TYPE) {
        if (
          [
            Enums.BoosterTypes.RECEIVES_FINANCING,
            Enums.BoosterTypes.CURRENT_REVENUE,
          ].includes(value as Enums.BoosterTypes)
        ) {
          row.condition.conditionType =
            Enums.NumericConditionTypes.GREATER_THAN;
        } else {
          row.condition.conditionType = Enums.ConditionTypes.INCLUDES;
        }
      }

      return row;
    });

    setBoosterData(cloned);
  };

  const calculateDropdownOptionsFromEnums = (enumValues) => {
    const enumArray: string[] = Object.values(enumValues) as string[];

    return enumArray
      .filter((enumVal) => enumVal !== Enums.HiringPositionTypes.UNDEFINED)
      .map((enumValue: string) => {
        return {
          key: enumValue,
          text: startCase(enumValue.toLowerCase()),
          value: enumValue,
        } as DropdownItemProps;
      });
  };

  // Save Search
  const saveSearch = async () => {
    setIsScoring(true);
    let fileKey;
    if (acceptedCsvFile && loggedInUser) {
      const token = await getAccessTokenSilently();

      // Get upload URL
      const uploadUrl = await FileService.getFileUploadUrl(
        token,
        acceptedCsvFile.name,
        acceptedCsvFile.type,
        loggedInUser.auth0Id as string,
      );

      // Push new files to fileData array
      fileData.push(
        (uploadUrl as GetFileUploadUrlResponse).uploadId,
      ) as unknown as string[];

      // Set new files
      setFileData(fileData);

      // Upload file to S3
      await FileService.uploadFileToS3(
        (uploadUrl as GetFileUploadUrlResponse).uploadURL,
        appendedCsvFile as string,
      );

      fileKey = (uploadUrl as GetFileUploadUrlResponse).uploadId;
    }

    // Score companies
    notifyToast(
      `Companies uploaded! You will receive an email once processing is complete.`,
      { type: toast.TYPE.INFO },
    );

    setIsScoring(false);
  };

  // Set modal to display
  useEffect(() => {
    setShowModal(true);
    setAcceptedCsvFile(applyBoostersProps?.file);
    setAppendedCsvFile(applyBoostersProps?.appendedCsvFile);

    return () => setShowModal(false);
  }, [setShowModal]);

  return (
    <SC.Wrapper>
      <SC.HeaderContainer>
        <SC.StretchedContainer>
          <StyledParagraph>
            Score Leads /{' '}
            <SC.StyledLink
              onClick={() =>
                dispatch(
                  showModal({
                    forceOpen: true,
                    visible: true,
                    modal: ModalTypes.FILE_SELECT,
                  }),
                )
              }
            >
              File Select
            </SC.StyledLink>{' '}
            /{' '}
            <b style={{ color: themeContext.colors.general.blue }}>
              Apply Boosters
            </b>
          </StyledParagraph>
        </SC.StretchedContainer>
        <FontAwesomeIcon
          onClick={() => closeModal()}
          icon={faXmark}
          size={EXTRA_LARGE}
          style={{ cursor: 'pointer' }}
        />
      </SC.HeaderContainer>
      <SC.InformationParagraph>
        Boosters allow you to customise Discover’s scoring analysis to score
        leads that match your conditions higher.
      </SC.InformationParagraph>
      <SC.BoostersContainer>
        {boosterData.map((booster, index) => (
          <SC.IndividualBoosterContainer key={`booster-${index}`}>
            <SC.BoosterRow>
              <SpecificallyStyledDropdown
                style={
                  [
                    Enums.BoosterTypes.RECEIVES_FINANCING,
                    Enums.BoosterTypes.CURRENT_REVENUE,
                  ].includes(booster.boostType)
                    ? { width: '637px' }
                    : { width: '800px' }
                }
                search
                upward
                fluid
                selection
                name="boosters.boostType"
                value={booster.boostType}
                onChange={(e, { value }) =>
                  updateBoosterRowValue(index, BOOST_TYPE, value)
                }
                options={calculateDropdownOptionsFromEnums(Enums.BoosterTypes)}
              />
              {[
                Enums.BoosterTypes.RECEIVES_FINANCING,
                Enums.BoosterTypes.CURRENT_REVENUE,
              ].includes(booster.boostType) ? (
                <SpecificallyStyledDropdown
                  style={{ width: COMMON_WIDTH }}
                  fluid
                  selection
                  upward
                  defaultValue={Enums.NumericConditionTypes.GREATER_THAN}
                  name="boosters.condition.conditionType"
                  value={
                    Object.values(Enums.ConditionTypes).includes(
                      booster.condition.conditionType as Enums.ConditionTypes,
                    )
                      ? Enums.NumericConditionTypes.GREATER_THAN
                      : booster.condition.conditionType || ''
                  }
                  onChange={(e, { value }) =>
                    updateBoosterRowValue(index, CONDITION, {
                      ...booster.condition,
                      conditionType: value,
                    })
                  }
                  options={calculateDropdownOptionsFromEnums(
                    Enums.NumericConditionTypes,
                  )}
                />
              ) : (
                <SpecificallyStyledDropdown
                  style={{ width: COMMON_WIDTH }}
                  fluid
                  selection
                  upward
                  defaultValue={Enums.ConditionTypes.INCLUDES}
                  name="boosters.condition.conditionType"
                  value={booster.condition.conditionType || ''}
                  onChange={(e, { value }) =>
                    updateBoosterRowValue(index, CONDITION, {
                      ...booster.condition,
                      conditionType: value,
                    })
                  }
                  options={calculateDropdownOptionsFromEnums(
                    Enums.ConditionTypes,
                  )}
                />
              )}
              {[
                Enums.BoosterTypes.RECEIVES_FINANCING,
                Enums.BoosterTypes.CURRENT_REVENUE,
              ].includes(booster.boostType) && (
                <SpecificallyStyledInput
                  width={'78%'}
                  type={'text'}
                  placeholder={
                    [Enums.BoosterTypes.RECEIVES_FINANCING].includes(
                      booster.boostType,
                    )
                      ? 'Enter investment amount'
                      : 'Enter revenue amount'
                  }
                  name="boosters.condition.conditionValues"
                  value={
                    typeof booster.condition.conditionValues === 'string'
                      ? Number(
                          booster.condition.conditionValues.replace(/\D/g, ''),
                        ).toLocaleString()
                      : ''
                  }
                  onChange={(e, { value }) =>
                    updateBoosterRowValue(index, CONDITION, {
                      ...booster.condition,
                      conditionValues: value.replace(/,/g, ''),
                    })
                  }
                />
              )}
              {[Enums.BoosterTypes.USES_SOFTWARE].includes(
                booster.boostType,
              ) ? (
                <SpecificallyStyledDropdown
                  loading={loadingOptions}
                  search
                  fluid
                  selection
                  noResultsMessage={
                    [Enums.BoosterTypes.USES_SOFTWARE].includes(
                      booster.boostType,
                    )
                      ? 'No results found'
                      : 'Type to add a new value'
                  }
                  name="boosters.condition.conditionValues"
                  multiple
                  allowAdditions={
                    ![Enums.BoosterTypes.USES_SOFTWARE].includes(
                      booster.boostType,
                    )
                  }
                  onAddItem={(e, { value }) => {
                    const clone = cloneDeep(locationBoosterOptions);
                    clone.push({
                      key: value,
                      value: (value as string).toLowerCase(),
                      text: value,
                    });
                    setLocationBoosterOptions(clone);
                  }}
                  value={booster.condition.conditionValues || []}
                  onChange={(e, { value }) => {
                    updateBoosterRowValue(index, CONDITION, {
                      ...booster.condition,
                      conditionValues: value,
                    });

                    if (
                      [Enums.BoosterTypes.USES_SOFTWARE].includes(
                        booster.boostType,
                      )
                    ) {
                      setTechBoosterOptions(
                        ((value as string[]) || []).map((item) => ({
                          key: item,
                          value: item as string,
                          text: item,
                        })),
                      );
                    }
                  }}
                  upward
                  placeholder={'Start typing to search technologies'}
                  minCharacters={3}
                  onSearchChange={(e, { searchQuery }) => {
                    if (
                      [Enums.BoosterTypes.USES_SOFTWARE].includes(
                        booster.boostType,
                      )
                    ) {
                      setLoadingOptions(true);
                      setDebounceValue(searchQuery as string);
                    }
                  }}
                  options={
                    [Enums.BoosterTypes.USES_SOFTWARE].includes(
                      booster.boostType,
                    )
                      ? techSearchOptions.concat(
                          techBoosterOptions.filter(
                            (item) =>
                              !techSearchOptions.find(
                                (vals) => vals.key === item.key,
                              ),
                          ),
                        )
                      : locationBoosterOptions
                  }
                />
              ) : (
                <>
                  {![
                    Enums.BoosterTypes.RECEIVES_FINANCING,
                    Enums.BoosterTypes.CURRENT_REVENUE,
                  ].includes(booster.boostType) && (
                    <SpecificallyStyledDropdown
                      search
                      fluid
                      upward
                      selection
                      noResultsMessage={
                        [Enums.BoosterTypes.IS_CURRENTLY_HIRING].includes(
                          booster.boostType,
                        )
                          ? 'No results found'
                          : 'Type to add a new value'
                      }
                      name="boosters.condition.conditionValues"
                      multiple
                      allowAdditions={
                        ![Enums.BoosterTypes.IS_CURRENTLY_HIRING].includes(
                          booster.boostType,
                        )
                      }
                      onAddItem={(e, { value }) => {
                        const clone = cloneDeep(locationBoosterOptions);
                        clone.push({
                          key: value,
                          value: (value as string).toLowerCase(),
                          text: value,
                        });
                        setLocationBoosterOptions(clone);
                      }}
                      value={booster.condition.conditionValues || []}
                      onChange={(e, { value }) =>
                        updateBoosterRowValue(index, CONDITION, {
                          ...booster.condition,
                          conditionValues: value,
                        })
                      }
                      options={
                        [Enums.BoosterTypes.IS_CURRENTLY_HIRING].includes(
                          booster.boostType,
                        )
                          ? calculateDropdownOptionsFromEnums(
                              Enums.HiringSectorTypes,
                            )
                          : locationBoosterOptions
                      }
                    />
                  )}
                  {[Enums.BoosterTypes.IS_CURRENTLY_HIRING].includes(
                    booster.boostType,
                  ) && (
                    <SpecificallyStyledDropdown
                      search
                      fluid
                      selection
                      upward
                      multiple
                      placeholder="Optional - Specific recruitment level"
                      noResultsMessage={
                        [Enums.BoosterTypes.IS_CURRENTLY_HIRING].includes(
                          booster.boostType,
                        )
                          ? 'No results found'
                          : 'Type to add a new value'
                      }
                      value={booster.condition.additionalConditions || []}
                      name="boosters.condition.additionalConditions"
                      allowAdditions={
                        ![Enums.BoosterTypes.IS_CURRENTLY_HIRING].includes(
                          booster.boostType,
                        )
                      }
                      onChange={(e, { value }) => {
                        updateBoosterRowValue(index, CONDITION, {
                          ...booster.condition,
                          additionalConditions: value,
                        });
                      }}
                      options={calculateDropdownOptionsFromEnums(
                        Enums.HiringPositionTypes,
                      )}
                    />
                  )}
                </>
              )}
              <SC.BoosterRowAdditionContainer>
                <FontAwesomeIcon
                  icon={faPlus}
                  size={EXTRA_LARGE}
                  onClick={addBoosterRow}
                  style={{ cursor: 'pointer', paddingBottom: '5px' }}
                />
                <FontAwesomeIcon
                  icon={faMinus}
                  size={EXTRA_LARGE}
                  onClick={() => removeBoosterRow(index)}
                  style={{ cursor: 'pointer', paddingBottom: '5px' }}
                />
              </SC.BoosterRowAdditionContainer>
            </SC.BoosterRow>
          </SC.IndividualBoosterContainer>
        ))}
      </SC.BoostersContainer>

      <SC.ActionButtonContainer>
        {!boosterData.length && (
          <ActionButton
            onClick={addBoosterRow}
            backgroundColor={white}
            textColor={blue}
            border={true}
          >
            Apply Boosters
          </ActionButton>
        )}
        <ActionButton
          backgroundColor={blue}
          textColor={white}
          disabled={!boostersComplete() || isScoring}
          onClick={async () => {
            await saveSearch();
            return dispatch(
              showModal({
                forceOpen: true,
                visible: true,
                modal: ModalTypes.SUCCESS,
              }),
            );
          }}
        >
          {isScoring ? `Processing...` : `Score Leads`}
        </ActionButton>
      </SC.ActionButtonContainer>
    </SC.Wrapper>
  );
};

export default ApplyBoostersModal;
