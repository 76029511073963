import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.margin.xlarge};
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-self: center;
  gap: ${({ theme }) => theme.margin.xlarge};
`;
