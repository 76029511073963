import { FC, useContext, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-duotone-svg-icons';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import * as SC from './styled';
import { hideModal } from '../../../store/modal';
import CSVCLickAndDrag from '../../CSVClickAndDrag/CSVClickAndDrag';
import { ThemeContext } from 'styled-components';
import { StyledParagraph } from '../../../main/theme';

export interface FileSelectModalProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  fileSelectProps?: string;
}

const EXTRA_LARGE = 'xl';

const FileSelectModal: FC<FileSelectModalProps> = ({ setShowModal }) => {
  const themeContext = useContext(ThemeContext);
  const searchId = uuidv4();
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(hideModal());
  };

  // Set modal to display
  useEffect(() => {
    setShowModal(true);

    return () => setShowModal(false);
  }, [setShowModal]);

  return (
    <SC.Wrapper>
      <SC.HeaderContainer>
        <SC.StretchedContainer>
          <StyledParagraph>
            Score Leads /{' '}
            <b style={{ color: themeContext.colors.general.blue }}>
              File Select
            </b>
          </StyledParagraph>
        </SC.StretchedContainer>
        <FontAwesomeIcon
          onClick={() => closeModal()}
          icon={faXmark}
          size={EXTRA_LARGE}
          style={{ cursor: 'pointer' }}
        />
      </SC.HeaderContainer>
      <SC.InformationParagraph>
        To get started, simply upload a CSV file containing a list of company
        domain names you wish to score.
      </SC.InformationParagraph>
      <CSVCLickAndDrag searchId={searchId} />
    </SC.Wrapper>
  );
};

export default FileSelectModal;
