import React, { FC } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

export interface LoadingProps {
  loading?: boolean;
  text?: string;
}

const Loading: FC<LoadingProps> = ({ loading, text }) => {
  if (!loading) {
    return null;
  }

  return (
    <Dimmer active inverted>
      <Loader style={{ justifySelf: 'center' }} inverted>
        {text || 'Loading...'}
      </Loader>
    </Dimmer>
  );
};

export default Loading;
