import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import CompanyService from '../../../../services/configur/CompanyService';
import EntriesResponse from '../../../../services/configur/CompanyService';
import { AppThunk } from '../../../store';

export interface ScoredCompaniesState {
  data: EntriesResponse;
  loading: boolean;
  error: string | null;
}

const initialState: ScoredCompaniesState = {
  data: { entries: [], next: { hasMoreEntries: false, totalCount: 0 } },
  loading: false,
  error: null,
};

const CompaniesSlice = createSlice({
  name: 'scoredCompanies',
  initialState,
  reducers: {
    // Fetch scored
    fetchScoredCompaniesStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchScoredCompaniesSuccess(state, action: PayloadAction<EntriesResponse>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchScoredCompaniesFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchScoredCompaniesStart,
  fetchScoredCompaniesSuccess,
  fetchScoredCompaniesFailure,
} = CompaniesSlice.actions;

export default CompaniesSlice.reducer;

export const fetchCompaniesWithScore =
  (
    token: string,
    queryParams?,
    encoded?: boolean,
    limit?,
    pageNum?,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchScoredCompaniesStart());
      const fetched = await CompanyService.getCompanies(
        token,
        queryParams,
        encoded,
        limit,
        pageNum,
      );
      dispatch(fetchScoredCompaniesSuccess(fetched.data));
    } catch (error) {
      const err = error as string;
      dispatch(fetchScoredCompaniesFailure(err.toString()));
      dispatch(fetchScoredCompaniesSuccess([]));
    }
  };
