import { Enums } from '@configur-tech/discover-core-types';
import { startCase } from 'lodash';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Dropdown, DropdownItemProps } from 'semantic-ui-react';
import { ThemeContext } from 'styled-components';
import { EventCategories } from '../../../enums/EventCategories';
import { ExportColumn } from '../../../enums/ExportColumn';
import useExport from '../../../hooks/events/useExport';
import { DefaultExportResult } from '../../../interfaces/DefaultExportResult';
import { ExportFormat } from '../../../interfaces/ExportFormat';
import { VeeamExportResult } from '../../../interfaces/VeeamExportResult';
import {
  GenericButton,
  StyledBodySubHeader,
  StyledH3,
  StyledInput,
  StyledText,
} from '../../../main/theme';
import { hideModal } from '../../../store/modal';
import CSVDownloader from '../../CSVDownloader/CSVDownloader';
import CSVReader from '../../CSVReader/CSVReader';
import * as SC from './styled';

export interface ExportModalProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ExportModal: FC<ExportModalProps> = ({ setShowModal }) => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const location = useLocation();
  const searchId = location.pathname.split('/search-results/')?.[1];

  const { getCompaniesExportData, processingCount, processingTotal } =
    useExport();

  const [dataToExport, setDataToExport] = useState<
    ExportFormat[] | string[][]
  >();
  const [headers, setHeaders] = useState<{
    domains?: string;
    description?: string;
    revenue?: string;
    employeeCount?: string;
    events?: string;
  }>({
    domains: '',
    description: '',
    revenue: '',
    employeeCount: '',
    events: '',
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [headersArray, setHeadersArray] = useState<string[]>([]);
  const [isMutated, setIsMutated] = useState<boolean>(false);
  const [canExport, setCanExport] = useState<boolean>(false);
  const [eventTypes, setEventTypes] = useState<string[]>([]);
  const [totalEventLimit, setTotalEventLimit] = useState<number>(5); // default to 5 events per company
  const [eventTypeLimit, setEventTypeLimit] = useState<number | string>(0); // default to 0
  const [clientType, setClientType] = useState<Enums.ClientTypes>(
    Enums.ClientTypes.DEFAULT,
  );

  const calculateDropdownOptions = () => {
    return headersArray.map((header) => {
      return {
        key: header,
        text: header,
        value: header,
      };
    });
  };

  const updateEventValue = (value) => {
    setEventTypes(value);
  };

  const updateTotalEventLimitValue = (value) => {
    setTotalEventLimit(value);
  };

  const updateEventTypeLimitValue = (value) => {
    setEventTypeLimit(value);
  };

  const updateClientTypeValue = (value) => {
    setClientType(value);
  };

  const calculateDropdownOptionsFromEnums = (enumValues) => {
    const enumArray: string[] = Object.values(enumValues) as string[];

    return enumArray.map((enumValue: string) => {
      return {
        key: enumValue,
        text: startCase(enumValue.toLowerCase()),
        value: enumValue,
      } as DropdownItemProps;
    });
  };

  const onDropdownSelection = (column: ExportColumn, value: string) => {
    // Remove value from other columns if previously selected
    const updatedHeaders = Object.entries(headers).reduce(
      (acc: Record<string, string>, header) => {
        return {
          ...acc,
          [header[0]]:
            header[0] === column ? value : header[1] === value ? '' : header[1],
        };
      },
      {},
    );

    setHeaders(updatedHeaders);
  };

  const onExport = async () => {
    setIsLoading(true);

    // Grab initial indexes of each header
    const domainIndex = headersArray.indexOf(headers.domains as string);

    const existingData = dataToExport?.map((existingArray) => {
      // Get remaining header indexes which contain pre existing data
      const headersArrayWithIndexesRemoved = Array.from(
        Array(headersArray.length).keys(),
      ).filter((element) => element !== domainIndex);

      // Map it over into a {HeaderTitle: existingData} format
      return headersArrayWithIndexesRemoved.map((indexOfHeader) => {
        return {
          [headersArray[indexOfHeader] as string]: existingArray[
            indexOfHeader
          ] as string,
        };
      });
    });

    const exportData = await getCompaniesExportData(
      searchId,
      dataToExport as string[][],
      domainIndex,
      eventTypes,
      totalEventLimit,
      eventTypeLimit as string,
      clientType,
    );

    const mappedResponse = exportData?.map((responseArray, index) => {
      // Grab the existing data from the relevant function
      const clientData = !existingData ? [] : existingData[index];

      // Convert to a single object of key value pairs
      const mergedData = Object.assign({}, ...(clientData ? clientData : []));

      switch (clientType) {
        case Enums.ClientTypes.DEFAULT: {
          const castedResponse = responseArray as DefaultExportResult;

          // Return the new data with the old data appended
          return {
            ...mergedData,
            [headersArray[domainIndex] || 'Domains']: castedResponse.domain,
            ['Discover - Descriptions']:
              castedResponse.companyDescription || '',
            ['Discover - Industry']: castedResponse.industry || '',
            ['Discover - Revenue']: castedResponse.revenue || '',
            ['Discover - Employee Count']: castedResponse.employeeCount || '',
            ['Discover - Events']: castedResponse.events || '',
            ['Discover - Hiring Score']: castedResponse.hiringScore || '',
            ['Discover - Technology Score']:
              castedResponse.technologyScore || '',
            ['Discover - Events Score']: castedResponse.eventsScore || '',
            ['Discover - Website Score']: castedResponse.websiteScore || '',
            ['Discover - Finance Score']: castedResponse.financeScore || '',
            ['Discover - Insight Score']: castedResponse.insightsScore || '',
            ['Discover - Overall Score']: castedResponse.overallScore || '',
            ['Discover - Event Count']: castedResponse.eventCount,
          };
        }
        case Enums.ClientTypes.VEEAM: {
          const castedResponse = responseArray as VeeamExportResult;
          return {
            firstNameMKTO: castedResponse.firstNameMKTO,
            lastNameMKTO: castedResponse.lastNameMKTO,
            email: castedResponse.email,
            phone: castedResponse.phone,
            Asset1: castedResponse.Asset1,
            Asset2: castedResponse.Asset2,
            Asset3: castedResponse.Asset3,
            company: castedResponse.company,
            industry: castedResponse.industry,
            numEmployeesMKTO: castedResponse.numEmployeesMKTO,
            discussionNotes: castedResponse.discussionNotes,
            CAAttendance: castedResponse.CAAttendance,
            opt_in: castedResponse.opt_in,
            street: castedResponse.street,
            street_2: castedResponse.street_2,
            city: castedResponse.city,
            state: castedResponse.state,
            postalCode: castedResponse.postalCode,
            country: castedResponse.country,
            ['Discover - SOS']: castedResponse.discoverSos || '',
          };
        }
      }
    });

    setDataToExport(mappedResponse);
    setIsLoading(false);
    setIsMutated(true);
  };

  const closeModal = () => {
    dispatch(hideModal());
  };

  // Check if all export conditions met
  useEffect(() => {
    setCanExport(!(!dataToExport || isLoading));
  }, [dataToExport, isLoading]);

  // Set modal to display
  useEffect(() => {
    setShowModal(true);

    return () => setShowModal(false);
  }, [setShowModal]);

  return (
    <SC.Wrapper>
      <SC.Header>Export Search Results</SC.Header>

      <SC.SubHeader>Upload Export File</SC.SubHeader>
      <StyledText>
        This file must contain a minimum of 1 column containing a list of
        company website domains.
      </StyledText>

      <CSVReader
        isSearchResults={true}
        setHeadersData={setHeaders}
        setHeadersArray={setHeadersArray}
        setDataToExport={setDataToExport}
      />
      {!Object.values(headers).every((value) => value === '') && (
        <>
          <SC.ColumnWrapper>
            <StyledH3>Select Domain Column</StyledH3>
            <SC.CsvColumnSelectorContainer>
              <SC.ColumnHeaderWrapper>
                <StyledBodySubHeader>Company Domain</StyledBodySubHeader>
                <Dropdown
                  placeholder={'Select a column'}
                  style={{ width: '200px' }}
                  fluid
                  selection
                  name="domains"
                  value={headers.domains || ''}
                  onChange={(e, { value }) =>
                    onDropdownSelection(ExportColumn.DOMAIN, value as string)
                  }
                  options={calculateDropdownOptions()}
                />
              </SC.ColumnHeaderWrapper>
            </SC.CsvColumnSelectorContainer>
          </SC.ColumnWrapper>
          <SC.ColumnWrapper>
            <StyledH3>Select Export Format</StyledH3>
            <SC.CsvColumnSelectorContainer>
              <SC.ColumnHeaderWrapper>
                <StyledBodySubHeader>Export Format</StyledBodySubHeader>
                <Dropdown
                  placeholder={'Select a column'}
                  style={{ width: '200px' }}
                  fluid
                  selection
                  name="format"
                  value={clientType || ''}
                  onChange={(e, { value }) => updateClientTypeValue(value)}
                  options={calculateDropdownOptionsFromEnums(Enums.ClientTypes)}
                />
              </SC.ColumnHeaderWrapper>
            </SC.CsvColumnSelectorContainer>
          </SC.ColumnWrapper>

          <SC.ColumnWrapper>
            <SC.CsvColumnSelectorContainer>
              <SC.ColumnHeaderWrapper>
                <StyledBodySubHeader>
                  Select Event Types To Export
                </StyledBodySubHeader>
                <Dropdown
                  fluid
                  style={{ width: '400px' }}
                  selection
                  placeholder="Optional - Types of event to export"
                  name="eventTypes"
                  multiple
                  value={eventTypes || []}
                  onChange={(e, { value }) => updateEventValue(value)}
                  upward
                  options={calculateDropdownOptionsFromEnums(EventCategories)}
                  search
                />
              </SC.ColumnHeaderWrapper>
              <SC.ColumnHeaderWrapper>
                <StyledBodySubHeader>
                  Total Event Limits - Optional
                </StyledBodySubHeader>
                <StyledInput
                  type={'number'}
                  backgroundColor={themeContext.colors.general.white}
                  width={'220px'}
                  placeholder="Optional - Total limit on events"
                  name="totalEventLimit"
                  value={totalEventLimit || ''}
                  onChange={(e, { value }) => updateTotalEventLimitValue(value)}
                />
              </SC.ColumnHeaderWrapper>
              <SC.ColumnHeaderWrapper>
                <StyledBodySubHeader>
                  Event Type Limits - Optional
                </StyledBodySubHeader>
                <StyledInput
                  type={'number'}
                  backgroundColor={themeContext.colors.general.white}
                  width={'220px'}
                  placeholder="Optional - Event Type Limit"
                  name="eventTypesLimit"
                  value={eventTypeLimit || ''}
                  onChange={(e, { value }) => updateEventTypeLimitValue(value)}
                />
              </SC.ColumnHeaderWrapper>
            </SC.CsvColumnSelectorContainer>
          </SC.ColumnWrapper>
        </>
      )}

      <SC.ActionButtonWrapper>
        <GenericButton
          onClick={() => closeModal()}
          secondary={true}
          width={200}
        >
          Close
        </GenericButton>
        {!isMutated && (
          <GenericButton
            disabled={!canExport}
            backgroundColor={themeContext.colors.general.yellow}
            width={200}
            onClick={async () => await onExport()}
          >
            {isLoading
              ? processingCount
                ? `Processing ${processingCount}/${processingTotal}`
                : 'Processing...'
              : `Create Export`}
          </GenericButton>
        )}
        {isMutated && <CSVDownloader data={dataToExport} />}
      </SC.ActionButtonWrapper>
    </SC.Wrapper>
  );
};

export default ExportModal;
