import { FC, useContext, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-duotone-svg-icons';
import { useDispatch } from 'react-redux';
import * as SC from './styled';
import { hideModal } from '../../../store/modal';
import { ActionButton, StyledParagraph } from '../../../main/theme';
import { ThemeContext } from 'styled-components';

export interface SuccessModalProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  successProps?: string;
}

const EXTRA_LARGE = 'xl';

const SuccessModal: FC<SuccessModalProps> = ({
  setShowModal,
  successProps,
}) => {
  const themeContext = useContext(ThemeContext);
  const dispatch = useDispatch();

  const blue = themeContext.colors.general.blue;
  const white = themeContext.colors.general.white;

  const closeModal = () => {
    dispatch(hideModal());
  };

  // Set modal to display
  useEffect(() => {
    setShowModal(true);

    return () => setShowModal(false);
  }, [setShowModal]);

  return (
    <SC.Wrapper>
      <SC.HeaderContainer>
        <SC.StretchedContainer>
          <StyledParagraph>
            Score Leads / File Select / Apply Boosters /{' '}
            <b style={{ color: themeContext.colors.general.blue }}>Success</b>
          </StyledParagraph>
        </SC.StretchedContainer>
        <FontAwesomeIcon
          onClick={() => closeModal()}
          icon={faXmark}
          size={EXTRA_LARGE}
          style={{ cursor: 'pointer' }}
        />
      </SC.HeaderContainer>
      <SC.InformationParagraph>
        Success! Your leads are now being scored and you will receive an email
        when they process is complete.
      </SC.InformationParagraph>
      <ActionButton
        textColor={blue}
        border={true}
        backgroundColor={white}
        onClick={() => closeModal()}
      >
        Confirm
      </ActionButton>
    </SC.Wrapper>
  );
};

export default SuccessModal;
