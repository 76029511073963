import { useAuth0 } from '@auth0/auth0-react';
import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';

import Loading from '../components/Loading/Loading';
import { ErrorType } from '../components/Modal/error/ErrorModal';
import Modal, { ModalTypes } from '../components/Modal/Modal';
import NavigationBar from '../components/NavigationBar/NavigationBar';
import { RouteName } from '../enums/RouteName';
import { DEFAULT_TOAST_LIMIT } from '../hooks/toast/useToast';
import useLoggedInUser from '../hooks/user/useLoggedInUser';
import Routing from '../routing/Routing';
import { hideLoading, showLoading } from '../store/loading/overallLoading';
import { showModal } from '../store/modal';
import { RootState } from '../store/rootReducer';
import * as TC from './../main/theme';

const USER_FETCH_RETRY_LIMIT = 3;

interface AppContainerProps {
  themeToggler: () => void;
}

const AppContainer: FC<AppContainerProps> = ({ themeToggler }) => {
  const { isAuthenticated, isLoading, loginWithRedirect, user } = useAuth0();
  const { loggedInUser, getLoggedInUser } = useLoggedInUser();
  const dispatch = useDispatch();
  const location = useLocation().pathname;
  const loadingState = useSelector((state: RootState) => state.loading);
  const loggedInUserState = useSelector((state: RootState) => state.user);
  const modalState = useSelector((state: RootState) => state.modal);

  const [fetchingLoggedInUser, setFetchingLoggedInUser] =
    useState<boolean>(false);
  const [fetchingUserCount, setFetchingUserCount] = useState<number>(0);
  const [retryingLoggedInUser, setRetryingLoggedInUser] =
    useState<boolean>(false);

  // Load user
  useEffect(() => {
    if (
      isAuthenticated &&
      user &&
      !loggedInUser?.auth0Id &&
      !fetchingLoggedInUser
    ) {
      (async () => {
        setFetchingLoggedInUser(true);
        dispatch(showLoading({ text: 'Loading User...' }));
        await getLoggedInUser();
        dispatch(hideLoading());
      })();
    }
  }, [
    dispatch,
    fetchingLoggedInUser,
    getLoggedInUser,
    isAuthenticated,
    loggedInUser?.auth0Id,
    user,
  ]);

  const retryUserLookup = useCallback(() => {
    setTimeout(() => {
      setFetchingLoggedInUser(false);
      setFetchingUserCount(fetchingUserCount + 1);
      setRetryingLoggedInUser(false);
    }, 2000);
  }, [fetchingUserCount]);

  // No user found
  useEffect(() => {
    if (loggedInUserState.error) {
      if (!retryingLoggedInUser && fetchingUserCount < USER_FETCH_RETRY_LIMIT) {
        // Try again in case of signup delay
        setRetryingLoggedInUser(true);
        return retryUserLookup();
      }

      if (fetchingUserCount === USER_FETCH_RETRY_LIMIT) {
        dispatch(hideLoading());
        dispatch(
          showModal({
            visible: true,
            modal: ModalTypes.ERROR,
            forceOpen: true,
            additionalProps: {
              errorType: ErrorType.NO_USER_FOUND,
            },
          }),
        );
      }
    }
  }, [
    dispatch,
    fetchingUserCount,
    loggedInUserState.error,
    retryUserLookup,
    retryingLoggedInUser,
  ]);

  if (!isAuthenticated && !isLoading) {
    const domain = process.env['REACT_APP_DOMAIN'] || window.location.pathname;

    loginWithRedirect({
      screen_hint: location === RouteName.SIGNUP ? 'signup' : 'login',
      appState: {
        redirectUrl: window.location.href.replace(domain, ''),
      },
    });
  }

  return (
    <>
      <Modal
        visible={modalState.visible}
        modal={modalState.modal}
        additionalProps={modalState.additionalProps}
        style={modalState.style}
        fullScreen={modalState.fullScreen}
        forceOpen={modalState.forceOpen}
        scrollable={modalState.scrollable}
      />

      <TC.AppWrapper>
        <NavigationBar themeToggler={themeToggler} />

        <TC.AppContainer className="App">
          <Loading loading={loadingState.loading} text={loadingState.text} />
          <ToastContainer limit={DEFAULT_TOAST_LIMIT} />
          {loggedInUser?.auth0Id && <Routing />}
        </TC.AppContainer>
      </TC.AppWrapper>
    </>
  );
};

export default AppContainer;
