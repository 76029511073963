import { Enums } from '@configur-tech/discover-core-types';
import { startCase } from 'lodash';
import { FC, useContext } from 'react';
import { Divider, DropdownItemProps } from 'semantic-ui-react';
import { ThemeContext } from 'styled-components';
import { DARK_THEME } from '../../consts/projectConsts';
import * as TC from '../../main/theme';
import { getLocalTheme } from '../../utils/dark-theme/getLocalTheme';
import * as SC from './styled';

export enum ScoreRanges {
  ZERO_TO_THIRTY_NINE = '0-39',
  FORTY_TO_ONE_HUNDRED = '40-100',
  ONE_HUNDRED_PLUS = '100+',
}

export enum RevenueRanges {
  ZERO_TO_HALF_MILLION = '0-500K',
  HALF_MILLION_TO_ONE_MILLION = '500K-1M',
  ONE_MILLION_TO_FIVE_MILLION = '1M-5M',
  FIVE_MILLION_TO_TEN_MILLION = '5M-10M',
  TEN_MILLION_TO_TWENTY_FIVE_MILLION = '10M-25M',
  TWENTY_FIVE_MILLION_TO_FIFTY_MILLION = '25M-50M',
  FIFTY_MILLION_PLUS = '50M+',
}

interface FilterLeadsProps {
  setCompanyNameFilter: (data) => void;
  setCompanySectorFilter: (data) => void;
  setCompanyCountryFilter: (data) => void;
  setCompanyRevenueFilter: (data) => void;
  setDiscoverScoreFilter: (data) => void;
  setHiringScoreFilter: (data) => void;
  setNewsScoreFilter: (data) => void;
  setTechScoreFilter: (data) => void;
  setFinanceScoreFilter: (data) => void;
  setWebsiteScoreFilter: (data) => void;
  setInsiderScoreFilter: (data) => void;

  companyNameFilter?: string;
  companySectorFilter?: string;
  companyCountryFilter?: string;
  companyRevenueFilter: string;
  discoverScoreFilter: string;
  hiringScoreFilter: string;
  newsScoreFilter: string;
  techScoreFilter: string;
  financeScoreFilter: string;
  websiteScoreFilter: string;
  insiderScoreFilter: string;

  setFiltersFetched: (data) => void;
  setFiltersCleared: (data) => void;
}

const FilterLeadsSideBar: FC<FilterLeadsProps> = ({
  setCompanyNameFilter,
  setCompanySectorFilter,
  setCompanyCountryFilter,
  setCompanyRevenueFilter,
  setDiscoverScoreFilter,
  setHiringScoreFilter,
  setNewsScoreFilter,
  setTechScoreFilter,
  setFinanceScoreFilter,
  setWebsiteScoreFilter,
  setInsiderScoreFilter,
  companyNameFilter,
  companySectorFilter,
  companyCountryFilter,
  companyRevenueFilter,
  discoverScoreFilter,
  hiringScoreFilter,
  newsScoreFilter,
  techScoreFilter,
  financeScoreFilter,
  websiteScoreFilter,
  insiderScoreFilter,
  setFiltersFetched,
  setFiltersCleared,
}) => {
  const themeContext = useContext(ThemeContext);

  const localTheme = getLocalTheme();

  const calculateDropdownOptionsFromEnums = (
    enumValues,
    isScoreRanges?: boolean,
  ) => {
    const enumArray: string[] = Object.values(enumValues) as string[];

    return enumArray
      .filter((enumVal) => enumVal !== Enums.HiringPositionTypes.UNDEFINED)
      .map((enumValue: string) => {
        return {
          key: enumValue,
          text: isScoreRanges ? enumValue : startCase(enumValue.toLowerCase()),
          value: enumValue,
        } as DropdownItemProps;
      });
  };

  const clearFilters = () => {
    setCompanyNameFilter('');
    setCompanyRevenueFilter('');
    setCompanySectorFilter('');
    setCompanyCountryFilter('');
    setDiscoverScoreFilter('');
    setFinanceScoreFilter('');
    setHiringScoreFilter('');
    setInsiderScoreFilter('');
    setNewsScoreFilter('');
    setTechScoreFilter('');
    setWebsiteScoreFilter('');
    setFiltersCleared(true);
  };

  return (
    <SC.Container
      style={{
        backgroundColor:
          localTheme === DARK_THEME
            ? themeContext.overlayComponents
            : themeContext.colors.general.white,
      }}
    >
      <SC.HeaderContainer>
        <TC.StyledH1
          color={themeContext.colors.general.green}
          style={{ margin: 0 }}
        >
          Filter Leads
        </TC.StyledH1>
        <TC.StyledLink
          color={themeContext.colors.general.grey}
          onClick={() => clearFilters()}
        >
          Clear Filters
        </TC.StyledLink>
      </SC.HeaderContainer>
      <SC.InputContainer>
        <SC.CompanyNameContainer>
          <b>Company Name</b>
          <TC.SpecificallyStyledInput
            name={'companyName'}
            type={'text'}
            placeholder={'Filter by a specific company name'}
            value={companyNameFilter || ''}
            onChange={(e, { value }) => setCompanyNameFilter(value)}
          />
        </SC.CompanyNameContainer>
        <SC.CompanyIndustryContainer>
          <b>Company Industry</b>
          <TC.SpecificallyStyledInput
            name={'industry'}
            type={'text'}
            placeholder={'Filter by a specific company industry'}
            value={companySectorFilter || ''}
            onChange={(e, { value }) => setCompanySectorFilter(value)}
          />
        </SC.CompanyIndustryContainer>
        <SC.CompanyIndustryContainer>
          <b>Company Country</b>
          <TC.SpecificallyStyledInput
            name={'country'}
            type={'text'}
            placeholder={'Filter by a specific country'}
            value={companyCountryFilter || ''}
            onChange={(e, { value }) => setCompanyCountryFilter(value)}
          />
        </SC.CompanyIndustryContainer>
        <SC.DropdownContainer>
          <b>Revenue</b>
          <TC.SpecificallyStyledDropdown
            name={'revenue'}
            search
            selection
            clearable
            placeholder={'Filter by a revenue range'}
            options={calculateDropdownOptionsFromEnums(RevenueRanges, true)}
            value={companyRevenueFilter || ''}
            onChange={(e, { value }) => setCompanyRevenueFilter(value)}
          />
        </SC.DropdownContainer>
        <SC.DropdownContainer>
          <b>Discover Score</b>
          <TC.SpecificallyStyledDropdown
            name={'overallScore'}
            search
            selection
            clearable
            placeholder={'Filter by a Discover overall score'}
            options={calculateDropdownOptionsFromEnums(ScoreRanges, true)}
            value={discoverScoreFilter || ''}
            onChange={(e, { value }) => setDiscoverScoreFilter(value)}
          />
        </SC.DropdownContainer>
        <SC.DropdownContainer>
          <b>Hiring Score</b>
          <TC.SpecificallyStyledDropdown
            name={'hiringScore'}
            search
            selection
            clearable
            placeholder={'Filter by a Discover hiring score'}
            options={calculateDropdownOptionsFromEnums(ScoreRanges, true)}
            value={hiringScoreFilter || ''}
            onChange={(e, { value }) => setHiringScoreFilter(value)}
          />
        </SC.DropdownContainer>
        <SC.DropdownContainer>
          <b>News Score</b>
          <TC.SpecificallyStyledDropdown
            name={'newsScore'}
            search
            selection
            clearable
            placeholder={'Filter by a Discover news score'}
            options={calculateDropdownOptionsFromEnums(ScoreRanges, true)}
            value={newsScoreFilter || ''}
            onChange={(e, { value }) => setNewsScoreFilter(value)}
          />
        </SC.DropdownContainer>
        <SC.DropdownContainer>
          <b>Tech Score</b>
          <TC.SpecificallyStyledDropdown
            name={'techScore'}
            search
            selection
            clearable
            placeholder={'Filter by a Discover tech score'}
            options={calculateDropdownOptionsFromEnums(ScoreRanges, true)}
            value={techScoreFilter || ''}
            onChange={(e, { value }) => setTechScoreFilter(value)}
          />
        </SC.DropdownContainer>
        <SC.DropdownContainer>
          <b>Finance Score</b>
          <TC.SpecificallyStyledDropdown
            name={'financeScore'}
            search
            selection
            clearable
            placeholder={'Filter by a Discover financial score'}
            options={calculateDropdownOptionsFromEnums(ScoreRanges, true)}
            value={financeScoreFilter || ''}
            onChange={(e, { value }) => setFinanceScoreFilter(value)}
          />
        </SC.DropdownContainer>
        <SC.DropdownContainer>
          <b>Website Score</b>
          <TC.SpecificallyStyledDropdown
            name={'websiteScore'}
            search
            selection
            clearable
            placeholder={'Filter by a Discover website score'}
            options={calculateDropdownOptionsFromEnums(ScoreRanges, true)}
            value={websiteScoreFilter || ''}
            onChange={(e, { value }) => setWebsiteScoreFilter(value)}
          />
        </SC.DropdownContainer>
        <SC.InsiderContainer>
          <b>Insider Score</b>
          <TC.SpecificallyStyledDropdown
            name={'insiderScore'}
            search
            selection
            clearable
            placeholder={'Filter by a Discover insider score'}
            options={calculateDropdownOptionsFromEnums(ScoreRanges, true)}
            value={insiderScoreFilter || ''}
            onChange={(e, { value }) => setInsiderScoreFilter(value)}
          />
        </SC.InsiderContainer>
      </SC.InputContainer>
      <Divider />
      <SC.ButtonContainer>
        <TC.ActionButton
          secondary={true}
          onClick={() => setFiltersFetched(true)}
          textColor={themeContext.colors.system.pureWhite}
        >
          Filter Leads
        </TC.ActionButton>
      </SC.ButtonContainer>
    </SC.Container>
  );
};

export default FilterLeadsSideBar;
