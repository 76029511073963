import { FC } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { RouteName } from '../enums/RouteName';
import CompaniesPage from '../pages/Companies/CompaniesPage';
import CompanyPage from '../pages/Company/CompanyPage';

// Pages
import DashboardPage from '../pages/Dashboard/DashboardPage';
import NotFoundPage from '../pages/NotFound/NotFoundPage';

const Routing: FC = () => {
  return (
    <Switch>
      <Route
        path={RouteName.DASHBOARD}
        exact={true}
        component={DashboardPage}
      />
      <Route
        path={RouteName.COMPANIES}
        exact={true}
        component={CompaniesPage}
      />
      <Route
        path={`${RouteName.COMPANY}/:companyDomain`}
        exact={true}
        component={CompanyPage}
      />
      <Route path={RouteName.NOT_FOUND} component={NotFoundPage} />
      <Redirect to={RouteName.NOT_FOUND} />
    </Switch>
  );
};

export default Routing;
