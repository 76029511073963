import { useAuth0 } from '@auth0/auth0-react';
import React, { FC, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ThemeContext } from 'styled-components';
import { StyledText } from '../../../main/theme';
import { hideModal } from '../../../store/modal';
import FeatureButton, {
  FeatureButtonSize,
} from '../../FeatureButton/FeatureButton';
import * as SC from './styled';

export enum ErrorAction {
  LOGOUT = 'logout',
}

export enum ErrorType {
  GENERAL = 'general',
  NO_USER_FOUND = 'no-user-found',
  PERMISSION_DENIED = 'permission-denied',
}

export interface ErrorModalProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  errorType?: ErrorType;
  errorHeading?: string;
  errorText?: string;
  errorCancel?: () => void;
  errorCancelText?: string;
  errorAction?: ErrorAction | (() => void);
  errorActionText?: string;
}

interface ErrorMap {
  [key: string]: {
    errorHeading?: string;
    errorText?: string;
    errorCancel?: () => void;
    errorCancelText?: string;
    errorAction?: ErrorAction | (() => void);
    errorActionText?: string;
  };
}

const ErrorModal: FC<ErrorModalProps> = ({
  setShowModal,
  errorType,
  errorHeading,
  errorText,
  errorCancel,
  errorCancelText,
  errorAction,
  errorActionText,
}) => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { logout } = useAuth0();

  const domain = process.env['REACT_APP_DOMAIN'] || window.location.pathname;

  const closeModal = () => {
    dispatch(hideModal());
  };

  const errorMessageMap: ErrorMap = {
    [ErrorType.GENERAL]: {
      errorHeading: `Something Went Wrong`,
      errorText: `That didn't go quite to plan. Please try again later.`,
      errorAction: () => closeModal(),
      errorActionText: 'Okay',
    },
    [ErrorType.NO_USER_FOUND]: {
      errorHeading: 'No User Found',
      errorText:
        'No user account could be found. Please double check your login credentials or try again later.',
      errorAction: () => logout({ returnTo: window.location.origin }),
      errorActionText: 'Login',
    },
    [ErrorType.PERMISSION_DENIED]: {
      errorHeading: 'Nothing To See Here',
      errorText: `This page or portal either doesn't exist or you don't have the correct permissions to be able to access it. Please contact your organisation manager.`,
      errorAction: () =>
        logout({
          returnTo: domain,
        }),
      errorActionText: 'Logout',
    },
  };

  const errorObj = errorMessageMap[errorType || ''];

  // Set modal to display
  useEffect(() => {
    setShowModal(true);

    return () => setShowModal(false);
  }, [setShowModal]);

  return (
    <SC.Wrapper>
      <SC.Header>{errorHeading || errorObj?.errorHeading || 'Error'}</SC.Header>

      <StyledText>
        {errorText ||
          errorObj?.errorText ||
          `That's not gone quite to plan. Let's try again.`}
      </StyledText>

      <SC.ActionButtonWrapper>
        {(errorCancel || errorObj?.errorCancel) && (
          <FeatureButton
            action={errorObj?.errorCancel || closeModal}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.yellow}
            text={errorCancelText || errorObj?.errorCancelText || 'Cancel'}
          />
        )}

        {typeof (errorAction || errorObj?.errorAction) === 'function' && (
          <FeatureButton
            action={(errorObj?.errorAction as () => void) || closeModal}
            size={FeatureButtonSize.WIDE}
            color={themeContext.colors.general.yellow}
            text={errorActionText || errorObj?.errorActionText || 'Confirm'}
          />
        )}
      </SC.ActionButtonWrapper>
    </SC.Wrapper>
  );
};

export default ErrorModal;
