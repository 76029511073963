const NOT_SCORED = 'Not scored';
const GOOD = 'Good';
const EXCELLENT = 'Excellent';
const BOOSTER_ACTIVATED = 'Booster activated';

/**
 * @description - Helper method to convert a score to a string
 *
 * @param {number} [score] - Score coming through
 * @param {boolean} [isSpecificCategory] - Flag to check if the score is a specific category
 *
 * @returns {string}
 */
export const scoreToRankHelper = (
  score?: number,
  isSpecificCategory?: boolean,
): string => {
  if (!score && isSpecificCategory) {
    return NOT_SCORED;
  }

  if (!score || score < 40) {
    return GOOD;
  }

  if (score >= 40 && score <= 100) {
    return EXCELLENT;
  }

  if (score > 100) {
    return BOOSTER_ACTIVATED;
  }

  return '';
};
