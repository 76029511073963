import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingProps } from '../../../components/Loading/BackgroundScoresLoading';
import InitialLoading from '../initial-state';

const SubscribedComponentLoadingSlice = createSlice({
  name: 'subscribedComponentLoading',
  initialState: InitialLoading,
  reducers: {
    showSubscribedComponentLoading(state, action: PayloadAction<LoadingProps>) {
      state.loading = true;
      state.text = action?.payload.text;
    },
    hideSubscribedComponentLoading(state) {
      state.loading = false;
      state.text = undefined;
    },
  },
});

export const {
  showSubscribedComponentLoading,
  hideSubscribedComponentLoading,
} = SubscribedComponentLoadingSlice.actions;

export default SubscribedComponentLoadingSlice.reducer;
