import { Interfaces } from '@configur-tech/discover-core-types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import InsightsService from '../../services/configur/InsightsService';
import { AppThunk } from '../store';

export interface SearchEntry {
  id: number;
  userId: string;
  companyName: string;
  companyDescription: string;
  tags: string | string[];
  domain: string | string[];
  boosters: string | Interfaces.Boosters[];
}

export interface InsightsState {
  data: Interfaces.Insights[];
  loading: boolean;
  error: string | null;
}

const initialState: InsightsState = {
  data: [],
  loading: false,
  error: null,
};

const InsightsSlice = createSlice({
  name: 'insights',
  initialState,
  reducers: {
    // Fetch insights
    fetchInsightsStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchInsightsSuccess(state, action: PayloadAction<Interfaces.Insights[]>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchInsightsFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchInsightsStart,
  fetchInsightsSuccess,
  fetchInsightsFailure,
} = InsightsSlice.actions;

export default InsightsSlice.reducer;

export const fetchInsights =
  (token: string, domains?: string[]): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchInsightsStart());
      const fetched = await InsightsService.getInsights(token, domains);

      dispatch(fetchInsightsSuccess(fetched.data.entries));
    } catch (error) {
      const err = error as string;
      dispatch(fetchInsightsFailure(err.toString()));
    }
  };
