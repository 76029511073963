import { FC } from 'react';
import GoogleFontLoader from 'react-google-font-loader';
import { Button, Card, Dropdown, Input, TextArea } from 'semantic-ui-react';
import styled, { DefaultTheme, ThemeProvider } from 'styled-components';
import { DARK_THEME, TRUE } from '../consts/projectConsts';
import { GlobalStyles } from './fonts';

export const defaultTheme: DefaultTheme = {
  colors: {
    system: {
      darkBlue: '#272C34',
      darkBlueHighlight: '#3D4857',
      darkBlueShadow: '#1B1F24',
      darkGrey: '#C8C8C8',
      darkOffBlack: '#23282F',

      grey: '#F7F7F7',
      navy: '#01203A',
      offBlack: '#2F3640',
      offWhite: '#F0EEEE',
      white: '#F8F8F8',
      pureWhite: '#FFF',
    },
    general: {
      blue: '#3B79B1',
      green: '#02eec2',
      purple: '#6F50C7',
      red: '#9d0053',
      scoreRed: '#E70000',
      sea: '#009d98',
      amber: '#EDAB00',
      yellow: '#FBBD08',
      white: '#FFFFFF',
      offWhite: '#F7F7F7',
      black: '#000000',
      darkModeBackground: '#121212',
      grey: '#D9D9D9',
      darkGrey: '#484848',
    },
  },

  typography: {
    header: `
      font-family: 'GothicA1', 'sans-serif';
      margin: 0;
      padding: 0;
    `,
    featureHeader: `
      font-family: 'GothicA1';
      font-weight: bold;
      margin: 0;
      padding: 0;
    `,
    body: `
      font-size: 0.9em;
      font-family: 'GothicA1', 'sans-serif';
      font-weight: regular;
      margin: 0 0 1em 0;
      padding: 0;
    `,
    sizes: {
      p: '1.0em',
      smaller: '0.85em',
      small: '0.75em',
      tiny: '0.65em',
      h1: '1.5em',
      h2: '1.3em',
      h3: '1.1em',
      h4: '1.05em',
      h5: '1.0em',
      featureH1: '2.0em',
      featureH2: '1.8em',
      featureH3: '1.6em',
      featureH4: '1.4em',
      featureH5: '1.2em',
    },
  },

  margin: {
    small: '5px',
    standard: '10px',
    large: '15px',
    xlarge: '20px',
    xxlarge: '25px',
    xxxlarge: '30px',
    xxxxlarge: '40px',
    xxxxxlarge: '50px',
  },

  padding: {
    small: '5px',
    standard: '10px',
    large: '15px',
    xlarge: '20px',
    xxlarge: '25px',
    xxxlarge: '30px',
    xxxxlarge: '40px',
  },

  borders: {
    small: '5px',
    radius: '10px',
  },
  body: '',
  text: '',
};

export const lightTheme = {
  body: '#FFF',
  text: '#363537',
  isDarkMode: 'false',
  toggleBorder: '#d4d4d5',
  gradient: 'linear-gradient(#39598A, #79D7ED)',
};

export const darkTheme = {
  body: '#121212',
  overlayComponents: '#202020',
  text: '#FAFAFA',
  isDarkMode: TRUE,
  toggleBorder: '#6B8096',
  gradient: 'linear-gradient(#091236, #1E215D)',
};

export const StyledText = styled.p<{ color?: string }>`
  ${({ theme }) => theme.typography.body};
  color: ${({ theme, color }) =>
    color
      ? color
      : theme.isDarkMode === TRUE
      ? `${theme.colors.system.white} !important`
      : theme.colors.system.black};
  font-size: ${({ theme }) => theme.typography.sizes.p};
  margin: 0;
`;

export const StyledFooterText = styled.p`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.general.grey};
`;

export const StyledBodySubHeader = styled(StyledText)`
  ${({ theme }) => theme.typography.header};
  font-size: ${({ theme }) => theme.typography.sizes.h5};
  margin-bottom: ${({ theme }) => theme.margin.small};
`;

export const StyledH1 = styled.h1<{
  black?: boolean;
  noMargin?: boolean;
  color?: string;
}>`
  font-size: ${({ theme }) => theme.typography.sizes.h1};
  font-weight: bold;
  margin: ${({ theme, noMargin }) => (noMargin ? 0 : theme.margin.large)};
  color: ${({ theme, color }) =>
    color
      ? color
      : theme.isDarkMode === TRUE
      ? `${theme.colors.system.white} !important`
      : theme.colors.system.black};
  align-self: flex-end;
`;

export const StyledH2 = styled.h2<{ color?: string }>`
  font-size: ${({ theme }) => theme.typography.sizes.h2};
  color: ${({ theme }) =>
    theme.isDarkMode === TRUE
      ? `${theme.colors.system.white} !important`
      : theme.colors.system.black};
  margin: 0;
  color: ${({ theme, color }) => color || theme.colors.system.white};
`;

export const StyledH3 = styled.h3`
  ${({ theme }) => theme.typography.header};
  color: ${({ theme }) =>
    theme.isDarkMode === TRUE
      ? `${theme.colors.system.white} !important`
      : theme.colors.system.black};
  font-size: ${({ theme }) => theme.typography.sizes.h3};
`;

export const StyledH4 = styled.h4`
  font-size: ${({ theme }) => theme.typography.sizes.h4};
  margin-bottom: ${({ theme }) => theme.margin.standard};
  color: ${({ theme }) =>
    theme.isDarkMode === TRUE
      ? theme.colors.general.white
      : theme.colors.system.offBlack};
`;

export const StyledH5 = styled.h5`
  ${({ theme }) => theme.typography.header};
  font-size: ${({ theme }) => theme.typography.sizes.h5};
  margin-bottom: ${({ theme }) => theme.margin.standard};
  color: ${({ theme }) =>
    theme.isDarkMode === TRUE
      ? theme.colors.general.white
      : theme.colors.system.offBlack};
`;

export const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.general.green};

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.general.green};
    opacity: 0.7;
  }
`;

export const StyledInput = styled(Input)<{
  width?: string;
  backgroundColor?: string;
}>`
  width: ${({ width }) => (width ? width : '365px')};
  max-width: 100%;

  > input {
    background-color: ${({ theme }) =>
      theme.isDarkMode === TRUE
        ? `${theme.colors.system.black} !important`
        : theme.colors.system.white} !important;

    &:focus {
      border-color: ${({ theme }) => theme.colors.general.blue};
      outline: none;
    }
  }
`;

export const StyledTextArea = styled(TextArea)<{
  width?: string;
  backgroundColor?: string;
}>`
  width: ${({ width }) => (width ? width : '365px')};
  max-width: 100%;

  background-color: ${({ theme }) =>
    theme.isDarkMode === TRUE
      ? `${theme.colors.system.black} !important`
      : theme.colors.system.white} !important;

  &:focus {
    border-color: ${({ theme }) => theme.colors.general.blue};
    outline: none;
  }
`;

export const ActionButton = styled(Button)<{
  backgroundColor?: string;
  textColor?: string;
  border?: boolean;
  disabled?: boolean;
}>`
  width: 200px;
  height: 40px;
  align-self: center;
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor || theme.colors.general.green} !important;
  color: ${({ theme, textColor }) =>
    textColor || theme.colors.general.black} !important;
  border: ${({ theme, border, textColor }) =>
    border
      ? textColor
        ? `2px solid ${textColor} !important`
        : `2px solid ${theme.colors.general.black} !important`
      : `none`};
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};

  &:hover {
    opacity: ${({ disabled }) => (disabled ? 0.4 : 0.7)};
  }
  &:active:not(:disabled) {
    opacity: ${({ disabled }) => (disabled ? 0.4 : 0.9)};
  }
`;

export const ActionCardContainer = styled(Card)<{
  borderColor?: string;
  backgroundColor?: string;
  height?: string;
}>`
  width: 100% !important;
  height: ${({ height }) => `${height || '250px'} !important`};
  border-bottom: ${({ theme, borderColor }) =>
    `5px solid ${borderColor || theme.colors.general.blue} !important`};
  box-shadow: ${({ theme }) => theme.isDarkMode === TRUE && `none !important`};
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor || theme.overlayComponents} !important;
  max-height: 500px !important;
`;

export const ActionCardContentContainer = styled(Card.Content)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.margin.xxlarge} !important;
  background-color: ${({ theme }) => `${theme.overlayComponents} !important`};
  height: 99%;
`;

export const GenericButton = styled.button<{
  width?: number;
  height?: number;
  backgroundColor?: string;
  color?: string;
  disabled?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: ${({ width }) => width || 144}px;
  height: ${({ height }) => height || 38}px;

  transition: background-color 0.25s, color 0.25s, opacity 0.25s;

  background-color: ${({ theme, secondary, backgroundColor }) =>
    secondary
      ? theme.colors.general.green
      : backgroundColor || theme.colors.general.yellow};

  color: ${({ theme, secondary, color }) =>
    secondary
      ? theme.colors.system.white
      : color || theme.colors.system.offBlack};

  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};

  border: none;
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.borders.small};

  cursor: ${({ disabled }) => (disabled ? 'no-drop' : 'pointer')};

  ${({ theme }) => theme.typography.header}
  font-size:  ${({ theme }) => theme.typography.sizes.h4};

  &:hover {
    opacity: ${({ disabled }) => (disabled ? 0.4 : 0.7)};
  }
  &:active:not(:disabled) {
    opacity: ${({ disabled }) => (disabled ? 0.4 : 0.9)};
  }

  > i {
    margin: 0;
    padding: 1px 0 0 0;
    font-size: ${({ theme }) => theme.typography.sizes.h3};
  }
`;

export const AppWrapper = styled.div`
  display: flex;
`;

export const AppContainer = styled.div`
  width: 100%;
  height: 100vh;
  margin-left: 50px;

  background-color: ${({ theme }) =>
    theme.isDarkMode === TRUE
      ? theme.colors.general.black
      : theme.colors.system.grey} !important;
`;

export const PageContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
`;

export const InnerPageContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
`;

export const PageBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  padding: ${({ theme }) =>
    `${theme.padding.xxxlarge} ${theme.padding.xlarge} ${theme.padding.xxxlarge} ${theme.padding.xxlarge}`};
  overflow-y: auto;
`;

export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  > h1 {
    font-size: 1.8em;
    margin: 0;
  }

  img {
    max-width: 180px;
  }
`;

export const PageFooterContainer = styled.div`
  position: absolute;
  bottom: ${({ theme }) => theme.padding.xlarge};
  right: ${({ theme }) => theme.padding.xxxlarge};
  display: flex;
  justify-self: flex-end;
  justify-content: flex-end;
`;

export const PageSearchHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: ${({ theme }) => theme.margin.large} 0;

  > p {
    margin: 0;
  }
`;

export const SpecificallyStyledDropdown = styled(Dropdown)`
  background-color: ${({ theme }) =>
    theme.isDarkMode === TRUE
      ? theme.overlayComponents
      : theme.colors.general.white} !important;
  border: ${({ theme }) =>
    theme.isDarkMode === TRUE
      ? `1px solid ${theme.colors.general.grey}`
      : `1px solid ${theme.colors.general.grey}`} !important;

  .divider.default.text {
    color: ${({ theme }) =>
      theme.isDarkMode === TRUE
        ? theme.colors.general.white
        : theme.colors.general.grey} !important;
  }

  .divider.text {
    color: ${({ theme }) =>
      theme.isDarkMode === TRUE
        ? theme.colors.general.white
        : theme.colors.system.darkOffBlack} !important;
  }

  .dropdown.icon {
    color: ${({ theme }) =>
      theme.isDarkMode === TRUE
        ? theme.colors.general.white
        : theme.colors.system.darkOffBlack} !important;
  }
`;

export const SpecificallyStyledInput = styled(StyledInput)`
  border-radius: 5px !important;

  background-color: ${({ theme }) =>
    theme.isDarkMode === TRUE
      ? theme.overlayComponents
      : theme.colors.general.white} !important;
  border: ${({ theme }) =>
    theme.isDarkMode === TRUE
      ? `1px solid ${theme.colors.general.grey}`
      : `1px solid ${theme.colors.general.grey}`} !important;

  > input {
    background-color: ${({ theme }) =>
      theme.isDarkMode === TRUE
        ? theme.overlayComponents
        : theme.colors.general.white} !important;
    border: ${({ theme }) =>
      theme.isDarkMode === TRUE
        ? `1px solid ${theme.colors.general.grey}`
        : '0'} !important;
    color: ${({ theme }) =>
      theme.isDarkMode === TRUE
        ? theme.colors.general.white
        : theme.colors.general.black} !important;
  }

  > input::placeholder {
    color: ${({ theme }) =>
      theme.isDarkMode === TRUE
        ? theme.colors.general.white
        : theme.colors.general.grey} !important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const SpecificallyStyledTextArea = styled(StyledTextArea)`
  width: 100%;
  padding: ${({ theme }) => theme.padding.standard};
  border-radius: 5px !important;

  background-color: ${({ theme }) =>
    theme.isDarkMode === TRUE
      ? theme.overlayComponents
      : theme.colors.general.white} !important;
  border: ${({ theme }) =>
    theme.isDarkMode === TRUE
      ? `1px solid ${theme.colors.general.grey}`
      : `1px solid ${theme.colors.general.grey}`} !important;

  color: ${({ theme }) =>
    theme.isDarkMode === TRUE
      ? theme.colors.general.white
      : theme.colors.general.black} !important;

  ::placeholder {
    color: ${({ theme }) =>
      theme.isDarkMode === TRUE
        ? theme.colors.general.white
        : theme.colors.general.grey} !important;
  }
`;

export const StyledParagraph = styled.p<{ fontSize?: string }>`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : `16px`)};
  color: ${({ theme }) => theme.colors.general.black};
`;

interface ThemeProps {
  theme?: string;
}

const Theme: FC<ThemeProps> = ({ children, theme }) => (
  <ThemeProvider
    theme={
      theme === DARK_THEME
        ? { ...defaultTheme, ...darkTheme }
        : { ...defaultTheme, ...lightTheme }
    }
  >
    <GlobalStyles />
    <GoogleFontLoader
      fonts={[
        { font: 'Gothic+A1', weights: [400] },
        { font: 'Gothic+A1', weights: [700] },
      ]}
    />
    {children}
  </ThemeProvider>
);

export default Theme;
