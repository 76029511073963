import { DateTime } from 'luxon';
import { useContext } from 'react';
import { useCSVDownloader } from 'react-papaparse';
import { ThemeContext } from 'styled-components';
import { ActionButton } from '../../main/theme';

export default function CSVDownloader(data) {
  const themeContext = useContext(ThemeContext);
  const { CSVDownloader } = useCSVDownloader();

  return (
    <CSVDownloader
      filename={`DiscoverExportData-${DateTime.now().toISO()}`}
      data={data.data}
    >
      <ActionButton
        backgroundColor={themeContext.colors.general.purple}
        textColor={themeContext.colors.general.white}
      >
        Download
      </ActionButton>
    </CSVDownloader>
  );
}
