import styled from 'styled-components';

export const ShortcutsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 2;
  overflow: hidden;
  gap: 30px;
  padding-top: 20px;

  > a {
    font-size: ${({ theme }) => theme.typography.sizes.small};
    padding: ${({ theme }) => theme.padding.xlarge} 0
      ${({ theme }) => theme.padding.standard} !important;
  }
`;

export const UserProfileContainer = styled.div`
  justify-self: flex-end;
  padding-bottom: ${({ theme }) => theme.padding.large};
`;

export const DarkModeToggleContainer = styled.div`
  padding-bottom: ${({ theme }) => theme.padding.xlarge};
`;
