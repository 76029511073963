import { FC, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { ThemeContext } from 'styled-components';
import {
  ActionButton,
  ActionCardContainer,
  ActionCardContentContainer,
  StyledH1,
} from '../../main/theme';
import { showModal } from '../../store/modal';
import { ModalTypes } from '../Modal/Modal';
import * as SC from './styled';

const EnrichLeadsCard: FC = () => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const green = themeContext.colors.general.green;
  const white = themeContext.colors.general.white;
  const black = themeContext.colors.general.black;

  return (
    <ActionCardContainer borderColor={green}>
      <ActionCardContentContainer>
        <SC.HeaderContainer>
          <StyledH1 color={black} noMargin={true}>
            Enrich Leads
          </StyledH1>
        </SC.HeaderContainer>
        <div>
          <p>
            Enrich your leads with custom scoring, revenue, size and key event
            data across hundreds of signals.
          </p>
        </div>
        <div>
          <p>Upload a CSV file of companies you wish to enrich.</p>
        </div>
        <SC.ButtonContainer>
          <ActionButton
            backgroundColor={green}
            textColor={white}
            onClick={() =>
              dispatch(
                showModal({
                  forceOpen: true,
                  visible: true,
                  modal: ModalTypes.ENRICH,
                }),
              )
            }
          >
            Enrich Leads
          </ActionButton>
        </SC.ButtonContainer>
      </ActionCardContentContainer>
    </ActionCardContainer>
  );
};

export default EnrichLeadsCard;
