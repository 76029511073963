import styled from 'styled-components';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const CardWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.margin.xxxlarge};
  margin-bottom: ${({ theme }) => theme.margin.xxxlarge};

  > div.ui.card {
    margin: 0;
  }
`;

export const StatsWrapper = styled.div`
  display: flex;
  gap: 100px;
  align-items: baseline;
`;
