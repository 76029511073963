export enum EventActionPastTense {
  ACQUIRES = 'acquires',
  ACQUIRED = 'acquired',

  EXPANDED = 'expanded',
  EXPANDS = 'expands',

  HIRES = 'hires',
  HIRED = 'hired',
  WILL_HIRE = 'will hire',

  INVESTS = 'invests',
  INVESTED = 'invested',
  INTEGRATES = 'integrates',
  INTEGRATED = 'integrated',

  LEAVES = 'leaves',
  LAUNCHES = 'launches',
  LAUNCHED = 'launched',
  WILL_LAUNCH = 'will launch',

  SIGNS = 'signs',
  SELLS = 'sells',
  SOLD_ASSETS = 'sold assets',

  PARTNERED = 'partnered',
  PARTNERS = 'partners',
  PROMOTES = 'promotes',

  RETIRES = 'retires',
  RETIRED = 'retired',
}
