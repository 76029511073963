import { Interfaces } from '@configur-tech/discover-core-types';
import HttpHelper from '../../utils/http-helper/HttpHelper';

export interface EntriesResponse {
  statusCode: number;
  message: string;
  data: {
    entries: Interfaces.Insights[];
    next: {
      hasMoreEntries: boolean;
      totalCount: number;
    };
  };
}

const INSIGHTS_ENDPOINT = process.env['REACT_APP_INSIGHTS_API'] || '';
const DOMAIN_FIELD_ID = '1cca93d6-ddca-4fe9-9e2a-e8205d0df1d1';
const DOMAIN_FIELD_ALIAS = 'Domain';
const INSIGHTS_HEADER_OBJECT = {
  'X-API-Key': process.env['REACT_APP_INSIGHTS_X_API_KEY'] || '',
};

export default class InsightsService {
  /**
   * @description - Method to get insights from configur API
   *
   * @param {string} token - Auth0 token
   * @param {string[]} [domains] - Domains to get insights for
   *
   * @returns {Promise<EntriesResponse>}
   */
  public static async getInsights(
    token: string,
    domains?: string[],
  ): Promise<EntriesResponse> {
    const ah = new HttpHelper(token);

    // filter by list of domains
    const filterQuery = [
      {
        operator: 'IN',
        field: DOMAIN_FIELD_ID,
        alias: DOMAIN_FIELD_ALIAS,
        datasetMetaId: process.env.REACT_APP_INSIGHTS_DATASET_META,
        value: {
          type: 'CONSTANT',
          value: domains,
        },
      },
    ];

    return ah.get<EntriesResponse>(
      INSIGHTS_ENDPOINT,
      {
        conditions: JSON.stringify(filterQuery),
      },
      INSIGHTS_HEADER_OBJECT,
    );
  }
}
