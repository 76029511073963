import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 350px;
  border-left: 1px solid ${({ theme }) => theme.colors.general.grey};
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) =>
    `${theme.padding.xxxlarge} ${theme.padding.xxxlarge} 0 ${theme.padding.xxxlarge}`};
`;

export const CompanyNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${({ theme }) =>
    `${theme.padding.xlarge} ${theme.padding.xxxlarge} 0 ${theme.padding.xxxlarge}`};
  gap: ${({ theme }) => theme.margin.standard};
`;

export const CompanyIndustryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${({ theme }) =>
    `${theme.padding.standard} ${theme.padding.xxxlarge} 0 ${theme.padding.xxxlarge}`};
  gap: ${({ theme }) => theme.margin.standard};
`;

export const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${({ theme }) =>
    `${theme.padding.standard} ${theme.padding.xxxlarge} 0 ${theme.padding.xxxlarge}`};
  gap: ${({ theme }) => theme.margin.standard};

  > div {
    width: 100%;
  }
`;

export const InsiderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${({ theme }) =>
    `${theme.padding.standard} ${theme.padding.xxxlarge} ${theme.padding.standard} ${theme.padding.xxxlarge}`};
  gap: ${({ theme }) => theme.margin.standard};

  > div {
    width: 100%;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  justify-self: flex-end;
  align-self: center;
  padding: ${({ theme }) =>
    `${theme.padding.standard} 0 ${theme.padding.xlarge}`};
  font-weight: bold;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  gap: ${({ theme }) => theme.margin.large};
  flex-grow: 2;
`;
