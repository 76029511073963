import { Dropdown } from 'semantic-ui-react';
import styled from 'styled-components';
import { TRUE } from '../../consts/projectConsts';

export const IconWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.margin.xxxlarge};
  margin-top: ${({ theme }) => theme.margin.standard};
`;

export const AquaContainer = styled.div`
  border-radius: ${({ theme }) => theme.borders.radius};
  padding: 0;
  color: white;
`;

export const ActivitySelectorHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${({ theme }) => `${theme.margin.standard} 0 0`};

  > h1 {
    align-self: center;
  }
`;

export const YearHeader = styled.div`
  > h1 {
    margin: ${({ theme }) => `${theme.margin.standard} 0 0`};
    color: ${({ theme }) => theme.colors.general.grey};
  }
`;

export const CompanyInformationWrapper = styled.div`
  background-color: ${({ theme }) =>
    theme.isDarkMode === TRUE
      ? theme.colors.general.black
      : theme.colors.general.white};
  border-left: 1px solid ${({ theme }) => theme.colors.general.grey};

  padding: ${({ theme }) =>
    `${theme.padding.xxxxlarge} ${theme.padding.xxlarge} ${theme.padding.xxlarge}`};
  align-items: center;

  height: 100vh;
  max-width: 20%;
  overflow-y: auto;
`;

export const CompanyInformationHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.margin.xlarge};

  margin-bottom: ${({ theme }) => theme.margin.xxlarge};

  > h2 {
    color: ${({ theme }) => theme.colors.general.black};
  }
`;

export const CompanyInformationLogo = styled.div<{ logo?: string }>`
  width: 100px;
  height: 100px;

  border-radius: 50%;
  border: ${({ theme }) => `1px solid ${theme.colors.general.grey}`};

  background-image: url(${({ logo }) => logo});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  margin-bottom: ${({ theme }) => theme.margin.standard};
`;

export const CompanyInformationDetailWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const StyledDropdown = styled(Dropdown)`
  width: 40%;
  height: 50%;
  background-color: ${({ theme }) =>
    theme.isDarkMode === TRUE
      ? theme.colors.general.black
      : theme.colors.general.white} !important;
  border: ${({ theme }) =>
    theme.isDarkMode === TRUE
      ? `1px solid ${theme.colors.general.grey}`
      : `1px solid ${theme.colors.general.grey}`} !important;

  .divider.default.text {
    color: ${({ theme }) =>
      theme.isDarkMode === TRUE
        ? theme.colors.general.white
        : theme.colors.system.darkGrey} !important;
  }

  .divider.text {
    color: ${({ theme }) =>
      theme.isDarkMode === TRUE
        ? theme.colors.general.white
        : theme.colors.system.offBlack} !important;
  }

  .dropdown.icon {
    color: ${({ theme }) =>
      theme.isDarkMode === TRUE
        ? theme.colors.general.white
        : theme.colors.system.darkOffBlack} !important;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
