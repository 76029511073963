import styled from 'styled-components';

export const Button = styled.button<{
  disabled?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
}>`
  min-width: 140px;
  height: 38px;

  transition: background-color 0.25s, color 0.25s, opacity 0.25s;
  font-family: 'Roboto', 'sans-serif';
  font-weight: bold;

  background-color: ${({ theme, disabled, secondary, tertiary }) =>
    disabled
      ? tertiary
        ? theme.colors.system.darkGrey
        : theme.colors.system.grey
      : secondary
      ? theme.colors.general.sea
      : theme.colors.general.yellow};

  color: ${({ theme, disabled, secondary, tertiary }) =>
    disabled
      ? tertiary
        ? theme.colors.system.grey
        : theme.colors.system.darkGrey
      : secondary
      ? theme.colors.system.white
      : theme.colors.system.offBlack};

  border: none;
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.borders.small};

  cursor: ${({ disabled }) => (disabled ? 'disabled' : 'pointer')};

  &:hover {
    opacity: ${({ disabled }) => (disabled ? 1 : 0.7)};
  }
`;

export const CsvReader = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.margin.standard};
`;

export const AcceptedFile = styled.div`
  border: 1px solid #ccc;
  height: 45;
  line-height: 2.5;
  padding-left: ${({ theme }) => theme.padding.standard};
  width: 365px;
  border-radius: ${({ theme }) => theme.borders.small};
`;
