import styled from 'styled-components';
import { TRUE } from '../../consts/projectConsts';

export const Overlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  width: 100%;
  height: 100%;
  box-shadow: ${({ theme }) => theme.isDarkMode === TRUE && `none !important`};

  background-color: ${({ theme }) => `${theme.colors.system.offBlack}CC`};
  z-index: 999;
`;

export const Wrapper = styled.div<{
  showModal: boolean;
  scrollable?: boolean;
  fullScreen?: boolean;
}>`
  display: flex;

  max-width: 95%;
  max-height: 95%;

  ${({ fullScreen }) =>
    fullScreen &&
    `width: 1400px;
    max-width: 95%;
    height: 2000px;
    max-height: 95%;
  `};

  overflow-y: ${({ scrollable }) => (scrollable ? 'auto' : 'visible')};

  background-color: ${({ theme }) =>
    theme.isDarkMode === TRUE
      ? theme.overlayComponents
      : theme.colors.system.pureWhite};
  padding: ${({ theme, fullScreen }) => !fullScreen && theme.padding.xxxlarge};

  border: ${({ theme }) =>
    theme.isDarkMode === TRUE ? `0` : `1px solid ${theme.colors.system.grey}`};
  border-radius: ${({ theme }) => theme.borders.small};
  box-shadow: ${({ theme }) => theme.isDarkMode === TRUE && `none !important`};
  opacity: ${({ showModal }) => (showModal ? 1 : 0)};

  transition: opacity 0.5s;
`;
