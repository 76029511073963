import styled from 'styled-components';

export const ActionButtonContainerWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.margin.xlarge};
`;

export const ActionButtonContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  gap: ${({ theme }) => theme.margin.standard};

  cursor: ${({ disabled }) => (disabled ? `0` : `pointer`)};

  &:hover {
    opacity: 0.7;
  }
`;

export const TableAndFooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
