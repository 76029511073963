import { Interfaces } from '@configur-tech/discover-core-types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import CompanyService, {
  FilterQuery,
} from '../../services/configur/CompanyService';
import { AppThunk } from '../store';

export interface SearchedCompaniesState {
  data: Interfaces.SearchEntry[];
  loading: boolean;
  error: string | null;
}

const initialState: SearchedCompaniesState = {
  data: [],
  loading: false,
  error: null,
};

const SearchSlice = createSlice({
  name: 'searches',
  initialState,
  reducers: {
    // Fetch searched
    fetchSearchesStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchSearchesSuccess(
      state,
      action: PayloadAction<Interfaces.SearchEntry[]>,
    ) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchSearchesFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    // Create search
    createSearchStart(state) {
      state.loading = true;
      state.error = null;
    },
    createSearchSuccess(
      state,
      action: PayloadAction<Interfaces.SearchEntry[]>,
    ) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    createSearchFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    // Update searched
    updateSearchStart(state) {
      state.loading = true;
      state.error = null;
    },
    updateSearchSuccess(
      state,
      action: PayloadAction<Interfaces.SearchEntry[]>,
    ) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    updateSearchFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchSearchesStart,
  fetchSearchesSuccess,
  fetchSearchesFailure,
  createSearchStart,
  createSearchSuccess,
  createSearchFailure,
  updateSearchStart,
  updateSearchSuccess,
  updateSearchFailure,
} = SearchSlice.actions;

export default SearchSlice.reducer;

const processEntries = (entries) => {
  return entries.map((ent) => {
    let boosters;
    try {
      boosters = JSON.parse(ent.boosters as string);
    } catch (err) {
      boosters = [];
    }

    return {
      ...ent,
      tags: (ent.tags as string).split(', '),
      domain: (ent.domain as string).split(', '),
      boosters,
    };
  });
};

export const fetchSearches =
  (token: string, queryParams?: FilterQuery[], encoded?: boolean): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchSearchesStart());
      const fetched = await CompanyService.getSearches(
        token,
        queryParams,
        encoded,
      );

      dispatch(fetchSearchesSuccess(processEntries(fetched.data.entries)));
    } catch (error) {
      const err = error as string;
      dispatch(fetchSearchesFailure(err.toString()));
    }
  };

export const createSearch =
  (token: string, companyData): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createSearchStart());
      const created = await CompanyService.postSearch(token, companyData);
      dispatch(createSearchSuccess(processEntries(created.data.entries)));
    } catch (error) {
      const err = error as string;
      dispatch(createSearchFailure(err.toString()));
    }
  };

export const updateSearch =
  (token: string, companyData): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updateSearchStart());
      const updated = await CompanyService.putSearch(token, companyData);
      dispatch(updateSearchSuccess(processEntries([updated.data.entry])));
    } catch (error) {
      const err = error as string;
      dispatch(updateSearchFailure(err.toString()));
    }
  };
