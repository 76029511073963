import { toast } from 'react-toastify';
import { ToastOptions } from 'react-toastify/dist/types';

export const DEFAULT_TOAST_LIMIT = 3;

const DEFAULT_OPTIONS = {
  type: toast.TYPE.SUCCESS,
  autoClose: 2500,
  pauseOnFocusLoss: false,
};

interface ToastResult {
  notifyToast: (notification: string, options?: ToastOptions) => void;
}

const useToast = (): ToastResult => {
  const notifyToast = (notification: string, options?: ToastOptions) => {
    toast(notification, { ...DEFAULT_OPTIONS, ...options });
  };

  return {
    notifyToast,
  };
};

export default useToast;
