import { Interfaces } from '@configur-tech/discover-core-types';
import { FC, useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { usePagination, useSortBy, useTable } from 'react-table';
import { Icon } from 'semantic-ui-react';
import { ThemeContext } from 'styled-components';
import { DARK_THEME } from '../../consts/projectConsts';
import { RouteName } from '../../enums/RouteName';
import { StyledText } from '../../main/theme';
import { getLocalTheme } from '../../utils/dark-theme/getLocalTheme';
import { IconGenerator } from '../../utils/scores/ScoringHelper';
import * as SC from './styled';

interface ColumnProps {
  id?: string;
  Header: string;
  accessor: ((row) => number) | string;
}

interface TableProps {
  columns?: ColumnProps[];
  data?: Interfaces.Company[] | Interfaces.SearchEntry[];
  defaultPageSize?: number;
  sortBy?: {
    id: string;
    desc: boolean;
  }[];
  hiddenColumns?: string | string[];
  showNavButtons?: boolean;
  routeName?: string;
  wrapperHeight?: string;
  pagination: number;
  paginationAction?: (page: number) => void;
  noResultsMessage?: string;
  controlledPageCount?: number;
  isBoostingTech?: boolean;
  isBoostingFinance?: boolean;
  filtersOpen?: boolean;
}

const Table: FC<TableProps> = ({
  columns,
  data,
  defaultPageSize = 20,
  sortBy,
  hiddenColumns,
  showNavButtons = true,
  routeName,
  wrapperHeight,
  pagination,
  paginationAction,
  noResultsMessage,
  controlledPageCount,
  isBoostingTech,
  isBoostingFinance,
  filtersOpen,
}) => {
  const initialState = hiddenColumns
    ? {
        hiddenColumns: hiddenColumns,
        pageSize: defaultPageSize,
        sortBy: sortBy,
        pageIndex: pagination,
      }
    : {
        pageSize: defaultPageSize,
        sortBy: sortBy,
        pageIndex: pagination,
      };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      pageCount: controlledPageCount,
      initialState,
      autoResetPage: false,
    },
    useSortBy,
    usePagination,
  );

  const history = useHistory();
  const params = useParams();
  const themeContext = useContext(ThemeContext);

  const constructPageButtonsAmount = Math.ceil(pageCount / 20);

  // Fetch new data
  useEffect(() => {
    if (paginationAction) {
      paginationAction(pageIndex);
    }
  }, [paginationAction, pageIndex]);

  const routeChange = (route, entryId) => {
    if (route === RouteName.SEARCH_COMPANY && params.searchId) {
      return history.push(`${route}/${params.searchId}/${entryId}`);
    }

    history.push(`${route}/${entryId}`);
  };

  if (!page.length) {
    return (
      <SC.NoResultsWrapper>
        <StyledText>{noResultsMessage || 'No data to show'}</StyledText>
      </SC.NoResultsWrapper>
    );
  }

  const localTheme = getLocalTheme();

  return (
    <>
      <SC.Outer height={wrapperHeight}>
        <SC.Wrapper>
          <SC.Table {...getTableProps()}>
            <tbody style={{ border: 0 }} {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <>
                    <SC.TableRow
                      isClickable={true}
                      onClick={() =>
                        routeChange(
                          routeName,
                          row.values.id || row.values.domain,
                        )
                      }
                      {...row.getRowProps()}
                    >
                      {row.cells.map((cell) => {
                        return (
                          <SC.TableCell {...cell.getCellProps()}>
                            {cell.column.id === 'companyLogo' && (
                              <SC.LogoCell image={cell.value} />
                            )}

                            {cell.column.id === 'companyName' && (
                              <b>{cell.render('Cell')}</b>
                            )}

                            {(cell.column.id === 'tags' ||
                              cell.column.id === 'industries') && (
                              <SC.TagCell>
                                {cell.value.split(',').slice(0, 2).join(', ')}
                              </SC.TagCell>
                            )}

                            {cell.column.id !== 'tags' &&
                              cell.column.id !== 'industries' &&
                              cell.column.id !== 'companyLogo' &&
                              cell.column.id !== 'companyName' &&
                              (typeof cell.value === 'number' ||
                              cell.column.id === 'financeScore' ||
                              cell.column.id === 'insightsScore' ? (
                                <SC.IconCell>
                                  {IconGenerator(cell.column.id, cell.value)}
                                </SC.IconCell>
                              ) : (
                                <>{cell.render('Cell')}</>
                              ))}
                          </SC.TableCell>
                        );
                      })}
                    </SC.TableRow>

                    <td
                      height={'10px'}
                      colSpan={11}
                      style={{
                        backgroundColor:
                          localTheme === DARK_THEME
                            ? themeContext.colors.general.black
                            : themeContext.colors.general.offWhite,
                      }}
                    ></td>
                  </>
                );
              })}
            </tbody>
          </SC.Table>
        </SC.Wrapper>
      </SC.Outer>

      {showNavButtons && (
        <SC.PaginationWrapper>
          <p>
            Showing{' '}
            <strong>
              {page.length < pageSize
                ? Math.max(0, pagination - 1) * defaultPageSize + 1
                : Math.max(0, pagination - 1) * defaultPageSize + 1}
            </strong>{' '}
            -{' '}
            <strong>
              {page.length < pageSize
                ? Math.max(0, pagination - 1) * defaultPageSize + page.length
                : Math.max(0, pagination - 1) * defaultPageSize +
                  defaultPageSize}
            </strong>{' '}
            from{' '}
            <strong>
              {(pageCount ? pageCount : data?.length || 0).toLocaleString()}
            </strong>{' '}
            results
          </p>
          <SC.NavButtonsWrapper>
            <SC.Button
              disabled={pagination === 1 || !pagination}
              onClick={() => gotoPage(1)}
              style={{
                color:
                  pagination === 1 || !pagination
                    ? themeContext.colors.general.grey
                    : themeContext.colors.general.green,
              }}
            >
              <Icon name="angle double left" />
            </SC.Button>
            <SC.Button
              onClick={() => previousPage()}
              disabled={!canPreviousPage || pagination === 1}
            >
              <Icon
                name="angle left"
                style={{
                  color:
                    !canPreviousPage || pagination === 1
                      ? themeContext.colors.general.grey
                      : themeContext.colors.general.green,
                }}
              />
            </SC.Button>
            <SC.Button
              onClick={() => nextPage()}
              disabled={
                !canNextPage || constructPageButtonsAmount === pagination
              }
            >
              <Icon
                name="angle right"
                style={{ color: themeContext.colors.general.green }}
              />
            </SC.Button>
            <SC.Button
              onClick={() => gotoPage(constructPageButtonsAmount)}
              disabled={
                !canNextPage || constructPageButtonsAmount === pagination
              }
            >
              <Icon
                name="angle double right"
                style={{ color: themeContext.colors.general.green }}
              />
            </SC.Button>
          </SC.NavButtonsWrapper>
        </SC.PaginationWrapper>
      )}
    </>
  );
};

export default Table;
