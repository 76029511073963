import { FC } from 'react';
import * as TC from '../../main/theme';

const PageFooter: FC = () => {
  return (
    <TC.StyledFooterText>
      Copyright{' '}
      <TC.StyledLink href={'https://weareinterlink.com'}>
        Interlink
      </TC.StyledLink>{' '}
      2024 -{' '}
      <TC.StyledLink href={'https://weareinterlink.com/privacy-policy/'}>
        Privacy Policy
      </TC.StyledLink>
    </TC.StyledFooterText>
  );
};

export default PageFooter;
