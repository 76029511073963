import { ReactElement } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import Auth0ProviderWithHistory from '../auth/auth0-provider-with-history';
import { useDarkMode } from '../hooks/dark-mode/useDarkMode';
import store from '../store/store';
import AppContainer from './AppContainer';
import Fonts from './fonts';
import Theme from './theme';

function App(): ReactElement {
  const [theme, themeToggler] = useDarkMode();

  return (
    <Provider store={store}>
      <Router>
        <Auth0ProviderWithHistory>
          <Fonts />
          <Theme theme={theme as string}>
            <AppContainer themeToggler={themeToggler as () => void} />
          </Theme>
        </Auth0ProviderWithHistory>
      </Router>
    </Provider>
  );
}

export default App;
