import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingProps } from '../../../components/Loading/BackgroundScoresLoading';
import InitialLoading from '../initial-state';

const RecentEventsComponentLoadingSlice = createSlice({
  name: 'recentEventsComponentLoading',
  initialState: InitialLoading,
  reducers: {
    showRecentEventsComponentLoading(
      state,
      action: PayloadAction<LoadingProps>,
    ) {
      state.loading = true;
      state.text = action?.payload.text;
    },
    hideRecentEventsComponentLoading(state) {
      state.loading = false;
      state.text = undefined;
    },
  },
});

export const {
  showRecentEventsComponentLoading,
  hideRecentEventsComponentLoading,
} = RecentEventsComponentLoadingSlice.actions;

export default RecentEventsComponentLoadingSlice.reducer;
