import { useAuth0 } from '@auth0/auth0-react';
import { Enums, Interfaces } from '@configur-tech/discover-core-types';
import { useState } from 'react';
import { ExportFormat } from '../../interfaces/ExportFormat';
import CompanyService from '../../services/configur/CompanyService';
import {
  COMPANY_DOMAIN_ALIAS,
  COMPANY_DOMAIN_FIELD,
  EVENT_CATEGORY_ALIAS,
  EVENT_CATEGORY_FIELD,
  EVENT_DOMAIN_ALIAS,
  EVENT_DOMAIN_FIELD,
} from '../../consts/apiConsts';
import InsightsService from '../../services/configur/InsightsService';
import { defaultFormatExportDataForCompany } from '../../utils/exportFormatters/defaultFormatter';
import { veeamFormatExportDataForCompany } from '../../utils/exportFormatters/veeamFormatter';
import EventService, {
  EVENT_REQUEST_LIMIT,
} from '../../services/configur/EventService';
import { lessThanAYearAgo } from '../../utils/dates/DateFilter';

interface useExportResult {
  // Vars
  processingTotal: number;
  processingCount: number;

  // Methods
  getCompaniesExportData: (
    searchId: string,
    domains: string[][],
    domainIndex: number,
    eventTypes?: string[],
    totalEventLimit?: number,
    eventTypeLimit?: string,
    clientType?: Enums.ClientTypes,
  ) => Promise<ExportFormat[]>;
}

const COMPANY_REQUEST_LIMIT = 50;
const INSIGHTS_REQUEST_LIMIT = 50;

const timeout = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const cleanDomain = (domain: string): string => {
  return domain
    .replace('http://', '')
    .replace('https://', '')
    .replace('/', '')
    .replace('www.', '')
    .replace(/^/, 'www.');
};

const useExport = (): useExportResult => {
  const { getAccessTokenSilently } = useAuth0();

  const [processingTotal, setProcessingTotal] = useState<number>(1);
  const [processingCount, setProcessingCount] = useState<number>(0);

  /**
   * @description - Builds an array of Interfaces.Company[] split into pages limited by REQUEST_LIMIT
   *
   * @param {string} searchId - SearchId to use
   * @param {string[][]} rows - Rows from uploaded export file
   * @param {number} domainIndex - Index of the domain column to use
   *
   * @returns {Promise<Interfaces.Company[][]>} - Array containing paged arrays of Interfaces.Company
   */
  const getPagedCompanies = async (
    searchId: string,
    rows: string[][],
    domainIndex: number,
  ): Promise<Interfaces.Company[][]> => {
    const token = await getAccessTokenSilently();

    const rowsLength = rows.length;
    const pageNumbers = Math.ceil(rowsLength / COMPANY_REQUEST_LIMIT);

    return await Array.from(Array(pageNumbers)).reduce(
      async (acc, item, index) => {
        const accRes = await acc;

        const pageRows = rows.slice(
          COMPANY_REQUEST_LIMIT * index,
          (index + 1) * COMPANY_REQUEST_LIMIT,
        );

        const pageDomains = pageRows
          .filter((row) => row[domainIndex] !== '')
          .map((row) => cleanDomain(row[domainIndex]));

        const filterQuery = [
          {
            operator: 'IN',
            field: COMPANY_DOMAIN_FIELD,
            alias: COMPANY_DOMAIN_ALIAS,
            datasetMetaId: process.env.REACT_APP_COMPANIES_DATASET_META,
            value: {
              type: 'CONSTANT',
              value: pageDomains,
            },
          },
        ];

        let companiesRes;

        try {
          companiesRes = await CompanyService.getCompanies(
            token,
            JSON.stringify(filterQuery),
            true,
            undefined,
            undefined,
            true,
          );
        } catch (error) {
          return accRes;
        }

        // Filter out duplicates
        const filteredCompanies = companiesRes.data.entries.reduce(
          (acc: Interfaces.Company[], company) => {
            // Check if domain already exists in acc
            const existingDomainIndex = acc.findIndex(
              (c) => c.domain === company.domain,
            );

            // New domain
            if (existingDomainIndex === -1) {
              return acc.concat(company);
            }

            // Exists, check which is latest document - replace if required
            const existingDomain = acc[existingDomainIndex];
            if (
              company.row_id &&
              existingDomain?.row_id &&
              company.row_id > existingDomain.row_id
            ) {
              acc.splice(existingDomainIndex, 1, company);
            }

            return acc;
          },
          [],
        );

        // Ensure order is maintained and missing companies have default entries inserted
        const orderedCompanies = pageRows.map((row) => {
          const fetchedCompany = filteredCompanies.find(
            (c) => c.domain === cleanDomain(row[domainIndex]),
          );

          return (
            fetchedCompany || {
              domain: row[domainIndex] || '',
              description: '',
              eventIds: '',
            }
          );
        });

        return [...accRes, orderedCompanies];
      },
      Promise.resolve([]),
    );
  };

  /**
   * @description - Fetched events for all companies provided in one API call
   *
   * @param {Interfaces.Company[]} companies - Companies to fetch events for
   * @param {string[]} [eventTypes] - Event types to filter
   *
   * @returns {Promise<Interfaces.Events[]>} - Fetched events
   */
  const getEventsForCompanies = async (
    companies: Interfaces.Company[],
    eventTypes?: string[],
  ): Promise<Interfaces.Events[]> => {
    const token = await getAccessTokenSilently();

    // Get all domains for provided companies
    const domains = companies.reduce((acc: string[], singleCompany) => {
      const cleaned = singleCompany.domain
        ?.replace(/(https?:\/\/)|(www.)/gi, '')
        .split('/')[0];

      const httpsCleaned = cleaned?.replace(/^/, 'https://www.');
      const httpCleaned = cleaned?.replace(/^/, 'http://www.');
      const wwwCleaned = cleaned?.replace(/^/, 'www.');

      return acc.concat(
        singleCompany.domain as string,
        cleaned as string,
        httpsCleaned as string,
        httpCleaned as string,
        wwwCleaned as string,
      );
    }, []);

    // Create pages
    const rowsLength = domains.length;
    const pageNumbers = Math.ceil(rowsLength / EVENT_REQUEST_LIMIT);

    // Paginate through events
    const eventArray = await Array.from(Array(pageNumbers)).reduce(
      async (acc, item, index) => {
        const accRes = await acc;

        // Add artificial timeout to help lambdas reload
        await timeout(index * 250);

        const domainsToGrab = domains.slice(
          EVENT_REQUEST_LIMIT * index,
          (index + 1) * EVENT_REQUEST_LIMIT,
        );

        const filterQuery = [
          {
            operator: 'IN',
            field: EVENT_DOMAIN_FIELD,
            alias: EVENT_DOMAIN_ALIAS,
            datasetMetaId: process.env.REACT_APP_EVENTS_DATASET_META,
            value: {
              type: 'CONSTANT',
              value: domainsToGrab,
            },
          },
        ];

        try {
          let eventsRes;

          if (eventTypes) {
            const filterQueryWithEvents = [
              {
                operator: 'AND',
                value: filterQuery.concat({
                  operator: 'IN',
                  field: EVENT_CATEGORY_FIELD,
                  alias: EVENT_CATEGORY_ALIAS,
                  datasetMetaId: process.env.REACT_APP_EVENTS_DATASET_META,
                  value: { type: 'CONSTANT', value: eventTypes },
                }),
              },
            ];

            // Filter by types of event
            eventsRes = await EventService.getAllEvents(
              token,
              JSON.stringify(filterQueryWithEvents),
              true,
            );
          } else {
            // Dont filter by events
            eventsRes = await EventService.getAllEvents(
              token,
              JSON.stringify(filterQuery),
              true,
            );
          }

          // Filter out duplicates
          return accRes.concat(
            eventsRes.reduce((acc: Interfaces.Events[], event) => {
              const exists = acc.find((e) => e.id === event.id);

              if (exists) {
                return acc;
              }

              return [...acc, event];
            }, []),
          );
        } catch (err) {
          return accRes;
        }
      },
      Promise.resolve([]),
    );

    return lessThanAYearAgo(eventArray);
  };

  /**
   * @description - Fetched insights for all companies provided in one API call
   *
   * @param {Interfaces.Company[]} companies - Companies to fetch insights for
   *
   * @returns {Promise<Interfaces.Insights[]>} - Fetched insights
   */
  const getInsightsForCompanies = async (
    companies: Interfaces.Company[],
  ): Promise<Interfaces.Insights[]> => {
    const token = await getAccessTokenSilently();

    // Get all domains for provided companies
    const companyDomains = companies.map((company) =>
      company.domain?.replace('www.', ''),
    );

    // Create pages
    const rowsLength = companyDomains.length;
    const pageNumbers = Math.ceil(rowsLength / INSIGHTS_REQUEST_LIMIT);

    // Paginate through insights
    return await Array.from(Array(pageNumbers)).reduce(
      async (acc, item, index) => {
        const accRes = await acc;

        // Add artificial timeout to help lambdas reload
        await timeout(index * 250);

        const companyDomainsSliced = companyDomains.slice(
          INSIGHTS_REQUEST_LIMIT * index,
          (index + 1) * INSIGHTS_REQUEST_LIMIT,
        );

        try {
          const insightsRes = await InsightsService.getInsights(
            token,
            companyDomainsSliced as string[],
          );

          // Filter out duplicates
          return accRes.concat(
            insightsRes.data.entries.reduce(
              (acc: Interfaces.Insights[], insight) => {
                const exists = acc.find((e) => e.Domain === insight.Domain);

                if (exists) {
                  return acc;
                }

                return [...acc, insight];
              },
              [],
            ),
          );
        } catch (err) {
          return accRes;
        }
      },
      Promise.resolve([]),
    );
  };

  /**
   * @description - Builds export data for an array of domains
   *
   * @param {string} searchId - SearchID of search doc to export
   * @param {string[][]} rows - Rows from uploaded export file
   * @param {number} domainIndex - Index of the domain column to use
   * @param {string[]} [eventTypes] - Event types to filter
   * @param {number} [totalEventLimit] - Limit of the total events returned
   * @param {string} [eventTypeLimit] - Limit of the types of event per company
   * @param {Enums.ClientTypes} [clientType] - Client type to format export in
   *
   * @returns {Promise<ExportFormat[]>} - Data formatted ready for export
   */
  const getCompaniesExportData = async (
    searchId: string,
    rows: string[][],
    domainIndex: number,
    eventTypes?: string[],
    totalEventLimit?: number,
    eventTypeLimit?: string,
    clientType?: Enums.ClientTypes,
  ): Promise<ExportFormat[]> => {
    // Set total processing count
    setProcessingTotal(rows.length);

    // Get paged array of Companies
    const pagedCompanies = await getPagedCompanies(searchId, rows, domainIndex);

    let exportData: ExportFormat[] = [];

    // Process page
    for (const [pageIndex, page] of pagedCompanies.entries()) {
      // Get company events for page
      const events = await getEventsForCompanies(
        page,
        eventTypes?.length ? eventTypes : undefined,
      );

      const insights = await getInsightsForCompanies(page);

      // Build export data
      const processedPage = await page.reduce(
        async (acc: Promise<ExportFormat[]>, company, i) => {
          const accRes = await acc;

          // Update processingCount
          setProcessingCount(pageIndex * COMPANY_REQUEST_LIMIT + (i + 1));

          // Isolate company events
          const companyEvents = events.filter((event) => {
            const cleaned = company.domain
              ?.replace(/(https?:\/\/)|(www.)/gi, '')
              .split('/')[0];

            const httpsCleaned = cleaned?.replace(/^/, 'https://www.');
            const httpCleaned = cleaned?.replace(/^/, 'http://www.');
            const wwwCleaned = cleaned?.replace(/^/, 'www.');
            return (
              event.domain === company.domain?.replace('www.', '') ||
              event.domain === company.domain ||
              event.domain === httpsCleaned ||
              event.domain === httpCleaned ||
              event.domain === wwwCleaned
            );
          });

          // Isolate this company's insights
          const insightEvents = insights.filter(
            (insight) =>
              company.domain?.replace('www.', '') === insight.Domain ||
              company.domain === insight.Domain,
          );

          // Shuffling algorithm
          const shuffleArray = (array) => {
            let i = array.length;
            while (--i > 0) {
              const randIndex = Math.floor(Math.random() * (i + 1));
              [array[randIndex], array[i]] = [array[i], array[randIndex]];
            }

            return array;
          };

          // Shuffle to pick random insight to export
          const shuffledInsights = shuffleArray(insightEvents);

          let formattedCompanyData;

          switch (clientType) {
            case Enums.ClientTypes.DEFAULT:
              formattedCompanyData = await defaultFormatExportDataForCompany(
                company,
                companyEvents,
                shuffledInsights[0],
                totalEventLimit ? totalEventLimit : undefined,
                eventTypeLimit ? eventTypeLimit : undefined,
              );
              break;
            case Enums.ClientTypes.VEEAM:
              formattedCompanyData = await veeamFormatExportDataForCompany(
                company,
                companyEvents,
                shuffledInsights[0],
                totalEventLimit ? totalEventLimit : undefined,
                eventTypeLimit ? eventTypeLimit : undefined,
              );
          }

          return [...accRes, formattedCompanyData];
        },
        Promise.resolve([]),
      );

      exportData = exportData.concat(processedPage);
    }

    return exportData;
  };

  return {
    // Vars
    processingTotal,
    processingCount,

    // Methods
    getCompaniesExportData,
  };
};

export default useExport;
