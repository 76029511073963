import { useAuth0 } from '@auth0/auth0-react';
import { Interfaces } from '@configur-tech/discover-core-types';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FilterQuery } from '../../services/configur/CompanyService';
import { RootState } from '../../store/rootReducer';
import {
  createSearch,
  fetchSearches,
  updateSearch,
} from '../../store/searches';

interface useSearchResult {
  // Vars
  search?: Interfaces.SearchEntry;
  searches: Interfaces.SearchEntry[];

  // Methods
  getSearches: (query?: FilterQuery[], encoded?: boolean) => void;
  setUpdateSearch: (updateData: Interfaces.SearchEntry) => void;
  sendCreateSearch: (createData: Interfaces.SearchEntry) => void;
}

const useSearch = (): useSearchResult => {
  const dispatch = useDispatch();
  const { searchId } = useParams();
  const { getAccessTokenSilently } = useAuth0();

  const [search, setSearch] = useState<Interfaces.SearchEntry>();
  const [searches, setSearches] = useState<Interfaces.SearchEntry[]>([]);
  const searchState = useSelector((state: RootState) => state.searches);
  const searchStateEntries: Interfaces.SearchEntry[] = searchState.data;

  const getSearches = useCallback(
    async (queryRequest?: FilterQuery[], encoded?: boolean) => {
      const token = await getAccessTokenSilently();

      if (token) {
        await dispatch(fetchSearches(token, queryRequest, encoded));
      }
    },
    [dispatch, getAccessTokenSilently],
  );

  const setUpdateSearch = async (updateData: Interfaces.SearchEntry) => {
    const token = await getAccessTokenSilently();

    if (token) {
      await dispatch(updateSearch(token, updateData));
    }
  };

  const sendCreateSearch = async (createData: Interfaces.SearchEntry) => {
    const token = await getAccessTokenSilently();

    if (token) {
      await dispatch(createSearch(token, { entries: [createData] }));
    }
  };

  useEffect(() => {
    // Set current search
    if (searchId && searches.length) {
      const foundSearch = searches.find((s) => s.id === searchId);
      setSearch(foundSearch);
    }
    setSearches(searchStateEntries);
  }, [searchId, searchStateEntries, searches]);

  return {
    // Vars
    search,
    searches,

    // Methods
    getSearches,
    setUpdateSearch,
    sendCreateSearch,
  };
};

export default useSearch;
