import { Interfaces } from '@configur-tech/discover-core-types';
import { DateTime } from 'luxon';

/**
 * @desc - Filter down array of events to last twelve months
 *
 * @param {Interfaces.Events[]} dataArray - Array of events coming through
 *
 * @returns {Interfaces.Events[]}
 */
export const lessThanAYearAgo = (
  dataArray: Interfaces.Events[],
): Interfaces.Events[] => {
  // Filter down events to only the past 12 months
  const filteredArray = dataArray.filter((acquiredData) => {
    if (acquiredData) {
      const monthTimeRange = DateTime.now()
        .diff(DateTime.fromISO(acquiredData.eventDate), 'months')
        .toObject().months;

      if (monthTimeRange < 12) {
        return true;
      }
    }
  });

  return filteredArray.sort(
    (eventA, eventB) =>
      (DateTime.now()
        .diff(DateTime.fromISO(eventA.eventDate), 'months')
        .toObject().months as number) -
      (DateTime.now()
        .diff(DateTime.fromISO(eventB.eventDate), 'months')
        .toObject().months as number),
  );
};
