import styled from 'styled-components';

export const UserAvatar = styled.div<{ avatar?: string }>`
  width: 40px;
  height: 40px;
  margin-left: 5px;

  border-radius: 50%;

  background-image: url(${({ avatar }) => avatar});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;
