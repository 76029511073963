export enum EventGroup {
  DISCOVER_INSIDER = 'discover_insider',
  LEADERSHIP = 'leadership',
  ACQUISITION = 'acquisition',
  EXPANSION = 'expansion',
  NEW_OFFERING = 'new_offering',
  INVESTMENT = 'investment',
  COST_CUTTING = 'cost_cutting',
  PARTNERSHIP = 'partnership',
  RECOGNITION = 'recognition',
  CONTRACT = 'contract',
  CORPORATE_CHALLENGES = 'corporate_challenges',
  RELATIONAL = 'relational',
}
