import { FC } from 'react';
import * as SC from './styled';
import discoverLogo from '../../assets/icons/system/DiscoverLogoSvg.svg';
import { ActionButton } from '../../main/theme';

const NotFoundPage: FC = () => {
  return (
    <SC.PageContainer>
      <SC.ItemContainer>
        <SC.SpecificallyStyledH1>
          Whoops, we've{' '}
          <b style={{ textDecoration: 'underline' }}>discovered</b> something
          wrong...
        </SC.SpecificallyStyledH1>
      </SC.ItemContainer>
      <SC.LogoContainer src={discoverLogo} width={400} />
      <h1>
        Submit a bug report{' '}
        <a
          href="https://form.configurcodex.com/622fb19931b28b751c22f13c/62305e6bb0b691ec5af2e5fa"
          target={'_blank'}
        >
          here
        </a>{' '}
        so we can get right on it
      </h1>
      <h1>
        Or you can go straight back to the dashboard by clicking the button
        below
      </h1>
      <ActionButton>
        <SC.StyledLink to="/">Back to dashboard</SC.StyledLink>
      </ActionButton>
    </SC.PageContainer>
  );
};

export default NotFoundPage;
