import { Enums } from '@configur-tech/discover-core-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-duotone-svg-icons';

import { startCase } from 'lodash';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { DropdownItemProps } from 'semantic-ui-react';
import { ThemeContext } from 'styled-components';
import { EventCategories } from '../../../enums/EventCategories';
import { ExportColumn } from '../../../enums/ExportColumn';
import useExport from '../../../hooks/events/useExport';
import { EnrichExportResult } from '../../../interfaces/EnrichExportResult';
import { ExportFormat } from '../../../interfaces/ExportFormat';
import {
  ActionButton,
  SpecificallyStyledDropdown,
  SpecificallyStyledInput,
  StyledParagraph,
} from '../../../main/theme';
import { hideModal } from '../../../store/modal';
import CSVDownloader from '../../CSVDownloader/CSVDownloader';
import CSVReader from '../../CSVReader/CSVReader';
import * as SC from './styled';
import { getLocalTheme } from '../../../utils/dark-theme/getLocalTheme';
import { DefaultExportResult } from '../../../interfaces/DefaultExportResult';

const EXTRA_LARGE = 'xl';
const NATURAL_WIDTH = '220px';

export interface EnrichModalProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const EnrichModal: FC<EnrichModalProps> = ({ setShowModal }) => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const location = useLocation();
  const searchId = location.pathname.split('/search-results/')?.[1];

  const { getCompaniesExportData, processingCount, processingTotal } =
    useExport();

  const [dataToExport, setDataToExport] = useState<
    ExportFormat[] | string[][]
  >();
  const [headers, setHeaders] = useState<{
    domains?: string;
    description?: string;
    industry?: string;
    revenue?: string;
    employeeCount?: string;
    events?: string;
  }>({
    domains: '',
    description: '',
    revenue: '',
    employeeCount: '',
    events: '',
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [headersArray, setHeadersArray] = useState<string[]>([]);
  const [isMutated, setIsMutated] = useState<boolean>(false);
  const [canExport, setCanExport] = useState<boolean>(false);
  const [eventTypes, setEventTypes] = useState<string[]>([]);
  const [totalEventLimit, setTotalEventLimit] = useState<number>(5); // default to 5 events per company
  const [eventTypeLimit, setEventTypeLimit] = useState<number | string>(0); // default to 0
  const [clientType, setClientType] = useState<Enums.ClientTypes>(
    Enums.ClientTypes.DEFAULT,
  );

  const calculateDropdownOptions = () => {
    return headersArray.map((header) => {
      return {
        key: header,
        text: header,
        value: header,
      };
    });
  };

  const updateEventValue = (value) => {
    setEventTypes(value);
  };

  const updateTotalEventLimitValue = (value) => {
    setTotalEventLimit(value);
  };

  const updateEventTypeLimitValue = (value) => {
    setEventTypeLimit(value);
  };

  const updateClientTypeValue = (value) => {
    setClientType(value);
  };

  const calculateDropdownOptionsFromEnums = (enumValues) => {
    const enumArray: string[] = Object.values(enumValues) as string[];

    return enumArray.map((enumValue: string) => {
      return {
        key: enumValue,
        text: startCase(enumValue.toLowerCase()),
        value: enumValue,
      } as DropdownItemProps;
    });
  };

  const onDropdownSelection = (column: ExportColumn, value: string) => {
    // Remove value from other columns if previously selected
    const updatedHeaders = Object.entries(headers).reduce(
      (acc: Record<string, string>, header) => {
        return {
          ...acc,
          [header[0]]:
            header[0] === column ? value : header[1] === value ? '' : header[1],
        };
      },
      {},
    );

    setHeaders(updatedHeaders);
  };

  const onExport = async () => {
    setIsLoading(true);

    // Grab initial indexes of each header
    const domainIndex = headersArray.indexOf(headers.domains as string);

    const existingData = dataToExport?.map((existingArray) => {
      // Get remaining header indexes which contain pre existing data
      const headersArrayWithIndexesRemoved = Array.from(
        Array(headersArray.length).keys(),
      ).filter((element) => element !== domainIndex);

      // Map it over into a {HeaderTitle: existingData} format
      return headersArrayWithIndexesRemoved.map((indexOfHeader) => {
        return {
          [headersArray[indexOfHeader] as string]: existingArray[
            indexOfHeader
          ] as string,
        };
      });
    });

    const exportData = await getCompaniesExportData(
      searchId,
      dataToExport as string[][],
      domainIndex,
      eventTypes,
      totalEventLimit,
      eventTypeLimit as string,
      clientType,
    );

    const mappedResponse = exportData?.map((responseArray, index) => {
      // Grab the existing data from the relevant function
      const clientData = !existingData ? [] : existingData[index];

      // Convert to a single object of key value pairs
      const mergedData = Object.assign({}, ...(clientData ? clientData : []));

      const castedResponse = responseArray as unknown as DefaultExportResult;

      // Return the new data with the old data appended
      return {
        ...mergedData,
        [headersArray[domainIndex] || 'Domains']: castedResponse.domain,
        ['Discover - Descriptions']: castedResponse.companyDescription || '',
        ['Discover - Industry']: castedResponse.industry || '',
        ['Discover - Revenue']: castedResponse.revenue || '',
        ['Discover - Employee Count']: castedResponse.employeeCount || '',
        ['Discover - Events']: castedResponse.events || '',
        ['Discover - Hiring Score']: castedResponse.hiringScore || '',
        ['Discover - Technology Score']: castedResponse.technologyScore || '',
        ['Discover - Events Score']: castedResponse.eventsScore || '',
        ['Discover - Website Score']: castedResponse.websiteScore || '',
        ['Discover - Finance Score']: castedResponse.financeScore || '',
        ['Discover - Insight Score']: castedResponse.insightsScore || '',
        ['Discover - Overall Score']: castedResponse.overallScore || '',
        ['Discover - Event Count']: castedResponse.eventCount,
        ['Discover - IT Budget']: castedResponse.itBudget,
        ['Discover - IT Employee Count']: castedResponse.itEmployeeCount,
        ['Discover - Sales Employee Count']: castedResponse.salesEmployeeCount,
        ['Discover - Engineering And Tech Employee Count']:
          castedResponse.engAndTechEmployeeCount,
      };
    });

    setDataToExport(mappedResponse);
    setIsLoading(false);
    setIsMutated(true);
  };

  const closeModal = () => {
    dispatch(hideModal());
  };

  // Check if all export conditions met
  useEffect(() => {
    setCanExport(!(!dataToExport || isLoading));
  }, [dataToExport, isLoading]);

  // Set modal to display
  useEffect(() => {
    setShowModal(true);

    return () => setShowModal(false);
  }, [setShowModal]);

  return (
    <SC.Wrapper>
      <SC.HeaderContainer>
        <SC.StretchedContainer>
          <StyledParagraph>
            Enrich Leads /{' '}
            <b style={{ color: themeContext.colors.general.purple }}>
              Upload Companies
            </b>
          </StyledParagraph>
        </SC.StretchedContainer>
        <FontAwesomeIcon
          onClick={() => closeModal()}
          icon={faXmark}
          size={EXTRA_LARGE}
          style={{ cursor: 'pointer' }}
        />
      </SC.HeaderContainer>

      <SC.BodyContainer>
        <p>
          This file must contain a minimum of 1 column containing a list of
          company website domains.
        </p>

        <CSVReader
          isSearchResults={true}
          setHeadersData={setHeaders}
          setHeadersArray={setHeadersArray}
          setDataToExport={setDataToExport}
        />
        {!Object.values(headers).every((value) => value === '') && (
          <>
            <SC.ColumnWrapper>
              <SC.CsvColumnSelectorContainer>
                <SC.ColumnHeaderWrapper>
                  <p style={{ fontSize: '12px' }}>Company Domain</p>
                  <SpecificallyStyledDropdown
                    placeholder={'Select a column'}
                    style={{ width: '200px' }}
                    fluid
                    selection
                    name="domains"
                    value={headers.domains || ''}
                    onChange={(e, { value }) =>
                      onDropdownSelection(ExportColumn.DOMAIN, value as string)
                    }
                    options={calculateDropdownOptions()}
                  />
                </SC.ColumnHeaderWrapper>
              </SC.CsvColumnSelectorContainer>
            </SC.ColumnWrapper>

            <SC.ColumnWrapper>
              <SC.CsvColumnSelectorContainer>
                <SC.ColumnHeaderWrapper>
                  <p style={{ fontSize: '12px' }}>
                    Select Event Types To Enrich
                  </p>
                  <SpecificallyStyledDropdown
                    fluid
                    style={{ width: '400px' }}
                    selection
                    placeholder="Optional - Types of event to enrich"
                    name="eventTypes"
                    multiple
                    value={eventTypes || []}
                    onChange={(e, { value }) => updateEventValue(value)}
                    upward
                    options={calculateDropdownOptionsFromEnums(EventCategories)}
                    search
                  />
                </SC.ColumnHeaderWrapper>
                <SC.ColumnHeaderWrapper>
                  <p style={{ fontSize: '12px' }}>
                    Total Event Limits - Optional
                  </p>
                  <SpecificallyStyledInput
                    type={'number'}
                    width={NATURAL_WIDTH}
                    placeholder="Optional - Total limit on events"
                    name="totalEventLimit"
                    value={totalEventLimit || ''}
                    onChange={(e, { value }) =>
                      updateTotalEventLimitValue(value)
                    }
                  />
                </SC.ColumnHeaderWrapper>
                <SC.ColumnHeaderWrapper>
                  <p style={{ fontSize: '12px' }}>
                    Event Type Limits - Optional
                  </p>
                  <SpecificallyStyledInput
                    type={'number'}
                    backgroundColor={themeContext.colors.general.white}
                    width={NATURAL_WIDTH}
                    placeholder="Optional - Event Type Limit"
                    name="eventTypesLimit"
                    value={eventTypeLimit || ''}
                    onChange={(e, { value }) =>
                      updateEventTypeLimitValue(value)
                    }
                  />
                </SC.ColumnHeaderWrapper>
              </SC.CsvColumnSelectorContainer>
            </SC.ColumnWrapper>
          </>
        )}

        <SC.ActionButtonWrapper>
          {!isMutated && (
            <ActionButton
              disabled={!canExport}
              backgroundColor={themeContext.colors.general.purple}
              textColor={themeContext.colors.general.white}
              onClick={async () => await onExport()}
            >
              {isLoading
                ? processingCount
                  ? `Processing ${processingCount}/${processingTotal}`
                  : 'Processing...'
                : `Enrich`}
            </ActionButton>
          )}
          {isMutated && <CSVDownloader data={dataToExport} />}
        </SC.ActionButtonWrapper>
      </SC.BodyContainer>
    </SC.Wrapper>
  );
};

export default EnrichModal;
