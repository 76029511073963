import { Enums, Interfaces } from '@configur-tech/discover-core-types';
import HttpHelper from '../../utils/http-helper/HttpHelper';

export interface GetFileUploadUrlResponse {
  uploadURL: string;
  uploadId: string;
}

export interface CreateFileDocumentResponse {
  fileId: string;
  file: Interfaces.File;
}

export interface FileItemOutput {
  entity: Interfaces.File;
}

export interface SuccessResponseBody<T = Record<string, unknown>> {
  statusCode: Enums.StatusCode;
  message: string;
  data?: T;
}

export interface ErrorResponseBody<T = Record<string, unknown>> {
  statusCode: Enums.StatusCode;
  error: string;
  data?: T;
}

export interface FileItemsOutput {
  data: FileItemOutput[];
  pagination: {
    currentPageNum: number;
    prevPageNum: number | null;
    nextPageNum: number | null;
    totalCount: number;
    totalPages: number;
  };
}

export default class FileService {
  /**
   * Gets the upload url for the file
   *
   * @param {string} token - Token obtained from auth0
   * @param {string} fileName - Name of the file
   * @param {string} userId - Id of the user
   * @returns {Promise<SuccessResponseBody<GetFileUploadUrlResponse> | ErrorResponseBody>}
   */
  public static async getFileUploadUrl(
    token: string,
    fileName?: string,
    mimeType?: string,
    userId?: string,
    bucketName?: string,
  ): Promise<GetFileUploadUrlResponse | ErrorResponseBody> {
    const endpoint = process.env['REACT_APP_FILE_UPLOAD_URL'] || '';

    if (!endpoint) {
      throw Error('No endpoint found');
    }

    const ah = new HttpHelper(token);

    let uploadUrlResponse;
    if (bucketName) {
      uploadUrlResponse = await ah.get<
        SuccessResponseBody<GetFileUploadUrlResponse> | ErrorResponseBody
      >(endpoint, { fileName, mimeType, userId, bucketName });
    } else {
      uploadUrlResponse = await ah.get<
        SuccessResponseBody<GetFileUploadUrlResponse> | ErrorResponseBody
      >(endpoint, { fileName, mimeType, userId });
    }

    if (!uploadUrlResponse?.data) {
      throw Error('An upload URL could not be obtained');
    }

    return uploadUrlResponse?.data as GetFileUploadUrlResponse;
  }

  /**
   * Uploads a file to S3
   *
   * @param {string} url - Endpoint of the S3 Bucket
   * @param {File} file - File to upload
   * @returns {Promise<void>}
   */
  public static async uploadFileToS3(
    url: string,
    file: string | File | Buffer | FormData | Blob,
    headers?: Record<string, unknown>,
  ): Promise<void> {
    const ah = new HttpHelper();

    return await ah.put<void>(url, file, headers);
  }
}
