import styled from 'styled-components';
import { TRUE } from '../../consts/projectConsts';

interface TableCellProps {
  isError?: boolean;
  isComplete?: boolean;
}

export const NavButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  > button {
    margin-left: ${({ theme }) => theme.margin.large};
  }
`;

export const Button = styled.button<{
  disabled?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 44px;
  height: 44px;

  transition: background-color 0.25s, color 0.25s, opacity 0.25s;
  font-family: 'Roboto', 'sans-serif';
  font-weight: bold;

  background-color: ${({ theme }) =>
    theme.isDarkMode === TRUE
      ? theme.colors.general.black
      : theme.colors.system.white};

  color: ${({ theme, disabled, secondary, tertiary }) =>
    disabled
      ? tertiary
        ? theme.colors.system.grey
        : theme.colors.system.darkGrey
      : secondary
      ? theme.colors.system.white
      : theme.colors.system.offBlack};

  border: ${({ theme }) => `1px solid ${theme.colors.general.grey}`};
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.borders.small};

  cursor: ${({ disabled }) => (disabled ? 'disabled' : 'pointer')};

  &:hover {
    opacity: ${({ disabled }) => (disabled ? 1 : 0.7)};
  }

  > i {
    margin: 0;
    padding: 0 0 4px 0;
    font-size: ${({ theme }) => theme.typography.sizes.h4};
  }
`;

export const TagCell = styled.div`
  border-radius: 5px;
  display: inline-flex;
  justify-content: center;
  flex-wrap: wrap;

  > span {
    max-width: 150px;
    background-color: ${({ theme }) => theme.colors.system.grey};
    padding: ${({ theme }) => theme.padding.standard};
    margin: ${({ theme }) => theme.margin.standard}
      ${({ theme }) => theme.margin.standard}
      ${({ theme }) => theme.margin.standard} 0;
    border-radius: ${({ theme }) => theme.borders.small};
    font-size: ${({ theme }) => theme.typography.sizes.smaller};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    > i {
      color: ${({ theme }) => theme.colors.system.darkGrey};
      margin-right: ${({ theme }) => theme.margin.small};
    }
  }
`;

export const LogoCell = styled.div<{ image: string }>`
  display: inline-block;
  background-color: ${({ theme }) => theme.colors.general.white};
  background-image: url(${({ image }) => image});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: ${({ theme }) => `1px solid ${theme.colors.general.grey}`};

  margin: 0 ${({ theme }) => theme.margin.xlarge};
`;

export const IconCell = styled.div`
  padding: 0 0 0 ${({ theme }) => theme.padding.standard};
`;

export const Outer = styled.div<{
  fullWidth?: boolean;
  height?: string;
}>`
  ${({ fullWidth }) => fullWidth && `width: 100%;`};
  height: ${({ height }) => (height ? `${parseInt(height) - 7}vh` : '100%')};
  overflow-y: auto;
  overflow-x: auto;

  border-radius: ${({ theme }) => theme.borders.small};
`;

export const Wrapper = styled.div<{ noBorder?: boolean }>`
  width: 100%;
  overflow-x: visible;

  border: 0;
  border-radius: ${({ theme }) => theme.borders.small};
`;

export const NoResultsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;

  margin: ${({ theme }) => theme.margin.xxxlarge} 0;
`;

export const Table = styled.table`
  width: 100%;
  background: ${({ theme }) => theme.colors.system.white};
  border-spacing: 0;
  border: 0;
  box-shadow: none;
`;

export const TableRow = styled.tr<{ isClickable: boolean; lastItem?: boolean }>`
  border: 1px solid ${({ theme }) => theme.colors.system.black} !important;
  background-color: ${({ theme }) => theme.colors.system.white} !important;
  background: ${({ theme }) =>
    theme.isDarkMode === TRUE
      ? theme.overlayComponents
      : theme.colors.system.white} !important;
  align-content: center;
  text-align: center;
  height: 80px;

  transition: opacity 0.5s;

  ${({ isClickable }) =>
    isClickable
      ? `&:hover {
          cursor: pointer;
          opacity: 0.3;
        }`
      : ''};
`;

export const TableCell = styled.td<TableCellProps>`
  text-align: left;
  background-color: ${({ theme }) =>
    theme.isDarkMode === TRUE
      ? theme.colors.general.black
      : theme.colors.general.white};

  border-top: 1px solid ${({ theme }) => theme.colors.general.grey} !important;
  border-bottom: 1px solid ${({ theme }) => theme.colors.general.grey} !important;

  margin-bottom: 1em;
  ${({ color, theme }) =>
    color &&
    `color: ${theme.colors.system.white}; background-color: ${theme.colors.system.white}`};

  > a {
    transition: opacity 0.5s;
    &:hover {
      opacity: 0.3;
    }
  }

  &:last-of-type {
    border-left: 1px solid ${({ theme }) => theme.colors.general.grey} !important;
    padding-left: 10px;
    border-right: 1px solid ${({ theme }) => theme.colors.general.grey} !important;
  }

  &:first-of-type {
    border-right: none;
    border-left: 1px solid ${({ theme }) => theme.colors.general.grey} !important;
  }

  &[data-sticky-first-right-td='TRUE'] {
    background-color: ${({ theme }) => theme.colors.general.white};
  }
`;

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: ${({ theme }) => theme.margin.large} 0;

  > p {
    margin: 0;
  }
`;
